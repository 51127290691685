import React from 'react';
import { TextField, Stack, Typography } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Item } from "../../Items/TextItem";

export default function AlarmThresholdField(props) {
    return (
        <Stack sx={{ width: '100%' }}>
            <Item>
                <NotificationsIcon sx={{ float: 'left', color: props.color }} />
                <Typography paddingLeft={1}>{props.name}</Typography>
            </Item>
            <Item>
                <TextField
                    required
                    label="Threshold"
                    inputProps={{ type: 'number', step: 0.001, min: 0, max: 2 }}
                    value={props.value}
                    helperText="Must be between 0 and 2"
                    error={props.error}
                    onChange={event => props.onChange(event, props.thresholdID, props.positionIdx)}
                    id={props.thresholdID}
                    name={props.thresholdID}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                />
            </Item>
        </Stack>
    )
}