import React from 'react';
import { Box, FormControl, Grid, TextField, InputLabel, Select, MenuItem, ListSubheader, Chip, Typography, Checkbox, ListItemText, OutlinedInput, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Item } from "../../Items/TextItem";

export default function AlarmRecipientsSelect(props) {
    return (
        <Stack sx={{ width: '100%' }}>
            <Item>
                <NotificationsIcon sx={{ float: 'left', color: props.color }} />
                <Typography paddingLeft={1}>{props.name}</Typography>
            </Item>
            <Item>
                <FormControl fullWidth>
                    <InputLabel id="recipients-label" shrink={true}>Alarm Recipients</InputLabel>
                    <Select
                        required
                        label="Alarm Recipients"
                        labelId="recipients-label"
                        id={props.recipientsID}
                        value={props.recipientIDs}
                        onChange={(event) => props.onChange(event, props.thresholdID + "Recipients")}
                        input={<OutlinedInput label="Tag" />}
                        multiple
                        fullWidth
                        notched={true}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={props.users.find((user) => { return user.Key === value }).DisplayName} />
                                ))}
                            </Box>
                        )}
                    >
                        <ListSubheader>Recipient Groups</ListSubheader>
                        {
                            props.userGroups.map((item) => {
                                return (
                                    <MenuItem value={item.Key} key={item.Key}>
                                        <Checkbox checked={props.recipientIDs.indexOf(item.Key) > -1} />
                                        <ListItemText primary={item.DisplayName} />
                                    </MenuItem>
                                )
                            })
                        }
                        <ListSubheader>Recipients</ListSubheader>
                        {
                            props.users.map((item) => {
                                return (
                                    <MenuItem value={item.Key} key={item.Key}>
                                        <Checkbox checked={props.recipientIDs.indexOf(item.Key) > -1} />
                                        <ListItemText primary={item.DisplayName} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Item>
        </Stack>
    )
}