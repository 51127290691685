import React, { useState } from 'react';
import {
    Paper, Modal, Button, Typography, Grid, Box, Divider,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    IconButton
} from '@mui/material';
import { FormatDate } from "../../helpers/MiscFunctions"
import ClearIcon from '@mui/icons-material/Clear';
import ViewModal from '../Modals/ViewModal';

export default function IssueCommandModal(props) {

    const [viewHistoryModalOpen, setViewHistoryModalOpen] = useState(false);
    const [selectedCommand, setSelectedCommand] = useState();

    const handleViewHistoryModalOpen = (command) => {
        setViewHistoryModalOpen(true);
        setSelectedCommand(command)
    }

    const handleViewHistoryModalClose = () => {
        setViewHistoryModalOpen(false);
    }

    const renderCommand = (command) => {
        return (
            <Grid container direction="row" spacing={2} key={command.CommandTypeID}>
                <Grid item xs={5}>
                    <Button
                        variant="contained"
                        onClick={() => props.handleQueueCommand(command.CommandTypeID)}
                    >
                        {command.DisplayName}
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        onClick={() => handleViewHistoryModalOpen(command)}
                    >
                        View History
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    {command.QueuedOn != null && <Button
                        variant="contained"
                        color="error"
                        onClick={() => props.handleDeleteCommand(command.CommandTypeID)}
                    >
                        Cancel
                    </Button>}
                </Grid>
                <Grid item xs={6}>
                    <Typography>Command Queued:</Typography>
                </Grid>
                <Grid item xs={6}>
                    {command.QueuedOn != null ? (<Typography>{FormatDate(command.QueuedOn)}</Typography>) : (<Typography>N/A</Typography>)}
                </Grid>
                <Grid item xs={6}>
                    <Typography>Command Sent:</Typography>
                </Grid>
                <Grid item xs={6}>
                    {command.SentOn != null ? (<Typography>{FormatDate(command.SentOn)}</Typography>) : (<Typography>N/A</Typography>)}
                </Grid>
                <Grid item xs={6}>
                    <Typography>Command Executed:</Typography>
                </Grid>
                <Grid item xs={6}>
                    {command.ExecutedOn != null ? (<Typography>{FormatDate(command.ExecutedOn)}</Typography>) : (<Typography>N/A</Typography>)}
                </Grid>
                <br /><br />
            </Grid>
        )
    }

    return (
        <Box>
            <ViewModal
                title="Issue Commands"
                open={props.commandsModalOpen}
                handleClose={props.handleIssueCommandClose}
            >
                {props.commands.length == 0 ? <Typography>No commands availble for this node.</Typography> : <Typography>{`Last executed command: ${props.commands[0]?.DisplayName}`}</Typography>}
                <br />
                {props.commands?.map((command) => { return (renderCommand(command)) })}
            </ViewModal>
            <ViewModal
                title={`Command History for Command ${selectedCommand?.DisplayName}`}
                open={viewHistoryModalOpen}
                handleClose={handleViewHistoryModalClose}
            >
                <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Queued On</TableCell>
                                    <TableCell>Sent On</TableCell>
                                    <TableCell>Executed On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.commands?.filter((command) => command.CommandTypeID == selectedCommand?.CommandTypeID).map((command) => {
                                    return (
                                        <TableRow key={command.QueuedOn}>
                                            <TableCell>
                                                {selectedCommand.QueuedOn != null ? (<Typography>{FormatDate(command.QueuedOn)}</Typography>) : (<Typography>N/A</Typography>)}
                                            </TableCell>
                                            <TableCell>
                                                {selectedCommand.SentOn != null ? (<Typography>{FormatDate(command.SentOn)}</Typography>) : (<Typography>N/A</Typography>)}
                                            </TableCell>
                                            <TableCell>
                                                {selectedCommand.ExecutedOn != null ? (<Typography>{FormatDate(command.ExecutedOn)}</Typography>) : (<Typography>N/A</Typography>)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) }
                            </TableBody>
                        </Table>
                    </TableContainer>
            </ViewModal>
        </Box>

    )
}