import React, { useState } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import RestoreIcon from '@mui/icons-material/Restore';
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { useClasses } from '../../customHooks';
import { FormatDate, GetSeverityStatusColor } from '../../helpers/MiscFunctions';
import StatusTrafficLightDisplay from '../Generic/StatusTrafficLightDisplay';
import AlarmDashboardTableExpandedRow from './AlarmDashboardTableExpandedRow';

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                // Soft hide the button.
                visibility: 'hidden',
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "gray"
            }
        },
        MUIDataTableFilter: {
            filterPaper: {
                width: "250px",
            },
        },
        MuiPopover: {
            paper: {
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 10,
                minWidth: '90%'
            }
        }
    },
}

export default function AlarmDashboardTableExpandable(props) {
    for (var i = 0; i < props.channelsData.length; i++) {
        var channelData = props.channelsData[i];
        channelData.severityStatus = GetSeverityStatusColor(
            channelData.ChannelSeverityLevel,
            props.alarmStatusInfo,
            channelData.SecondsSinceCheckIn,
            channelData.ComputedValue
        );
    }

    const filteredChannels  = props.channelsData;
    const filteredAlarms = props.alarmData;
    const classes = useClasses(styles);

    const updateRowsExpanded = (allRowsExpanded) => {
        let myRowsExpanded = allRowsExpanded.map(item => {
            return item.dataIndex;
        });
        props.onRowExpansionChange(myRowsExpanded)
    }

    const updateRowsPerPage = (rowsPerPage) => {
        props.onChangeRowsPerPage(rowsPerPage);
    }

    const updateCurrentPage = (currentPage) => {
        props.onChangeCurrentPage(currentPage);
    }

    const updateSearchText = (currentSearchText) => {
        props.onChangeSearchText(currentSearchText);
        updateCurrentPage(0);
    }

    const handleFilterSubmit = applyFilters => {
        applyFilters();
    };

    // Define columns for table
    const columns = [
        {
            name: "NodeLocation",
            label: "Channel",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: true,
                filter: false,
                sort: true,
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        let valueA, valueB;
                        // NodeLocation + NodeDisplayName + NodeChannelDisplayName
                        valueA = obj1.rowData[8] + ' ' + obj1.rowData[9] + ' ' + obj1.rowData[10];
                        valueB = obj2.rowData[8] + ' ' + obj2.rowData[9] + ' ' + obj2.rowData[10];

                        if (order === 'asc') {
                            return valueA.localeCompare(valueB);
                        } else {
                            return valueB.localeCompare(valueA);
                        }
                    };
                },
                customBodyRenderLite: (dataIndex) => {
                    //console.log("Filtered: " + JSON.stringify(filteredChannels[dataIndex]));
                    var item = filteredChannels[dataIndex];

                    return (
                        <span>
                            {item.NodeLocation}
                            {item.NodeDisplayName}
                            <br />
                            <span style={{ color: item.severityStatus.StatusColor }}>
                                <h5><strong>{item.NodeChannelDisplayName}</strong></h5>
                            </span>
                        </span>

                    );
                }
            }
        },
        {
            name: "CheckInTimeZone",
            label: "Last Check In",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: false,
                filter: false,
                sort: true,
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        let dateA, dateB;
                        // Convert the values to Date objects
                        dateA = new Date(obj1.data);
                        dateB = new Date(obj2.data);

                        if (order === 'asc') {
                            return dateA - dateB;
                        } else {
                            return dateB - dateA;
                        }
                    };
                },
                customBodyRenderLite: (dataIndex) => {
                    var item = filteredChannels[dataIndex];
                    let str2Date = new Date(item.CheckInTimeZone);

                    let fDate = str2Date.getFullYear() > 2000 ?
                        FormatDate(item.CheckInTimeZone) :
                        "";

                    return (
                        <span style={{ color: item.severityStatus.StatusColor }}>
                            {item.severityStatus.ShowIcon ? <Tooltip title={item.severityStatus.Tooltip}><RestoreIcon sx={{ color: item.severityStatus.StatusColor }} /></Tooltip> : <RestoreIcon sx={{ visibility: 'hidden' }} />} {fDate}
                        </span>
                    );
                }
            }
        },
        {
            name: "ChannelSeverityLevel",
            label: "Current Status",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: false,
                filter: false,
                sort: true,
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        // obj1.rowData[1] : ChannelSeverityLevel
                        // obj1.rowData[2] : ComputedValue
                        // obj1.rowData[3] : ComputedOnTimeZone
                        // obj1.rowData[14] : SecondsSinceCheckIn
                        //IsGray : secondsSinceCheckIn > days7 && computedValue !== null;

                        const days7 = 604800;

                        let val1 = parseInt(obj1.rowData[2]);
                        let val2 = parseInt(obj2.rowData[2]);
                        let val3 = obj1.rowData[3];
                        let val4 = obj2.rowData[3];
                        let date1 = new Date(obj1.rowData[1]).getTime();
                        let date2 = new Date(obj2.rowData[1]).getTime();

                        let aIsGray = (obj1.rowData[15] > days7) && val3 !== null;
                        let bIsGray = (obj2.rowData[15] > days7) && val4 !== null;


                        if (val3 === null && val4 === null) return 0;
                        if (val3 === null) return 1;
                        if (val4 === null) return -1;

                        if (!(aIsGray || bIsGray)) {
                            if (val1 === val2) {
                                return (date1 - date2) * (order === 'asc' ? 1 : -1);
                            }
                            return (val1 - val2) * (order === 'asc' ? 1 : -1);
                        }

                        if (aIsGray && bIsGray) return 0;
                        if (aIsGray) return 1;
                        if (bIsGray) return -1;
                    };
                },

                customBodyRenderLite: (dataIndex) => {
                    var item = filteredChannels[dataIndex];

                    return (
                        <StatusTrafficLightDisplay
                            Status={item.severityStatus}
                            alarmStatusInfo={props.alarmStatusInfo}
                            keyName={
                                dataIndex + "-" +
                                item.ProductionReadableSN}

                        />
                    );

                }
            }
        },
        {
            name: "ConvertedComputedValue",
            label: "Current Value",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: false,
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    var item = filteredChannels[dataIndex];
                    let channelValue = "";

                    if (item.ConvertedComputedValue &&
                        item.ConvertedComputedValue !== 0) {
                        channelValue = item.ConvertedComputedValue
                            .toFixed(3)
                            .toString()
                            .concat(" ", item.ConfigSymbol == null ?
                                item.SensorUnitTypeSymbol :
                                item.ConfigSymbol);
                    } else if (item.ConvertedComputedValue === 0) {
                        channelValue = item.ConvertedComputedValue
                            .toString()
                            .concat(" ", item.ConfigSymbol == null ?
                                item.SensorUnitTypeSymbol :
                                item.ConfigSymbol);
                    }

                    return (
                        <span style={{ color: item.severityStatus.StatusColor }}>
                            {channelValue}
                        </span>
                    );

                }
            }
        },
        {
            name: "ComputedOnTimeZone",
            label: "Sampled On",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                filter: false,
                searchable: false,
                sort: true,
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        let dateA, dateB;
                        //console.log(obj1);
                        // Convert the values to Date objects
                        dateA = new Date(obj1.data);
                        dateB = new Date(obj2.data);

                        if (order === 'asc') {
                            return dateA - dateB;
                        } else {
                            return dateB - dateA;
                        }
                    };
                },
                customBodyRenderLite: (dataIndex) => {
                    var item = filteredChannels[dataIndex];
                    let str2Date = new Date(item.ComputedOnTimeZone);

                    let fDate = str2Date.getFullYear() > 2000 ?
                        FormatDate(item.ComputedOnTimeZone) :
                        "";

                    return (
                        <span style={{ color: item.severityStatus.StatusColor }}>
                            {fDate}
                        </span>
                    )

                }
            }
        },
        {
            name: "ProductionReadableSN",
            label: "Node Serial Number",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                filter: false,
                searchable: true
            }
        },
        {
            name: "NodeID",
            options: {
                filter: false,
                searchable: false,
                display: "excluded"
            }
        },
        {
            name: "NodeChannelID",
            options: {
                filter: false,
                searchable: false,
                display: "excluded"
            }
        },
        {
            name: "ComputedDataSourceID",
            options: {
                filter: false,
                searchable: false,
                display: "excluded"
            }
        },
        {
            name: "NodeLocation",
            label: "Node Location",
            options: {
                display: "excluded",
                searchable: true,
                filter: true
            }
        },
        {
            name: "NodeDisplayName",
            label: "Node Name",
            options: {
                display: "excluded",
                searchable: true,
                filter: false
            }
        },
        {
            name: "NodeChannelDisplayName",
            label: "Channel Name",
            options: {
                display: "excluded",
                searchable: true,
                filter: false
            }
        },
        {
            name: "AlarmNames",
            label: "Alarm Name",
            options: {
                display: "excluded",
                searchable: true,
                filter: false,
            }
        },
        {
            name: "SensorDataType",
            label: "Sensor Data Type",
            options: {
                display: "excluded",
                searchable: false,
                filter: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            {filteredChannels[dataIndex].SensorDataType}
                        </span>

                    );
                }
            }
        },
        {
            name: "AlarmTypes",
            label: "Alarm Type",
            options: {
                sort: false,
                display: "excluded",
                searchable: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    //render: v => v.map(l => l.toUpperCase()),
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    //logic: (alarmType, filters, row) => {
                    //    console.log("Alarm TYpe:" + JSON.stringify(alarmType));
                    //    console.log("filters:" + JSON.stringify(filters));
                    //    console.log("row:" + JSON.stringify(row));
                    //    //if (filters.length) {                            
                    //    //    for (let x = 0; x < filters.length; x++) {
                    //    //        if (alarmType.includes(filters[x])) {                                    
                    //    //            return true;
                    //    //        }
                    //    //    }
                    //    //    return false; 
                    //    //} 
                    //},
                    display: (filterList, onChange, index, column) => {
                        const optionValues = ['Diagnostic', 'Standard', 'Vibration'];
                        return (
                            <FormControl variant="standard">
                                <InputLabel htmlFor='select-multiple-chip'>
                                    Alarm Type
                                </InputLabel>
                                <Select
                                    //notched={true}
                                    multiple
                                    label={"Alarm Type"}
                                    value={filterList[index]}
                                    renderValue={selected => selected.join(', ')}
                                    onChange={event => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                >
                                    {optionValues.map(item => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox
                                                color='primary'
                                                checked={filterList[index].indexOf(item) > -1}
                                            />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }
                }
            }
        },
        { name: 'SecondsSinceCheckIn', options: { display: 'excluded' } },
    ]




    const options = {
        elevation: 5,
        pagination: true,
        filter: true,
        filterType: "multiselect",
        serverSide: false,
        confirmFilters: true,
        // Calling the applyNewFilters parameter applies the selected filters to the table 
        customFilterDialogFooter: (_currentFilterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                </div>
            );
        },
        onFilterChange: (_column, filterList, type) => {
            if (type === 'chip') {
                var newFilters = () => (filterList);
                //console.log('updating filters via chip');
                handleFilterSubmit(newFilters);
            }
        },
        onColumnSortChange: (changedColumn, direction) => {
            // Update local storage or any other side-effects as needed
            localStorage.setItem('name', changedColumn);
            localStorage.setItem('direction', direction);
            // Call the setters passed in from the parent component
            props.onColumnSortChange(changedColumn, direction);
        },
        sortOrder: {
            name: props.sortedColumnName,
            direction: props.sortedColumnDirection
        },
        setFilterChipProps: (_colIndex, _colName, _data) => {
            return {
                color: 'primary',
                variant: 'outlined'
            };
        },
        download: false,
        print: false,
        selectableRows: 'none',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        page: props.dashboardPageNum,
        onChangePage: (pageNum) => { updateCurrentPage(pageNum); },
        rowsPerPage: props.dashboardRowsPerPage,
        onChangeRowsPerPage: (numberOfRows) => { updateRowsPerPage(numberOfRows); },
        count: filteredChannels.count,
        viewColumns: true,
        selectableRowsHideCheckboxes: true,
        searchAlwaysOpen: props.searchAlwaysOpen,
        searchText: props.searchText,
        onSearchChange: (searchText) => { updateSearchText(searchText); },
        searchProps: {},
        searchPlaceholder: 'Search',
        customSearch: (searchQuery, currentRow, _columns) => {
            let isFound = false;

            currentRow.forEach(col => {
                if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                    isFound = true;
                }
            });
            return isFound;
        },
        rowsExpanded: props.dashboardRowsExpanded,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        onRowExpansionChange: (_currentRowsExpanded, allRowsExpanded) => {
            updateRowsExpanded(allRowsExpanded);
        },
        renderExpandableRow: (rowData, _rowMeta) => {
            const alarms = filteredAlarms.filter(a => a.ComputedDataSourceID === rowData[8]);
            const channels = filteredChannels.filter(a => a.ComputedDataSourceID === rowData[8]);

            let colspan = rowData.length + 1;
            let myAlarms = [];

            if (alarms) {
                myAlarms = alarms.filter(function (n) {
                    return typeof n != "object" || Object.keys(n).length > 0;
                });
            } else {
                return [];
            }

            return (
                <TableRow>
                    <AlarmDashboardTableExpandedRow
                        data={myAlarms}
                        colspan={colspan}
                        key={rowData[5]}
                        alarmStatusInfo={props.alarmStatusInfo}
                        companyID={props.companyID}
                        userID={props.userID}
                        viewAll={props.viewAll}
                        nodeDisplayName={rowData[8] + rowData[9]}
                        onDeleteFromExpandedRow={(alarmID, compDSID) =>
                            props.onDeleteFromTableExpandable(alarmID, compDSID)
                        }
                        onToastCloseFromExpandedRow={props.onToastCloseFromTableExpandable}
                        vibrationAssetID={channels[0].VibrationAssetID}
                    />
                </TableRow>
            );

        },

    };



    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    return (

        <CacheProvider value={muiCache}>
            <MUIDataTable
                title={props.title}
                data={filteredChannels}
                columns={columns}
                options={options}
                components={components}
                className={classes.overrides}

            />
        </CacheProvider>
    );
}
