import React, { useState } from 'react';
import { Box, IconButton, Typography, Grid, Stack, Modal, Paper, Button } from "@mui/material";
import theme from "../../theme";
import HelpIcon from '@mui/icons-material/Help';
import ClearIcon from '@mui/icons-material/Clear';

export default function MoreInfo({helpText, image, title, imgWidth }) {
    const [open, setOpen] = useState(false);

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }
    return (
        <Box>
            <IconButton onClick={helpButtonClick} sx={{ float: 'right', mr: 2, color: theme.palette.primary.main }}>
                <HelpIcon fontSize={"large"} />
            </IconButton>
            <Modal
                open={open}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper className="help-modal" sx={{
                    maxHeight: '95vh',
                    maxWidth: '95vw',
                    overflow: 'auto',
                    
                }}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={12}>
                            <IconButton sx={{ float: "right" }} onClick={handleModalClose}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Stack alignItems="center">
                        <Typography variant="h5" fontWeight="bold" align="center" sx={{ mb: 1 }}>{title}</Typography>
                        {image && <img
                            src={image}
                            alt={'Periodic Table'}
                            style={{
                                width: imgWidth ? imgWidth : '70%'
                            }}
                        />}
                        {helpText.map((block, index) => {
                            return (
                                <Box key={index} sx={{ my: 0.5 }}>
                                    <Typography variant="body1" fontWeight="bold" align="center">{block.title}</Typography>
                                    {block.text}
                                </Box>
                            )
                        })}
                    </Stack>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <Button variant="contained" sx={{ mx: '25%', width: '50%' }} onClick={handleModalClose}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </Box>
    )
}