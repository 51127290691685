import React from 'react'
import { Paper, Button, Grid, Modal, Divider, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export default function SubmitModal({title, open, handleClose, handleSave, saveLabel, children}) {
    return (
        <Modal
            open={open}
        >
            <Paper className="modal-paper">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton sx={{ float: "right" }} onClick={handleClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <br />
                {children}
                <br />
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "left" }} onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "right" }} onClick={handleSave}>
                            {saveLabel ? saveLabel : 'Save'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )

}