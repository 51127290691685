import React, { useState } from 'react';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import createCache from '@emotion/cache';
import TableRow from '@mui/material/TableRow';
import { useClasses } from '../../customHooks';
import DefectDashboardInnerTableExpandedRow from './DefectDashboardInnerTableExpandedRow';


const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                // Soft hide the button.
                visibility: 'hidden',
            },
        },
    },
}

export default function DefectDashboardTableExpandable(props) {

    const classes = useClasses(styles);
    const [searchText, setSearchText] = useState("");
    const [rowsExpanded, setRowsExpanded] = useState([]);

    // Define columns for asset-level table
    const columns = [
        {
            name: "CompanyName",
            label: "Company",
            options: {
                searchable: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            <strong>{props.data[dataIndex].CompanyName}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "AssetName",
            label: "Asset",
            options: {
                searchable: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            <strong>{props.data[dataIndex].AssetName}</strong>
                        </span>
                    )
                }
            }

        },
        {
            name: "DefectName",
            label: "Defect",
            options: {
                searchable: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            <strong>{props.data[dataIndex].DefectName}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "Likelihood",
            label: "Likelihood",
            options: {
                searchable: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            <strong>{props.data[dataIndex].Likelihood}</strong>
                            <strong> ({props.data[dataIndex].PercentComplete}%, </strong>
                            <strong>{props.data[dataIndex].PercentBurnt}%, </strong>
                            <strong>{props.data[dataIndex].PercentUnknown}%)</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "RecipeStateID",
            label: "RecipeStateID",
            options: {
                display: "excluded"
            }
        },
        {
            name: "VibrationObjectID",
            label: "VibrationObjectID",
            options: {
                display: "excluded"
            }
        },
    ];

    const hasSearchTextMatch = function (currentRow) {
        if (searchText == '' || !currentRow)
            return true;
        let isMatch = false;
        currentRow.forEach(property => {
            if (property && property.toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
                isMatch = true;
                return;
            }
        });
        return isMatch;
    }

    // Define options for asset-level table
    const options = {
        elevation: 5,
        filter: false,
        search: true,
        download: false,
        print: false,
        selectableRows: 'none',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        rowsPerPage: 10,
        count: props.data.count,
        selectableRowsHideCheckboxes: true,
        searchAlwaysOpen: true,
        searchText: searchText,
        searchProps: {
            onBlur: (e) => {
                setSearchText(e.target.value);
            },
            onKeyUp: (e) => {
                setSearchText(e.target.value);
            }
        },
        searchPlaceholder: 'Search',
        customSearch: (searchQuery, currentRow, columns) => {
            return hasSearchTextMatch(currentRow);
        },
        rowsExpanded: rowsExpanded,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            let myRowsExpanded = allRowsExpanded.map(item => {
                return item.dataIndex;
            });
            setRowsExpanded(myRowsExpanded)
        },
        renderExpandableRow: (rowData, rowMeta) => {
            //let defect = props.data[rowMeta.dataIndex];
            let defect = props.data
                .filter(record => {
                    return hasSearchTextMatch(Object.values(record));
                })
                .find(n => n.RecipeStateID === rowData[4]);
            return (
                <TableRow>
                    <DefectDashboardInnerTableExpandedRow
                        recipeStateID={rowData[4]}
                        vibrationObjectID={rowData[5]}
                        defectName={defect.DefectName}
                        likelihood={defect.Likelihood}
                        companyID={defect.CompanyID}
                        userID={props.userID}
                        colspan={columns.length}

                    />
                </TableRow>
            );
        },
    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };


    return (
        <MUIDataTable
            title={props.title}
            data={props.data}
            columns={columns}
            options={options}
            components={components}
            className={classes.overrides}
        />
    );
}