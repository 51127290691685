import React from 'react';
import { Typography, Stack } from "@mui/material";
import DefectPeriodicTableRow from './DefectPeriodicTableRow'; // Adjust the import path as necessary
import MoreInfo from '../Modals/MoreInfo';
import PeriodicTable from '../images/PeriodicTable.png'
import Element from '../images/Element.png'

const helpText = [
    {
        title: "How do I Interpret the Vibration Element Table?",
        text: <Stack>
            <Typography align="center">The Vibration Element Table displays <strong>Defects (Elements)</strong> that our system
                can detect. It groups them by <strong>Frequency</strong> using the Frequency Headers at the top. </Typography>
            <img
                src={Element}
                alt={'Element'}
                style={{
                    width: '9%',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            />
        </Stack>
    },
    {
        title: "What Information is Displayed on the Vibration Element Tile?",
        text: <Stack>
            <Typography align="center">Top Left Corner: <strong>Frequency</strong> (Options: Synchronous (S), Sub-Synchronous (Ss),
                Harmonic (H), Non-Synchronous (Ns))</Typography>
            <Typography align="center">Bottom Left Corner: <strong>Direction</strong> (Options: Radial (R), Axial (A), Radial
                or Axial (R/A))</Typography>
            <Typography align="center">Top Left Corner: <strong>Isolation Method</strong> (Options: Phase, Time Waveform, Ultrasonic (Ux),
                Transient, Orbit)</Typography>
        </Stack>
    },
]

export default function DefectPeriodicTableGrid({ tableElements, recipeID, activeDefects, defaultRecipeStateID, handleElementChange, userID }) {
    return (
        <div>
            <MoreInfo
                title=""
                helpText={helpText}
                image={PeriodicTable}
                imgWidth="40%"
            />
            {Array.from({ length: 7 }, (_, i) => i + 1).map(rowNumber => (
                <DefectPeriodicTableRow
                    key={rowNumber}
                    tableElements={tableElements}
                    recipeID={recipeID}
                    rowNumber={rowNumber}
                    activeDefects={activeDefects}
                    handleElementChange={handleElementChange}
                    userID={userID}
                />
            ))}
        </div>
    );
}
