import React from 'react';
import { Typography, Paper, Button, Modal, Divider, IconButton, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export default function ViewModal({title, open, handleClose, children, long}) {
    return (
        <Modal
            open={open}
        >
            <Paper className={long ? "modal-paper-long" : "modal-paper"}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton sx={{ float: "right" }} onClick={handleClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <br />
                {children}
                <br />
                <Button variant="contained" onClick={handleClose} fullWidth>Close</Button>
            </Paper>
        </Modal>
    )
}