import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Backdrop, Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../components/Auth/AuthService';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const resetForm = () => {
        setUsername('');
        setPassword('');
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await AuthService.login(username, password);
            resetForm();
            setSuccess('Successfully logged in!');
            setLoading(false);
            window.location.href = '/';
        } catch (error) {
            const resMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            setMessage(resMessage);
            setLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="background.default"
            sx={{
                paddingTop: 0,
                '@media (max-width: 600px)': {
                    alignItems: 'flex-start',
                    paddingTop: '10vh',
                },
            }}
        >
            <StatusBackdrop open={loading} />
            <Backdrop open={loading} style={{ zIndex: 11 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} style={{ padding: 20, width: 480, zIndex: 10 }}>
                <Typography variant="h5" component="h1" gutterBottom style={{ textAlign: 'center' }}>
                    Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: 16 }}
                    >
                        Login
                    </Button>
                </form>
                {success && <Typography color="success" style={{ color: 'green', marginTop: 16, textAlign: 'center' }}><CheckCircleIcon style={{ marginRight: 8 }} />{success}</Typography>}
                {message && <Typography color="error" style={{ marginTop: 16 }}>{message}</Typography>}
            </Paper>
        </Box>
    );
};

export default Login;
