import { Grid, Paper } from "@mui/material";
import { Box } from '@mui/system';
import { Toast } from 'primereact/toast';
import React, { useCallback, useRef, useState, createContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CustomHeader from '../../components/Generic/CustomHeader';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import StatusMessage from "../../components/Generic/StatusMessage";
import {
    fetchDefectRecipes
} from '../../repositories/DefectQueryFunctions';
import Defect3DAnimation from '../../components/DefectClassification/Defect3DAnimation';
import DefectPeriodTable from '../../components/DefectClassification/DefectPeriodicTable';
import Defects from '../../components/DefectClassification/Defects';
import Data from "../../components/DefectClassification/data.json";

const ModalContext = createContext();

// Expected Route: <Route path='/Defect/Details/:companyID/:userID/:vibrationObjectID' element={<DefectDetail />} />
export default function DefectDetail() {
    const { companyID, userID, vibrationObjectID, defaultRecipeStateID } = useParams();
    const toast = useRef(null);
    const [errorState, setErrorState] = useState();
    const [isLoadingDefectRecipes, setIsLoadingDefectRecipes] = useState(false);
    const [defectRecipes, setDefectRecipes] = useState([]);
    const [headerText, setHeaderText] = useState("Defects");
    const [selectedValue, setSelectedValue] = useState({ index: '', name: '', item: {} });
    const [recipeID, setRecipeID] = useState();
    const [recipeStateID, setRecipeStateID] = useState(Number(defaultRecipeStateID));
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const toggleModal = (element) => {
        setModalData(element)
        setOpen((prevState) => !prevState);
    }

    const handleDropdownChange = useCallback((index, name, recipeID, item) => {
        setRecipeID(recipeID);
        setSelectedValue({ index, name, item });
        const elem = Data.elements.find((item) => item.RecipeID == recipeID);
        toggleModal(elem);
    }, []); // Dependency array is empty because we don't depend on any external values

    const handleElementChange = useCallback((defect) => {
        setRecipeStateID(defect.RecipeStateID);
        setRecipeID(defect.RecipeID);
        setSelectedValue({ index: defect.RecipeStateID, name: defect.DefectName, item: defect });
    }, []); // Dependency array is empty because we don't depend on any external values


    useQuery(["DefectDetail.defectRecipes", userID, vibrationObjectID], fetchDefectRecipes, {
        onSuccess: (data) => {
            function formatDataRows(dataRows) {
                return dataRows.filter(dr => dr.Likelihood != "4 - Unlikely" || (dr.Likelihood == "4 - Unlikely" && dr.PercentComplete > 0))
                    .map(dr => {
                        dr.Likelihood = dr.Likelihood.replace(/[0-9]+ - /, '');
                        return dr;
                    });
            }
            let formattedDataRows = formatDataRows(data);

            setDefectRecipes(formattedDataRows);
            setIsLoadingDefectRecipes(false);
            // Assume that data is an array and the first item always exists for header setting
            setHeaderText(`Defects Details: ${data[0]?.AssetName}`);

            // Check if defaultRecipeStateID is provided and valid
            if (recipeStateID) {
                const matchedRecipe = data.find(recipe => Number(recipe.RecipeStateID) === Number(recipeStateID));
                if (matchedRecipe) {
                    setRecipeID(matchedRecipe.RecipeID);
                    setSelectedValue({ index: matchedRecipe.RecipeStateID, name: matchedRecipe.DefectName, item: matchedRecipe });
                }
            }
        },
        onError: (errorDefectRecipes) => {
            // Handle the error here. For example, you can log the error or set an error state.
            setErrorState(errorDefectRecipes || "An unexpected error occurred.");
            setIsLoadingDefectRecipes(false);
        }
    })

    const getHeaderText = () => {
        return <CustomHeader headerText={headerText} />;
    };

    const pageStyle = {
        margin: "2%",
    }
    return (
        <ModalContext.Provider value={toggleModal}>
            <Box sx={pageStyle}>
                {/*<CustomHeader headerText={headerText} />*/}
                {getHeaderText()}
                <Toast ref={toast} />
                {isLoadingDefectRecipes && <StatusBackdrop open={isCompanyLoading} />}
                {
                    errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location={getHeaderText()}
                        statusCode={errorState?.request?.status}
                        message={errorState.message}
                        error={errorState}
                    />
                }
                {!isLoadingDefectRecipes && defectRecipes &&
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} lg={3}>
                            <Paper elevation={2}>
                                <Defects
                                    userID={userID}
                                    defects={defectRecipes}
                                    onDropdownChange={handleDropdownChange}
                                    selectedValue={selectedValue}
                                    defaultRecipeStateID={recipeStateID}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={9} xl={8}>
                            <Paper elevation={2} sx={{ height: 795 }}>
                                <DefectPeriodTable
                                    userID={userID}
                                    activeRecipeID={recipeID}
                                    defectRecipes={defectRecipes}
                                    defaultRecipeStateID={recipeStateID}
                                    selectedValue={selectedValue}
                                    handleElementChange={handleElementChange}
                                    open={open}
                                    modalData={modalData}
                                    toggleModal={toggleModal}
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Defect3DAnimation />
                        </Grid>
                    </Grid>
                }

            </Box>
        </ModalContext.Provider>
        
    );
}

export { ModalContext };