import React from 'react';
import { Box, FormControl, Grid, TextField, InputLabel, Select, MenuItem, ListSubheader, Chip, Typography, Checkbox, ListItemText, OutlinedInput } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Item } from "../Items/TextItem";

export default function AddAlarmThreshold(props) {
    return (
        <React.Fragment>
            <Grid container item spacing={3}>
                <Grid item xs={8}>
                    <Item>
                        <NotificationsIcon sx={{ float: 'left', color: props.color }} />                    
                        <Typography paddingLeft={1}>{props.name}</Typography>
                    </Item>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Grid container item spacing={3}>
                <Grid item xs={4}>
                    <Item>
                        <TextField
                            required
                            label="Threshold"
                            inputProps={{ type: 'number', step: 0.001, min: 0, max: 2 }}                        
                            value={props.value}
                            helperText="Must be between 0 and 2"
                            error={props.error}
                            onChange={event => props.onChange(event, props.thresholdID, props.positionIdx)}
                            id={props.thresholdID}
                            name={props.thresholdID}
                            InputLabelProps={{ shrink: true}}
                        />
                    </Item>
                </Grid>
                <Grid item xs={8}>
                    <Item>
                        <FormControl fullWidth sx={{ marginBottom: '4%' }}>
                            <InputLabel id="recipients-label" shrink={true}>Alarm Recipients</InputLabel>
                            <Select
                                required
                                label="Alarm Recipients"
                                labelId="recipients-label"
                                id={props.recipientsID}
                                value={props.recipientIDs}
                                onChange={(event) => props.onChange(event, props.thresholdID + "Recipients", props.positionIdx)}
                                input={<OutlinedInput label="Tag" />}
                                multiple
                                fullWidth
                                notched={true}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={props.users.find((user) => {return user.Key === value}).DisplayName} />
                                        ))}
                                    </Box>
                                )}
                            >
                                <ListSubheader>Recipient Groups</ListSubheader>
                                {
                                    props.userGroups.map((item) => {
                                        return (
                                            <MenuItem value={item.Key} key={item.Key}>
                                                <Checkbox checked={props.recipientIDs.indexOf(item.Key) > -1} />
                                                <ListItemText primary={item.DisplayName} />
                                            </MenuItem>
                                        )
                                    })
                                }
                                <ListSubheader>Recipients</ListSubheader>
                                {
                                    props.users.map((item) => {
                                        return (
                                            <MenuItem value={item.Key} key={item.Key}>
                                                <Checkbox checked={props.recipientIDs.indexOf(item.Key) > -1} />
                                                <ListItemText primary={item.DisplayName} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Item>
                </Grid>
            </Grid>
        </React.Fragment>
        )
}