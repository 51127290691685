import React, { useContext } from 'react';
import {
    Paper, Modal, Button, FormControl, Switch, FormGroup, InputLabel, Select,
    TextField, MenuItem, Typography, Divider, IconButton, Grid, FormControlLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VibrationBandConfigure from '../Vibration/VibrationBandConfigure';
import { selectDisabled } from '../../helpers/NodeSettingsHelpers';
import { SessionContext } from '../../SessionContext';

export default function ConfigurationModal(props) {

    const { sessionData } = useContext(SessionContext);

    const formStyle = {
        my: '3%'
    }
    const renderConfig = (config) => {
        let analysisType = props.configs.find((c) => c.ConfigurationOptionID == props.enums?.ConfigurationOption['Analysis Type'])?.OptionValue;
        if (config.ConfigurationOptionID == props.enums?.ConfigurationOption['Enabled V&T Channels']) {
            return (
                <VibrationBandConfigure
                    key={config.ConfigurationOptionID}
                    analysisType={analysisType}
                    enableFlags={config.FlagBytes}
                    enableChange={analysisType == props.enums.AnalysisType['Advanced VBT'] ? true : false}
                />
            );
        }
        
        if (!(props.node?.IsCloudGate) && config.ConfigurationOptionID == props.enums?.ConfigurationOption['Radio Channel']) {
            return null;
        }
        switch (config.OptionTypeID) {
            case props.enums?.ConfigurationOptionType['Boolean']:
                return (
                    <FormControlLabel
                        sx={formStyle}
                        control={<Switch
                            checked={config.OptionValue != 0}
                            onChange={(event) => { props.handleConfigChange(event, config.ConfigurationOptionID) }}
                            disabled={selectDisabled(props.enums, config.ConfigurationOptionID, analysisType)}
                        />}
                        label={config.DisplayName}
                        key={config.ConfigurationOptionID}
                    />
                )
            case props.enums?.ConfigurationOptionType['Multiple']:
            case props.enums?.ConfigurationOptionType['Multiple - Dynamic']:

                return (
                    <FormControl
                        sx={formStyle}
                        key={config.ConfigurationOptionID}
                    >
                        <InputLabel id="multiple-select">{config.DisplayName}</InputLabel>
                        <Select
                            labelId="multiple-select"
                            label={config.DisplayName}
                            value={config.OptionValue}
                            defaultValue={config.DefaultValue}
                            disabled={selectDisabled(props.enums, config.ConfigurationOptionID, analysisType)}
                            onChange={(event) => { props.handleConfigChange(event, config.ConfigurationOptionID) }}
                        >
                            {config.Options.map((option, index) => {
                                return (<MenuItem key={index} value={option.Value}>{option.Text}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                )
            case props.enums?.ConfigurationOptionType['Freeform']:
                let driveType = props.configs.find((c) => c.ConfigurationOptionID == props.enums?.ConfigurationOption['Drive Type'])?.OptionValue
                if (driveType == 0 && analysisType != props.enums?.AnalysisType['Advanced VBT'] && analysisType != props.enums?.AnalysisType['Intermediate VBT'] && (config.configurationOptionID == props.enums?.ConfigurationOption['Rotations Per Second - Lower Bound'] || config.configurationOptionID == props.enums?.ConfigurationOption['Rotations Per Second - Upper Bound'])) {
                    return null;
                }
                else if ((driveType == 1 || driveType == 2) && (config.ConfigurationOptionID == props.enums?.ConfigurationOption['Primary RPM'])) {
                    return null;
                }
                const minMaxError = (config.OptionValue < config.Options[0].Min || config.OptionValue > config.Options[0].Max);
                let errorText = "";
                if (minMaxError) {
                    errorText = "Value must be between " + config.Options[0].Min + " and " + config.Options[0].Max;
                }
                let valueError = false;
                if (config.ConfigurationOptionID == props.enums?.ConfigurationOption['Rotations Per Second - Lower Bound']
                    && config.OptionValue > (props.configs.find(cfg => cfg.ConfigurationOptionID == props.enums?.ConfigurationOption['Rotations Per Second - Upper Bound']).OptionValue) - 240) {
                    valueError = true;
                    errorText = "Lower bound must be at least 240 RPM less than upper bound";
                }
                return (
                    <TextField
                        error={minMaxError || valueError}
                        helperText={errorText}
                        key={config.ConfigurationOptionID}
                        sx={formStyle}
                        label={config.DisplayName}
                        variant="outlined"
                        value={config.OptionValue}
                        defaultValue={config.DefaultValue}
                        disabled={selectDisabled(props.enums, config.ConfigurationOptionID, analysisType)}
                        inputProps={{ type: 'number' }}
                        onChange={(event) => { props.handleConfigChange(event, config.ConfigurationOptionID) }}
                    />
                )
            default:
                return null;
        }
    }
    return (
        <Modal
            open={props.configModalOpen}
            sx={{ zIndex: 1300 }}
        >
            <Paper className="modal-paper">
                <Grid container direction="row" alignItems="center" sx={{width: 500}}>
                    <Grid item xs={7}>
                        {props.editing ? <TextField
                            variant="outlined"
                            value={props.node?.DisplayName}
                            onChange={props.handleSelectedNodeNameUpdate}
                            inputProps={{ style: { fontSize: '1.5rem' } }}
                        /> :
                            <Typography sx={{ fontSize: '1.5rem' }}>{!(props.node?.IsVBTx && props.node?.ObjectType == props.enums?.TreeObjectType['Sensor Data']) ? `Configure ${props.node?.DisplayName}` : props.node?.DisplayName}</Typography>
                            }
                    </Grid>
                    <Grid item xs={1}>
                        {props.edit && <IconButton onClick={props.handleEditButtonClick}>
                            <EditIcon color="primary" />
                        </IconButton>}
                    </Grid>
                    <Grid item xs={2}>
                        {props.enable && !props.node?.IsVBTx && <FormControlLabel control={<Switch checked={props.node?.IsEnabled} onChange={props.handleSelectedNodeEnable} />} label="Enabled" />}
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton sx={{ float: "right" }} onClick={props.handleConfigModalClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <br />
                {props.node?.ObjectType == "Node" && <Grid container direction="row">
                    <Grid item xs={8}>
                        <Typography>Part Number:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{props.node?.PartNumber}</Typography>
                    </Grid>
                    <br /><br />
                    <Grid item xs={8}>
                        <Typography>Serial Number:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{props.node?.SerialNumber}</Typography>
                    </Grid>
                    <br /><br />
                </Grid>
                }
                <FormGroup>
                    {props.node?.ObjectType == "Node" && <FormControl
                        sx={formStyle}
                    >
                        <InputLabel id="folder-select">Folder</InputLabel>
                        <Select
                            labelId="folder-select"
                            label="Folder"
                            value={props.node?.ParentObjectID}
                            onChange={(event) => { props.handleFolderChange(event, props.node?.ObjectID) }}
                        >
                            {props.folders.map((folder, index) => {
                                return (<MenuItem key={index} value={folder.ObjectID}>{folder.DisplayName}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>}
                    {props.configs?.map(renderConfig)}
                </FormGroup>
                {!(props.node?.IsVBTx && props.node?.ObjectType == "Sensor Data") && <br />}
                {sessionData.viewAll && props.node?.ObjectType == "Node" &&
                    <Paper sx={{p: 1}}>
                        <Grid container direction="row">
                            <Grid item xs={3}>
                                <Typography variant="h6">Master Admin:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Button variant="contained" style={{ float: "right" }} onClick={() => props.handleAssignModalOpen(props.node)}>
                                    Assign to New Company
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="contained" style={{ float: "right" }} onClick={() => props.handleIssueCommandClick(props.node?.ObjectID)}>
                                    Issue Commands
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    }
                {!(props.node?.IsVBTx && props.node?.ObjectType == "Sensor Data") && <br />}
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "left" }} onClick={props.handleConfigModalClose}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" style={{ float: "right" }} onClick={props.handleConfigSaveButtonClick}>
                            Save
                        </Button>
                        
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}