import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    CalculateTimeDifference,
    GetSeverityStatusColorVIB
} from '../../helpers/MiscFunctions';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import StatusMessage from "../../components/Generic/StatusMessage";
import EmptyAssetDetail from '../../components/images/EmptyAssetDetail.png';
import AssetDetailTableExpandable from '../../components/Vibration/AssetDetailTableExpandable';
import ObjectHeader from '../../components/Vibration/ObjectHeader';
import { fetchAssetDetail, fetchVibrationEnums } from '../../repositories/VibrationQueryFunctions';
import MoreInfo from '../../components/Modals/MoreInfo';
import ViewAsset from '../../components/images/ViewAsset.png';
import { Item } from "../../components/Items/BoxItem";
import { mapVibrationObject, mapDashboardData } from "../../helpers/VibrationHelpers";

const helpText = [
    {
        title: "",
        text: <Stack>
            <Typography align="center" sx={{ mb: 1 }}>This is the graphical view of the latest vibration capture of an asset.
            </Typography>
            <Typography align="center" sx={{ mb: 1 }}>The vibration graphs are broken up into each direction (the three axis the
                sensors monitor). They are then broken into the different bands or "frequencies" in each direction. </Typography>
            <Typography align="center" sx={{ mb: 1 }}>The colors coordinate with the good (green), warning (yellow), and danger (red)
                levels for the vibration in each band and each direction. </Typography>
        </Stack>
    },
    {
        title: "Why is the vibration separated into bands?",
        text: <Typography align="center">The separation gives the ability to diagnose vibration faults more accurately by having the
            vibration data separated into each band. This is because vibration faults occur in different directions and in different
            frequencies. This can assist in the troubleshooting and diagnostic process. The <strong>Defect Classification </strong>
            page gives more insight into the alarming levels of vibration.</Typography>
    },
    {
        title: "What are the yellow and red lines on each of the graphs?",
        text: <Typography align="center">These lines indicate the different alarming levels for warning (yellow) and danger (red).
            These levels are defaulted to the ISO standards of vibration faults based on the equipment type that was selected for the
            asset. These levels can be configured based on the application requirements. </Typography>
    },
]

export default function AssetDetail(props) {
    // Consume parameters from URL access
    //:companyID/:objectID/:userID
    let { companyID, objectID, userID, viewAll } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // If access is not via URL, use props
    if (!objectID) {
        objectID = props.objectID
    }

    // State to handle recurring DB interactions
    const initalState = 0;
    const [refresh, setRefresh] = useState(initalState);
    const [vibrationObject, setVibrationObject] = useState([]);
    const [vibrationObjectNodes, setVibrationObjectNodes] = useState([]);
    const [nodeDiagnosticStatus, setNodeDiagnosticStatus] = useState([]);
    const [nodeCheckInStatus, setNodeCheckInStatus] = useState([]);
    const [nodeMaxAlarmValues, setNodeMaxAlarmValues] = useState([]);
    const [nodeAmplitudeData, setNodeAmplitudeData] = useState([]);
    const [nodeFrequencyData, setNodeFrequencyData] = useState([]);
    const [nodePhaseData, setNodePhaseData] = useState([]);
    const [errorState, setErrorState] = useState();

    // State to handle relevant enumerations
    const [enums, setEnums] = useState();

    // State to control rendered dashboard objects
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [detailRowsExpanded, setDetailRowsExpanded] = useState([]);

    // Force react components to refresh every 10 seconds (will run on refresh variable change)
    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(refresh + 1);
        }, 10000);
        return () => clearInterval(interval)
    }, [refresh]);


    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } =
        useQuery(["enums", { refresh }], fetchVibrationEnums, {
            onSuccess: (dataE) => {
                setEnums({
                    driveType: dataE.DriveTypes,
                    machineType: dataE.MachineTypes,
                    alarmStatusInfo: dataE.AlarmStatusInfo,
                    positionType: dataE.PositionTypes,
                    axisOrientations: dataE.AxisOrientations,
                    bands: dataE.Bands
                })
                setIsLoadedEnums(true);
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

    // Fetch Vibration Object to populate detail from DB (only react on DB changes)
    const { isLoading: isLoadingObjectDetail, error: errorFetchAsset, data: dataA } =
        useQuery(["vibrationObjectDetail", objectID, userID, { refresh }], fetchAssetDetail, {
            onSuccess: (dataA) => {

                // Handle structural changes
                if (JSON.stringify(dataA.VibrationObject) !== JSON.stringify(vibrationObject)) {
                    setIsLoadingDetail(true);
                    setVibrationObject(dataA.VibrationObject);
                }

                if (JSON.stringify(dataA.VibrationObjectNodes) !== JSON.stringify(vibrationObjectNodes)) {
                    setIsLoadingDetail(true);
                    setVibrationObjectNodes(dataA.VibrationObjectNodes);
                }

                // Handle data changes
                if (JSON.stringify(dataA.NodeDiagnosticStatuses) !== JSON.stringify(nodeDiagnosticStatus)) {
                    setIsLoadingData(true);
                    setNodeDiagnosticStatus(dataA.NodeDiagnosticStatuses);
                }

                // Handle data changes
                let existingNodeCheckInStatus = nodeCheckInStatus.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.HoursSinceCheckIn;
                    delete newObj.HoursSinceDataSampled;
                    delete newObj.SecondsSinceCheckIn;
                    return newObj;
                });
                let newDataCheckInStatus = dataA.NodeCheckInStatuses.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.HoursSinceCheckIn;
                    delete newObj.HoursSinceDataSampled;
                    return newObj;
                });

                if (JSON.stringify(newDataCheckInStatus) !== JSON.stringify(existingNodeCheckInStatus)) {
                    setIsLoadingData(true);
                    setNodeCheckInStatus(dataA.NodeCheckInStatuses);
                }
                if (JSON.stringify(dataA.NodeMaxAlarmValues) !== JSON.stringify(nodeMaxAlarmValues)) {
                    setIsLoadingData(true);
                    setNodeMaxAlarmValues(dataA.NodeMaxAlarmValues);
                }
                if (JSON.stringify(dataA.NodeAmplitudeData) !== JSON.stringify(nodeAmplitudeData)) {
                    setIsLoadingData(true);
                    setNodeAmplitudeData(dataA.NodeAmplitudeData);
                }
                if (JSON.stringify(dataA.NodeFrequencyData) !== JSON.stringify(nodeFrequencyData)) {
                    setIsLoadingData(true);
                    setNodeFrequencyData(dataA.NodeFrequencyData);
                }
                if (JSON.stringify(dataA.NodePhaseData) !== JSON.stringify(nodePhaseData)) {
                    setIsLoadingData(true);
                    setNodePhaseData(dataA.NodePhaseData);
                }
            },
            onError: (errorFetchAsset) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorFetchAsset || "An unexpected error occurred.");
            }
        });


    // Update Object Detail structure when object changes
    useEffect(() => {
        if (isLoadingDetail && isLoadedEnums) {
            handleDetailStructureChange();
        }
    }, [vibrationObject, vibrationObjectNodes, enums]);


    // Update Object Data when data changes
    useEffect(() => {
        if (isLoadingData && !isLoadingDetail && isLoadedEnums) {
            handleDetailDataChange();
        }
    }, [detailData, isLoadingData, isLoadedEnums]);


    // Handler for structural change to network
    const handleDetailStructureChange = async () => {

        //Create detail data object 
        const newDetailData = mapVibrationObject(vibrationObject, [], enums, null, vibrationObjectNodes); 

        // Update State
        setDetailData(newDetailData);
        setIsLoadingDetail(false);
        setIsLoadingData(true);

    }

    // Handler for newly received data
    const handleDetailDataChange = async () => {
        mapDashboardData(detailData, nodeDiagnosticStatus, nodeCheckInStatus, nodeMaxAlarmValues, nodeAmplitudeData, enums);
        // Update state
        setDetailData(detailData);
        setIsLoadingData(false);

    }

    // Handle a change in expanded rows
    const handleDetailRowChange = (expandedRows) => {
        setDetailRowsExpanded(expandedRows)
    }

    const handleBackButtonClick = () => {
        const { state } = location;
        if (state && state.from) {
            navigate(state.from);
        } else {
            navigate(`/Vibration/AssetDashboard/${companyID}/${userID}/${viewAll}`);
        }
    }

    const pageStyle = {
        margin: "2%",
        flexGlow: 1
    }

    return (
        <Box sx={pageStyle}>
            {(isLoadingEnums || isLoadingObjectDetail) &&
                <StatusBackdrop open={(isLoadingEnums || isLoadingObjectDetail) && refresh === 0} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Asset Detail"
                    statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            {(detailData && Object.keys(detailData).length > 0 && !props.hideBackToDashboard && !isLoadingDetail && !isLoadingData) &&
                <Grid container spacing={2} >
                    <Grid item xs={2} align="left" justifyContent="left" alignItems="left">
                        <Item><Button fullWidth onClick={handleBackButtonClick} variant="contained">Back To Dashboard</Button></Item>
                    </Grid>
                    <Grid item xs={1} align="left" justifyContent="left" alignItems="left">
                        <MoreInfo
                            helpText={helpText}
                            image={ViewAsset}
                            title="What is Available in the View Asset Page?"
                        />
                    </Grid>
                    <Grid item xs={12} align="center" justifyContent="center" alignItems="center">
                        <ObjectHeader
                            data={detailData}
                            alarmStatusInfo={enums.alarmStatusInfo}
                        />
                    </Grid>
                </Grid>
            }
            {(!detailData && Object.keys(detailData).length === 0 && !isLoadingDetail && !isLoadingData) &&
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <img src={EmptyAssetDetail} />
                    </Grid>
                </Grid>
            }
            {(detailData && Object.keys(detailData).length > 0 && !isLoadingDetail && !isLoadingData) &&
                <Grid item xs={12} paddingTop="12px">
                    <AssetDetailTableExpandable
                        data={detailData.Nodes}
                        count={detailData.Nodes.length}
                        alarmStatusInfo={enums.alarmStatusInfo}
                        onRowExpansionChange={handleDetailRowChange}
                        detailRowsExpanded={detailRowsExpanded}
                    />
                </Grid>
            }
        </Box>
    );
}