import React from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@mui/material";
import StatusTrafficLightDisplay from '../Generic/StatusTrafficLightDisplay';
import { useClasses } from '../../customHooks';
import CommunicationStatus from "../Generic/CommunicationStatus";
import BatteryStatus from "../Generic/BatteryStatus";
import { useTheme } from "@mui/material/styles";
import { GetSeverityStatusColorVIB, FormatDate } from '../../helpers/MiscFunctions';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Typography from '@mui/material/Typography';

const styles = {
    overrides: {
        MUITableHeadRow: {
            root: {
                display: 'none'
            }
        }
    },
}

export default function AssetDashboardInnerTableExpandedRow(props) {
    const classes = useClasses(styles);
    const theme = useTheme();

    const options = {
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        responsive: 'vertical',
        pagination: false,
        selectableRowsHideCheckboxes: true,
        enableNestedDataAccess: ".",
        
    };

    // Define columns for node-level table
    const columns = [
        {
            name: "DisplayName",
            label: " Node",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                searchable: true,
                sort: true,
                customSort: (data1, data2) => {
                    return data1.DisplayName.localeCompare(data2.DisplayName);
                },
                customBodyRenderLite: (dataIndex) => {
                    //console.log("props.nodedata[dataIndex]" + JSON.stringify(props.nodedata[dataIndex]));
                    let statusColor = GetSeverityStatusColorVIB(
                        props.nodedata[dataIndex].StatusInfo.Severity,
                        props.alarmStatusInfo,
                        props.nodedata[dataIndex].CheckinInfo.SecondsSinceCheckIn
                    );

                    return (
                        <span style={{ color: statusColor.StatusColor }}>
                            <strong>{props.nodedata[dataIndex].PositionType.DisplayName + ": " + props.nodedata[dataIndex].DisplayName}</strong>
                        </span>
                    )                     
                }
            }
        },
        {
            name: "NodeStatusInfo",
            label: "Status",
            options: {
                sort: false, 
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                searchable: false,
                customBodyRenderLite: (dataIndex) => {
                    let status = props.nodedata[dataIndex].StatusInfo
                    return (
                        <StatusTrafficLightDisplay
                            Status={status}
                            alarmStatusInfo={props.alarmStatusInfo}
                            keyName={
                                dataIndex + "-" +
                                props.nodedata[dataIndex].NodeID}
                        />
                    );
                    
                }
            }
        }, 
        {
            name: "NodeSignalInfo",
            label: "Connectivity",
            options: {
                sort: false, 
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                searchable: false,
                customBodyRenderLite: (dataIndex) => {
                    //console.log(JSON.stringify(props.nodedata[dataIndex].CheckinInfo));
                    const minCheckInTime = props.nodedata[dataIndex].CheckinInfo.SecondsSinceCheckIn;

                    const nodesAreDown = (minCheckInTime === null || minCheckInTime > 604800);
                    return (
                        <React.Fragment>
                            <CommunicationStatus
                                value={props.nodedata[dataIndex].SignalInfo.ComputedValue}
                                unitType={props.nodedata[dataIndex].SignalInfo.UnitTypeSymbol}
                                checkinInfo={props.nodedata[dataIndex].CheckinInfo}
                                minCheckInTime={minCheckInTime}
                            />
                            {nodesAreDown &&
                                <PriorityHighIcon sx={{ color: theme.palette.error.main }} fontSize="large" />}
                        </React.Fragment>
                    );
                }
            }
        },
        {
            name: "NodeBatteryInfo", 
            label: "Battery",
            options: {
                sort: false, 
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                searchable: false,
                customBodyRenderLite: (dataIndex) => {
                    const minCheckInTime = props.nodedata[dataIndex].CheckinInfo.SecondsSinceCheckIn;
                    return (
                        <BatteryStatus
                            value={isFinite(props.nodedata[dataIndex].BatteryInfo.ComputedValue) ?
                                props.nodedata[dataIndex].BatteryInfo.ComputedValue : -1}
                            unitType={props.nodedata[dataIndex].BatteryInfo.UnitTypeSymbol}
                            minCheckInTime={minCheckInTime}
                        />
                    );
                }
            }
        },
        {
            name: "CheckInTimeZone",
            label: "Last Check In",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                sort: false,
                customSort: (data1, data2) => {
                    
                    //return console.log(data1)
                    //data1.CheckInTimeZone.localeCompare(data2.CheckInTimeZone);
                    
                },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            <strong>{FormatDate(props.nodedata[dataIndex].CheckinInfo["CheckInTimeZone"])}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "MostRecentSampledOnTimeZone",
            label: "Most Recent Data Sample",
            options: {
                sort: false, 
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>)
                },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span >
                            <strong>{FormatDate(props.nodedata[dataIndex].CheckinInfo["MostRecentSampledOnTimeZone"])}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "NodeID",
            options: {
                display: "excluded"
            }
        },        
    ];

    //console.log(columns);
    //console.log(props.nodedata);
    //console.log(options);

    return (
        <React.Fragment>
        {
            <TableCell colSpan={props.colspan}>
                    <MUIDataTable
                        title={""}
                        columns={columns}
                        data={props.nodedata}
                        options={options}
                        className={classes.overrides}
                    />
            </TableCell>
        }
        </React.Fragment>
    );
}


