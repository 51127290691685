import React from 'react';
import { Typography, Grid, TextField, Select, MenuItem, FormControl } from '@mui/material';
import SubmitModal from '../Modals/SubmitModal';

export default function EditCompanyModal(props) {
    return (
        <SubmitModal
            title={props.save ? "Edit Company" : "Create Company"}
            open={props.editCompanyModalOpen}
            handleClose={props.handleCloseClick}
            handleSave={props.handleSaveClick}
        >
            <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item xs={3}>
                        <Typography align="center"><b>Name</b></Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            label="New Company Name"
                            variant="outlined"
                            fullWidth
                            style={{ float: "right" }}
                            value={props.companyName}
                            onChange={props.handleCompanyNameChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align="center"><b>Time Zone</b></Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl fullWidth>
                            <Select
                                notched={true}
                                value={props.timeZone}
                                onChange={props.onTimeZoneChange}

                            >
                                {props.timeZonesList.map((timeZ, index) => {
                                    return (
                                        <MenuItem key={index} value={timeZ.Value}>{timeZ.Name}</MenuItem>
                                )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
        </SubmitModal>
    )
}

