
/**
 * Functions that convert base64 numbers from the API to types that javascript can handle and vice versa. Thank you ChatGPT.
 */
export function base64ToNumber(base64String) {
    // Decode the base64 string to binary data
    const binaryString = atob(base64String);
    // Convert binary data to an integer
    let number = 0;
    let i;
    for (i = 0; i < binaryString.length; i++) {
        number = (number << 8) | binaryString.charCodeAt(i);
    }
    return {
        value: number,
        length: i
    };
}

export function base64ToByteArray(base64String) {
    // Decode the base64 string to a binary string
    const binaryString = atob(base64String);

    // Create a Uint8Array and fill it with the binary string's char codes
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }

    return byteArray;
}


export function numberToBase64(number, length) {
    if (number === 0) {
        return btoa(String.fromCharCode(0));
    }

    // Convert the number to a binary string
    let binaryString = '';
    for (let i = 0; i < length; i++) {
        binaryString = String.fromCharCode(number & 0xFF) + binaryString;
        number = number >> 8;
    }

    // Encode the binary string to base64
    return btoa(binaryString);
}

export function byteArrayToBase64(byteArray) {
    // Convert the byte array to a binary string
    let binaryString = '';
    for (let i = 0; i < byteArray.length; i++) {
        binaryString += String.fromCharCode(byteArray[i]);
    }

    // Encode the binary string to base64
    return btoa(binaryString);
}

export function transformEnumsToObject(enums) {
    return {
        AnalysisType: ArrayToObject(enums.AnalysisTypes),
        ConfigurationOptionType: ArrayToObject(enums.ConfigurationOptionTypes),
        ConfigurationOption: ArrayToObject(enums.ConfigurationOptions),
        TreeObjectType: ArrayToObject(enums.TreeObjects),
    };
}

export const selectDisabled = (enums, configID, analysisType) => {
    switch (analysisType) {
        case enums.AnalysisType['Basic Fixed Speed']:
            if (configID == enums.ConfigurationOption['Acceleration Range'] || configID == enums.ConfigurationOption['Drive Type'] || configID == enums.ConfigurationOption['Blade/Gear Tooth Count']) {
                return true;
            }
            break;
        case enums.AnalysisType['Basic Variable Speed']:
            if (configID == enums.ConfigurationOption['Acceleration Range'] || configID == enums.ConfigurationOption['Drive Type'] || configID == enums.ConfigurationOption['Blade/Gear Tooth Count']) {
                return true;
            }
            break;
        case enums.AnalysisType['Low Frequency Fixed Speed']:
            if (configID == enums.ConfigurationOption['Acceleration Range'] || configID == enums.ConfigurationOption['Drive Type'] || configID == enums.ConfigurationOption['Blade/Gear Tooth Count']) {
                return true;
            }
            break;
        case enums.AnalysisType['Low Frequency Variable Speed']:
            if (configID == enums.ConfigurationOption['Acceleration Range'] || configID == enums.ConfigurationOption['Drive Type'] || configID == enums.ConfigurationOption['Blade/Gear Tooth Count']) {
                return true;
            }
            break;
        case enums.AnalysisType['Temperature Focused Fixed Speed']:
            if (configID == enums.ConfigurationOption['Acceleration Range'] || configID == enums.ConfigurationOption['Drive Type'] || configID == enums.ConfigurationOption['Blade/Gear Tooth Count'] || configID == enums.ConfigurationOption['Sampling Rate']) {
                return true;
            }
            break;
        case enums.AnalysisType['Temperature Focused Variable Speed']:
            if (configID == enums.ConfigurationOption['Acceleration Range'] || configID == enums.ConfigurationOption['Drive Type'] || configID == enums.ConfigurationOption['Blade/Gear Tooth Count'] || configID == enums.ConfigurationOption['Sampling Rate']) {
                return true;
            }
            break;
        default:
            return false;
    }
    return false;
}

function ArrayToObject(arr) {
    return arr.reduce((acc, curr) => {
        acc[curr.DisplayName] = curr.ID;
        return acc;
    }, {});
}