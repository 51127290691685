import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Link, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from "react-query";
import AuthService from '../../components/Auth/AuthService';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import { fetchTimeZones } from "../../repositories/TimeZoneQueryFunctions";

const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const [eulaAccepted, setEulaAccepted] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setPassword('');
        setConfirmPassword('');
        setTimeZone('');
        setEulaAccepted('');
    };

    const { isLoading: objectLoading, error: objectError, data: dataO } =
        useQuery([], fetchTimeZones, {
            onSuccess: (data) => {
                if (data) {
                    setTimeZones(data);
                }
            }
        });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await AuthService.register({
                firstName,
                lastName,
                email,
                phoneNumber,
                password,
                confirmPassword,
                timeZone,
                eulaAccepted,
            });
            setSuccess('Successfully registered, you should recieve a confirmation email.');
            resetForm();
            setLoading(false);
        } catch (error) {
            const resMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            setMessage(resMessage);
            setLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="background.default"
            sx={{
                paddingTop: 0,
                '@media (max-width: 600px)': {
                    alignItems: 'flex-start',
                    paddingTop: '10vh',
                },
            }}
        >
            <StatusBackdrop open={loading || objectLoading} />
            <Backdrop open={loading} style={{ zIndex: 11 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} style={{ padding: 20, width: 480, zIndex: 10 }}>
                <Typography variant="h5" component="h1" gutterBottom style={{ textAlign: 'center' }}>
                    Register
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Phone Number"
                        type="tel"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <TextField
                        select
                        label="Time Zone"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={timeZone}
                        onChange={(e) => setTimeZone(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="">Select Time Zone</option>
                        {timeZones.map(tz => {
                            return (<option key={tz.ID} value={tz.ID}>{tz.DisplayName}</option>);
                        })}
                    </TextField>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={eulaAccepted}
                                onChange={(e) => setEulaAccepted(e.target.checked)}
                            />
                        }
                        label={
                            <>
                                I agree to the{" "}
                                <Link
                                    component="button"
                                    onClick={handleOpenModal}
                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                >
                                    End User License Agreement (EULA)
                                </Link>
                            </>
                        }
                    />

                    <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
                        <DialogTitle sx={{ textAlign: 'center' }}>End User License Agreement (EULA)</DialogTitle>
                        <DialogContent>
                            <iframe
                                src="/eula/GraceSense EULA - 2018-11-02 (2).pdf"
                                title="EULA"
                                width="100%"
                                height="500px"
                                style={{ border: "none" }}
                            />
                        </DialogContent>
                    </Dialog>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: 16 }}
                        disabled={!eulaAccepted}
                    >
                        Submit
                    </Button>
                </form>
                {success && <Typography color="success" style={{ color: 'green', marginTop: 16, textAlign: 'center' }}><CheckCircleIcon style={{ marginRight: 8 }} />{success}</Typography>}
                {message && <Typography color="error" style={{ marginTop: 16 }}>{message}</Typography>}
            </Paper>
        </Box>
    );
};

export default Register;
