import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { CompanyInfoContext } from '../../App';
import { SessionContext } from '../../SessionContext';

export default function CustomHeader(props) {
    const { sessionData } = useContext(SessionContext);
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);

    let headerContent = props.headerText;

    if (sessionData) {
        headerContent += ` - ${sessionData.currentCompanyName || companyInfo.CompanyName}`;
    }

    return (
        <Typography variant="h4" component="h2" className="header">
            {headerContent}
        </Typography>
    );
}
