import { ThemeProvider } from '@emotion/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import 'primeflex/primeflex.css';
import "primeicons/primeicons.css";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from './components/Auth/AuthContext';
import AuthService from "./components/Auth/AuthService";
import './custom.css';
import AppNavBar from './layout/MainMenuBar/AppNavBar';
import AccountManagement from './pages/Account/AccountManagement';
import AlarmDashboard from './pages/Alarm/AlarmDashboard';
import AlarmEventDetails from './pages/Alarm/AlarmEventDetails';
import AlarmStepperAddNew from './pages/Alarm/AlarmStepperAddNew';
import AlarmStepperEdit from './pages/Alarm/AlarmStepperEdit';
import AssetIntegration from './pages/AssetIntegration/AssetIntegration';
import ConfirmEmail from './pages/Auth/ConfirmEmail';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import CompanyManagement from './pages/Company/CompanyManagement';
import ControlGateConfiguration from './pages/Control/ControlGateConfiguration';
import DefectDashboard from './pages/Defect/DefectDashboard';
import DefectDetail from './pages/Defect/DefectDetail';
import HomeDashboard from './pages/Home/HomeDashboard';
import NodeSettings from './pages/NodeSettings/NodeSettings';
import AlarmNotificationTemplate from './pages/NotificationTemplate/AlarmNotificationTemplate';
import APIManagement from './pages/PublicAPI/APIManagement';
import RecipientGroupPage from './pages/RecipientGroups/RecipientGroupPage';
import UnitTypeSettings from './pages/UnitTypeSettings/UnitTypeSetting';
import FormComponent from './pages/Vendor/FormComponent';
import AddAsset from './pages/Vibration/AddAsset';
import AssetDashboard from './pages/Vibration/AssetDashboard';
import AssetDetail from './pages/Vibration/AssetDetail';
import EditAsset from './pages/Vibration/EditAsset';
import { SessionContext } from './SessionContext';
import theme from "./theme";

export const CompanyInfoContext = createContext();

export default function App() {
    const [companyInfo, setCompanyInfo] = useState({});
    const authInfo = useAuth();
    const location = useLocation();
    const appRef = useRef(null);

    const { updateSessionData } = useContext(SessionContext);
    useEffect(() => {
        if (AuthService.getCurrentUser()) {
            updateSessionData(AuthService.getCurrentUser())
        }
    }, []);


    const PrivateRoute = ({ children }) => {
        return authInfo.isAuthenticated() ? children : <Navigate to={`/login?returnUrl=${encodeURIComponent(location.pathname)}`} />;
    };

    return (
        <>
            <CompanyInfoContext.Provider value={{ companyInfo, setCompanyInfo }}>
                <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                    <PrimeReactProvider>
                        <ThemeProvider theme={theme}>
                            <div ref={appRef}>
                                    {process.env.REACT_APP_ENABLE_AUTH && <AppNavBar />}
                                    <Routes>
                                        {/*Public Routes*/}
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/register" element={<Register />} />
                                        <Route path="/forgot-my-password" element={<ForgotPassword />} />
                                        <Route path="/confirm-my-email" element={<ConfirmEmail />} />

                                        {/*Need To Migrate to private*/}
                                        {!process.env.REACT_APP_ENABLE_AUTH &&
                                            (<Route exact path='/' element={<AssetDashboard />} breadcrumb='Home' />)}
                                        <Route path='/Vibration/AssetDashboard/:companyID/:userID/:viewAll' element={<AssetDashboard />} />
                                        <Route path='/Vibration/AddAsset/:companyID/:userID/:viewAll' element={<AddAsset />} />
                                        <Route path='/Vibration/AddAsset/:companyID/:userID/:objectID/:viewAll' element={<AddAsset />} />
                                        <Route path='/Vibration/EditAsset/:companyID/:userID/:objectID/:viewAll' element={<EditAsset />} />
                                        <Route path='/Vibration/AssetDetail/:companyID/:userID/:objectID/:viewAll' element={<AssetDetail />} />
                                        <Route path='/Alarm/AlarmDashboard/:companyID/:userID/:viewAll' element={<AlarmDashboard />} />
                                        <Route path='/Alarm/Add/:companyID/:userID/:viewAll' element={<AlarmStepperAddNew />} />
                                        <Route path='/Alarm/Edit/:companyID/:userID/:alarmID/:cdsid/:viewAll/:crudType' element={<AlarmStepperEdit />} />
                                        <Route path='/Alarm/Clone/:companyID/:userID/:alarmID/:cdsid/:viewAll/:crudType' element={<AlarmStepperEdit />} />
                                        <Route path='/Alarm/EventDetails/:userID/:urlFragment/:vib' element={<AlarmEventDetails />} />
                                        <Route path='/Alarm/EventDetails/view/:companyID/:userID/:cdsid/:alarmID/:vib' element={<AlarmEventDetails />} />
                                        <Route path='/Vendor/Form/:companyID/:userID/:vendorID' element={<FormComponent />} />
                                        <Route path='/AssetIntegration/:companyID/:userID/:vendorID/:viewAll?' element={<AssetIntegration />} />
                                        <Route path='/Defect/DefectDashboard/:userID/:companyID' element={<DefectDashboard />} />
                                        <Route path='/Defect/Details/:companyID/:userID/:vibrationObjectID/:defaultRecipeStateID' element={<DefectDetail />} />
                                        <Route path='/Defect/Details/:companyID/:userID/:vibrationObjectID' element={<DefectDetail />} />
                                        <Route path='/Defect/Details/:companyID/:userID' element={<DefectDetail />} />
                                        <Route path='/PublicAPI/:companyID/:userID' element={<APIManagement />} />
                                        

                                        {/*Private Routes*/}
                                        {process.env.REACT_APP_ENABLE_AUTH &&
                                            (<Route exact path='/' element={<PrivateRoute><HomeDashboard /></PrivateRoute>} breadcrumb='Home' />)}
                                        <Route path='/Settings/profile' element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
                                        <Route path='/Settings/company' element={<PrivateRoute><CompanyManagement /></PrivateRoute>} />
                                        <Route path='/Settings/control-gates' element={<PrivateRoute><ControlGateConfiguration /></PrivateRoute>} />
                                        <Route path='/Alarms/recipient-groups' element={<PrivateRoute><RecipientGroupPage /></PrivateRoute>} />
                                        <Route path='/Settings/nodes' element={<PrivateRoute><NodeSettings /></PrivateRoute>} />
                                        <Route path='/Alarms/notifications' element={<PrivateRoute><AlarmNotificationTemplate /></PrivateRoute>} />
                                        <Route path='/Settings/unit-types' element={<UnitTypeSettings />} />
                                    </Routes>
                                </div>
                                </ThemeProvider>
                            </PrimeReactProvider>
                        </MuiThemeProvider>                        
                    </StyledEngineProvider>
                </CompanyInfoContext.Provider>
        </>
    );
}


