import {
    Box,
    Typography
} from '@mui/material';
import React from 'react';
import theme from "../../theme";
import { Opacity } from '../../helpers/MiscFunctions';

export default function DefectModalLabel({ children }) {
    return (
        <Box sx={{ display: 'inline-block' }}>
            <Typography
                sx={{
                    backgroundColor: Opacity(theme.palette.secondary.main, 0.8),
                    borderRadius: '10px',
                    px: 1,
                    py: .5,
                    ml: 1,
                    color: 'white',
                }}
            >
                {children}
            </Typography>
        </Box>
    );
}
