import { fetchNotificationTemplates, deleteNotificationTemplate } from '../../repositories/AlarmNotificationTemplateQueryFunctions';
import React, { useState, useRef, useContext } from 'react';
import { useQuery, useMutation } from "react-query";
import {
    Box, Stack, Typography, Button, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper
} from '@mui/material';
import { Toast } from 'primereact/toast';
import StatusMessage from "../../components/Generic/StatusMessage";
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import AlarmNotificationTemplateModal from '../../components/AlarmNotification/AlarmNotificationTemplateModal';
import { createToastError, createToastSuccess } from "../../helpers/MiscFunctions";
import { SessionContext } from '../../SessionContext';

// Page Styling
const pageStyle = {
    margin: "2%"
}

export default function AlarmNotificationTemplate() {

    const [errorState, setErrorState] = useState();
    const [templates, setTemplates] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loadTemplate, setLoadTemplates] = useState(true);

    const toast = useRef(null);
    const { sessionData } = useContext(SessionContext);

    const { isLoading: notificationsTemplatesLoading } =
        useQuery({
            queryKey: ["templates", sessionData.currentCompanyID],
            queryFn: fetchNotificationTemplates,
            onSuccess: (dataN) => {
                setLoadTemplates(false);
                setTemplates(dataN);
            },
            onError: (templatesError) => {
                setErrorState(templatesError || "An unexpected error occurred.");
            },
            enabled: loadTemplate
        });

    const doDeleteTemplate =
        useMutation((notificationID) => deleteNotificationTemplate(notificationID), {
            onSuccess: (_) => {
                setLoadTemplates(true);
                toast.current.show(createToastSuccess('Notification Template Successfully Deleted'));
            },
            onError: (_) => {
                toast.current.show(createToastError('An error occurred while attempting to delete notification.'));
            }
        });
    const handleCreateClick = () => {
        setSelectedTemplate(null);
        setOpen(true);
    }

    const handleModifyClick = (template) => {
        setSelectedTemplate(template);
        setOpen(true);
    }

    const handleDeleteClick = (notificationID) => {
        doDeleteTemplate.mutateAsync(notificationID);
    }

    return (
        <Box sx={pageStyle}>
            {(notificationsTemplatesLoading) &&
                <StatusBackdrop open={(notificationsTemplatesLoading)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="warning"
                    location="Settings"
                    statusCode={errorState?.request?.status}
                    message={errorState?.message}
                    error={errorState}
                />
            }
            <Toast ref={toast} />
            <Stack alignItems="center" spacing={3}>
                <Typography variant="h3">Alarm Notification Management</Typography>
                <Button variant='contained' onClick={handleCreateClick}>Create new Alarm Notification</Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Alarm Notification Name</TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map((template) => (
                                <TableRow key={template.AlarmNotificationID}>
                                    <TableCell component="th" scope="row">
                                        {template.AlarmNotificationDisplayName}
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>
                                        <Button variant='contained' onClick={() => { handleModifyClick(template) }}>Modify</Button>
                                    </TableCell>
                                    <TableCell sx={{ width: '10%' }}>
                                        <Button variant='contained' color="error" onClick={() => {handleDeleteClick(template.AlarmNotificationID)}}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            <AlarmNotificationTemplateModal
                open={open}
                template={selectedTemplate}
                handleClose={() => setOpen(false)}
                setErrorState={setErrorState}
                toast={toast}
                setLoadTemplates={setLoadTemplates}
            />

        </Box>
    )
}