import React, { useState, useContext } from 'react';
import {
    Paper, Button, Typography, Grid,
    Stack, Box, ListItem, ListItemIcon, ListItemText,
    Checkbox, ListItemButton, List, FormControl, Select, MenuItem,
} from '@mui/material';
import { useQuery, useMutation } from "react-query";
import {
    fetchSamplingRates, setSamplingRates
} from '../../repositories/SettingsQueryFunctions';
import {
    base64ToNumber, numberToBase64
} from "../../helpers/NodeSettingsHelpers";
import { createToastSuccess, createToastError } from "../../helpers/MiscFunctions";
import { fetchVibrationVBTxEnums } from "../../repositories/VibrationQueryFunctions"
import ViewModal from '../Modals/ViewModal';
import SubmitModal from '../Modals/SubmitModal';
import { SessionContext } from '../../SessionContext';

export default function MassEditModal(props) {
    const { sessionData } = useContext(SessionContext);
    const [rateSettings, setRateSettings] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [massEditModalOpen, setMassEditModalOpen] = useState(false);
    const [modifyModalOpen, setModifyModalOpen] = useState(false);
    const [rateEnum, setRateEnum] = useState([])
    const [selectedRates, setSelectedRates] = useState([]);

    useQuery(["vibrationVBTxEnums", sessionData.currentCompanyID], fetchVibrationVBTxEnums, {
        fetchPolicy: "network-only",
        onSuccess: (dataB) => {
            setRateEnum(dataB.SamplingPeriods);

        }
    });

    useQuery({
        queryKey: ["samplingrates", props.filteredDashboardIDs, sessionData.currentCompanyID],
        queryFn: fetchSamplingRates,
        onSuccess: (result) => {
            props.setLoadSamplingRates(false);
            result.forEach((rate) => {
                rate.Rates.forEach((period) => {
                    period.OptionValue = base64ToNumber(period.OptionBytes).value
                });
                rate.Nodes = rate.DashboardObjects.map((node) => { return props.dashNodes.find(obj => obj.ObjectID == node.DashboardObjectID); })
            });
            setRateSettings(result);
            setMassEditModalOpen(true);
            
        },
        onError: (_) => {
            props.setLoadSamplingRates(false);
            props.toast.current.show(createToastError('An error occurred while attempting to get sampling rates'));
        },
        enabled: props.loadSamplingRates
    });

    const doSetSamplingRates =
        useMutation(({ companyID, configs }) => setSamplingRates(companyID, configs), {
            onSuccess: (_) => {
                setMassEditModalOpen(false);
                setModifyModalOpen(false);
                setSelectedNodes([]);
                props.toast.current.show(createToastSuccess('Node configurations set'));
            },
            onError: (_) => {
                props.toast.current.show(createToastError('An error occurred while attempting to set node configurations'));
            }
        });

    const handleMassEditSave = () => {
        let filteredNodes = []
        rateSettings.forEach((rate) => {
            filteredNodes = filteredNodes.concat(rate.DashboardObjects.filter(obj => selectedNodes.includes(obj.DashboardObjectID)));
        });
        let newConfigs = {
            DashboardObjects: filteredNodes,
            Rates: selectedRates.filter(rate => rate.OptionValue != ''),
        }
        newConfigs.Rates.forEach((rate) => {
            rate.OptionByteString = numberToBase64(rate.OptionValue, 2);
        });
        doSetSamplingRates.mutateAsync({ companyID: sessionData.currentCompanyID, configs: newConfigs });
    }

    const handleMassEditClose = () => {
        setMassEditModalOpen(false)
        setSelectedNodes([]);
    }

    const handleModifyOpen = () => {
        setModifyModalOpen(true);
        let allRates = [];
        //Find all of the sampling rates that are shared by the selected nodes
        if (selectedNodes.length > 0) {
            let neededRates = [];
            selectedNodes.forEach((node) => {
                let rates = rateSettings.find(setting => setting.DashboardObjects.some((obj) => obj.DashboardObjectID == node));
                neededRates.push(rates.Rates);
            })
            allRates = neededRates.reduce((commonElements, currentRates) => {
                return commonElements.filter(element => currentRates.some(obj => obj.DisplayName == element.DisplayName));
            })
            //Clear all sampling rates to 0
            allRates.forEach((rate) => {
                rate.OptionValue = '';
            })
        }
        setSelectedRates(allRates);
    }

    const handleModifyClose = () => { setModifyModalOpen(false) }

    const handleNodeToggle = (value) => () => {
        const currentIndex = selectedNodes.indexOf(value);
        const newSelectedNodes = [...selectedNodes];

        if (currentIndex === -1) {
            newSelectedNodes.push(value);
        } else {
            newSelectedNodes.splice(currentIndex, 1);
        }
        setSelectedNodes(newSelectedNodes);
    }

    const onValueChange = (event, rate) => {
        let newSelectedRates = selectedRates.map(r => r.DisplayName === rate.DisplayName ? { ...r, OptionValue: event.target.value } : r);
        setSelectedRates(newSelectedRates);
    }

    const renderListTitle = (rate) => {
        let title = "";
        rate.Rates.forEach((period, index) => {
            title += `${period.DisplayName}: ${period.ValueName}`;
            if (index != rate.Rates.length - 1) {
                title += ", ";
            }
        })
        return title;
    }

    const renderSettingsWithNodes = (rate, index) => {
        return (
            <Paper style={{ padding: '7px', height: '98%'}} key={index} elevation={2}>
                <Typography fontWeight="bold">{renderListTitle(rate)}</Typography>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {rate.Nodes.map((node, index) => {
                        return (
                            <ListItem key={index}>
                                <ListItemButton onClick={handleNodeToggle(node.ObjectID)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedNodes.includes(node.ObjectID)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText>{node.DisplayName}</ListItemText>
                                </ListItemButton>
                            </ListItem>)
                    }) }
                </List>
            </Paper>)
    }

    const renderRateFields = (rate, index) => {
        return (
            <Box key={index}>
                <Typography sx={{ marginBottom: '1%', marginTop: '2%' }} fontWeight="bold">{rate.DisplayName}</Typography>
                <FormControl sx={{width: 500}}>
                    <Select
                        value={rate.OptionValue}
                        label={rate.DisplayName}
                        onChange={(e) => onValueChange(e, rate)}
                    >
                        {rateEnum.map((option) => {
                            return (
                                <MenuItem key={option.ID} value={option.ID}>{option.DisplayName}</MenuItem>
                            )
                        }) }
                    </Select>
                </FormControl>
            </Box>
        )
    }

    return (
        <Box>
            <ViewModal
                title="Mass Edit Node Settings"
                open={massEditModalOpen}
                handleClose={handleMassEditClose}
                long={true}
            >
                <Grid container direction="row" sx={{ marginBottom: '2%' }}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            style={{ float: "right" }}
                            onClick={handleModifyOpen}
                            disabled={selectedNodes.length == 0}
                        >
                            Modify
                        </Button>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        overflow: 'auto',
                        flexGrow: 1, // To fill the available space and make the Stack scrollable
                        p: 1
                    }}
                >
                    <Stack spacing={2}>
                        {rateSettings?.map(renderSettingsWithNodes)}
                    </Stack>
                </Box>
            </ViewModal>
            <SubmitModal
                title="Mass Edit Node Settings"
                open={modifyModalOpen}
                handleClose={handleModifyClose}
                handleSave={handleMassEditSave}
            >
                <Stack>
                    {selectedRates.map(renderRateFields)}
                </Stack>
            </SubmitModal>
        </Box>
        

    )
}