import apiClient from "../../http-common";
import { jwtDecode } from "jwt-decode";
import { fetchCompanyInfo } from '../../repositories/CompanyQueryFunctions';

const storageKey = 'token';
const storageCurrentCompanyKey = 'cc';

class AuthService {
    parseToken(token, cc) {
        if (typeof token === 'string') {
            const jwt = jwtDecode(token);
            return {
                currentCompanyID: Number(jwt.g_currco),
                defaultCompanyID: Number(jwt.g_defco),
                timezoneID: Number(jwt.g_tzid),
                userID: Number(jwt.nameid),
                viewAll: (jwt.g_ge === "True" || token.g_ma === "True"),
                email: jwt.email,
                firstName: jwt.given_name,
                lastName: jwt.family_name,
                token: token,
                cc: cc
            };
        }
        return null;
    }

    getCurrentUser() {
        const token = sessionStorage.getItem(storageKey);
        const cc = JSON.parse(sessionStorage.getItem(storageCurrentCompanyKey) || '{}');
        return this.parseToken(token, cc);
    }

    login(username, password) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/login`;
        return apiClient
            .post(url, {
                username,
                password,
            })
            .then(async (response) => {
                if (response.data.token) {
                    sessionStorage.setItem(storageKey, response.data.token);
                }

                var user = this.getCurrentUser();
                if (user?.currentCompanyID && user.currentCompanyID > -1) {
                    try {
                        const companyInfo = await fetchCompanyInfo({ queryKey: ['companyInfo', user.currentCompanyID] });
                        sessionStorage.setItem(storageCurrentCompanyKey, JSON.stringify(companyInfo));
                    } catch (error) {
                        console.error(`Error loading company info: ${error.message}`);
                    }
                }

                return user;
            });
    }

    logout() {
        sessionStorage.removeItem(storageKey);
        sessionStorage.removeItem(storageCurrentCompanyKey);
    }

    register(data) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/register`;
        return apiClient.post(url, data);
    }

    confirmEmail(data) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/confirm-email`;
        return apiClient.get(url, { params: data });
    }

    requestResetPassword(data) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/send-password-reset-email`;
        return apiClient.post(url, data);
    }

    resetPassword(data) {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/reset-password`;
        return apiClient.post(url, data);
    }
}

export default new AuthService();