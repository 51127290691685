import { Grid, TextField } from '@mui/material';
import React from 'react';
import { VibrationAnalysisType } from "./VibrationAnalysisType";
import AssetDropdownFormItem from './AssetDropdownFormItem';
import { Item } from "../Items/TextItem";

export default function AssetRpmFormItem(props) {
    // Determine whether drive selection is allowed
    const isRpmSelectDisabled = () => {
        switch (props.analysisType) {
            case VibrationAnalysisType.AdvancedVBT:
            case VibrationAnalysisType.IntermediateVBT:
                return false;
                break;

            case VibrationAnalysisType.BasicVBTCore:
            case VibrationAnalysisType.BasicVBTLowFreq:
            case VibrationAnalysisType.BasicVBTTemp:
            case VibrationAnalysisType.BasicVBTCoreVFD:
            case VibrationAnalysisType.BasicVBTLowFreqVFD:
            case VibrationAnalysisType.BasicVBTTempVFD:
                return true;
                break;

        }
    }

    // Render appropriate RPM input(s)
    const renderRPMbox = () => {
        if (props.rpmType == 0) {
            return (
                <React.Fragment>
                    <Grid item xs={2}>                          
                        <Item><TextField
                            fullWidth
                            inputProps={{ type: 'number', step: 1, min: 1, max: 30000 }}
                            onChange={props.handleRpmChange}
                            value={props.rpm}
                            label="RPM"
                            key={'fixed'}
                            helperText={props.primaryRPMErrorText}
                            error={props.primaryRPMErrorText != ""}
                        /></Item>
                    </Grid>
                    <Grid item xs={2} />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>  
                    <Grid item xs={2}>
                        <Item><TextField
                            fullWidth
                            inputProps={{ type: 'number', step: 1, min: 1, max: 30000 }}
                            onChange={props.handleLowRpmChange}
                            value={props.lowRpm}
                            label="Lowest RPM"
                            key={'low'}
                            helperText={props.lowRPMErrorText}
                            error={props.lowRPMErrorText != ""}
                        /></Item>
                    </Grid>
                    <Grid item xs={2}>
                        <Item><TextField
                            fullWidth
                            inputProps={{ type: 'number', step: 1, min: 1, max: 30000 }}
                            onChange={props.handleHighRpmChange}
                            value={props.highRpm}
                            label="Highest RPM"
                            key={'high'}
                            helperText={props.highRPMErrorText}
                            error={props.highRPMErrorText != ""}
                        /></Item>
                    </Grid>
                    <Grid item xs={4}/>
                </React.Fragment>
            );
        }
    }

    // Render RPM selection components
    return (
        <React.Fragment>
            <Grid container item spacing={3}>      
                <Grid item xs={2}>
                    <Item><AssetDropdownFormItem
                        id="rpm-type"
                        value={props.rpmType}
                        label="Fixed/Variable Speed"
                        onChange={props.handleRpmTypeChange}
                        selectItems={props.rpmTypes}
                        disabled={isRpmSelectDisabled()}
                    /></Item>
                </Grid>
                {renderRPMbox()}
            </Grid>
        </React.Fragment>
    )
}