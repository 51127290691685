import React from "react";
import { TableCell } from "@mui/material";
import ComboChart from './ComboChart';
import Grid from '@mui/material/Grid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { GetSeverityStatusColorVIB, Opacity } from '../../helpers/MiscFunctions';

export default function AssetDetailInnerTableExpandedRow(props) {

    //console.log("AmpData" + JSON.stringify(props.xAmpData));
    let [firstXAmpData] = props.xAmpData || [];
    let [firstYAmpData] = props.yAmpData || [];
    let [firstZAmpData] = props.zAmpData || [];

    const formatThresholdSeries = (data, attr) => {
        let thresholdSeries = []
        let index = 0;
        data.forEach(d => {
            thresholdSeries.push([index - .5, d[attr]])
            index++;
        })
        if (thresholdSeries.length > 0) {
            thresholdSeries[0][0] += 0.01;
            thresholdSeries.push([thresholdSeries[thresholdSeries.length - 1][0] + 0.99, thresholdSeries[thresholdSeries.length - 1][1]]);
        }
        return thresholdSeries
    }

    return (
        <TableCell colSpan={props.colspan}>
            <Grid container spacing={2}> 
                <Grid item xs={4}>
                    {(!props.xAmpData || props.xAmpData.length === 0) &&
                        <span>
                            <WarningAmberIcon color={'warning'}/> No Axial Data to Display
                        </span>
                    }
                    {(props.xAmpData && props.xAmpData.length > 0) &&
                        <ComboChart unitType={firstXAmpData?.UnitTypeSymbol} sx={{ display: { xs: 'flex', md: 'flex' } }}
                            ColSeries={
                                
                                props.xAmpData.map((item) => {
                                    //console.log("ITEM: " + JSON.stringify(item));
                                    let c = GetSeverityStatusColorVIB(
                                        item.AlarmTypeID,
                                        props.alarmStatusInfo,
                                        item.SecondsSinceCheckIn
                                    );
                                    return {
                                        y: item.ComputedValue,
                                        name: item.BandDisplayName,
                                        color: c.StatusColor,
                                        dashboardDataID: item.DashboardDataID
                                    }
                                })}
                            WarningSeries={formatThresholdSeries(props.xAmpData, "WarningThresholdValue")}
                            DangerSeries={formatThresholdSeries(props.xAmpData, "DangerThresholdValue")}
                            Title="Axial"
                            ShowSpinner={false}
                            key={props.nodeID + "-x"}
                        />
                    }
                </Grid>
                <Grid item xs={4}>
                    {(!props.yAmpData || props.yAmpData.length === 0) &&
                        <span>
                            <WarningAmberIcon color={'warning'} /> No Horizontal Data to Display
                        </span>
                    }
                    {(props.yAmpData && props.yAmpData.length > 0) &&
                        <ComboChart unitType={firstYAmpData?.UnitTypeSymbol} sx={{ display: { xs: 'flex', md: 'flex' } }}
                            ColSeries={
                                props.yAmpData.map((item) => {
                                    let c = GetSeverityStatusColorVIB(
                                        item.AlarmTypeID,
                                        props.alarmStatusInfo,
                                        item.SecondsSinceCheckIn
                                    );
                                    return {
                                        y: item.ComputedValue,
                                        name: item.BandDisplayName,
                                        color: c.StatusColor,
                                        dashboardDataID: item.DashboardDataID
                                    }
                                })}
                            WarningSeries={formatThresholdSeries(props.yAmpData, "WarningThresholdValue")}
                            DangerSeries={formatThresholdSeries(props.yAmpData, "DangerThresholdValue")}
                            Title="Horizontal"
                            ShowSpinner={false}
                            key={props.nodeID + "-y"}
                        />
                    }
                </Grid>
                <Grid item xs={4}>
                    {(!props.yAmpData || props.yAmpData.length === 0) &&
                        <span>
                            <WarningAmberIcon color={'warning'} /> No Vertical Data to Display
                        </span>
                    }
                    {(props.zAmpData && props.zAmpData.length > 0) &&
                        <ComboChart unitType={firstZAmpData?.UnitTypeSymbol} sx={{ display: { xs: 'flex', md: 'flex' } }}
                            ColSeries={
                                props.zAmpData.map((item) => {
                                    let c = GetSeverityStatusColorVIB(
                                        item.AlarmTypeID,
                                        props.alarmStatusInfo,
                                        item.SecondsSinceCheckIn
                                    );
                                    return {
                                        y: item.ComputedValue,
                                        name: item.BandDisplayName,
                                        color: c.StatusColor,
                                        dashboardDataID: item.DashboardDataID
                                    }
                                })}
                            WarningSeries={formatThresholdSeries(props.zAmpData, "WarningThresholdValue")}
                            DangerSeries={formatThresholdSeries(props.zAmpData, "DangerThresholdValue")}
                            Title="Vertical"
                            ShowSpinner={false}
                            key={props.nodeID + "-z"}
                        />
                    }
                </Grid>
            </Grid>
        </TableCell>
    );
}


