import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Backdrop, Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../components/Auth/AuthService';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setEmail(params.get('email') || '');
        setToken(params.get('token') || '');
    }, [location.search]);

    const resetForm = () => {
        setEmail('');
        setToken('');
        setPassword('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (!token) {
                await AuthService.requestResetPassword({
                    email
                });
                setSuccess('Reset password request processed.');
                setLoading(false);
                resetForm();
            } else {
                await AuthService.resetPassword({
                    email,
                    token: decodeURIComponent(token),
                    newPassword: password
                });
                setSuccess('Successfully reset password.');
                setLoading(false);
                resetForm();
            }
        } catch (error) {
            const resMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            setMessage(resMessage);
            setLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="background.default"
            sx={{
                paddingTop: 0,
                '@media (max-width: 600px)': {
                    alignItems: 'flex-start',
                    paddingTop: '10vh',
                },
            }}
        >
            <StatusBackdrop open={loading} />
            <Backdrop open={loading} style={{ zIndex: 11 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} style={{ padding: 20, width: 480, zIndex: 10 }}>
                <Typography variant="h5" component="h1" gutterBottom style={{ textAlign: 'center' }}>
                    {token ? 'Reset My Password' : 'Request Password Reset'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    {!token && (<TextField
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />)}
                    {token && (<TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />)}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: 16 }}
                    >
                        Submit
                    </Button>
                </form>
                {success && <Typography color="success" style={{ color: 'green', marginTop: 16, textAlign: 'center' }}><CheckCircleIcon style={{ marginRight: 8 }} />{success}</Typography>}
                {message && <Typography color="error" style={{ marginTop: 16, textAlign: 'center' }}>{message}</Typography>}
            </Paper>
        </Box>
    );
};

export default ForgotPassword;
