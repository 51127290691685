import React, { useRef, useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from "@mui/material/styles";
import { Toast } from 'primereact/toast';
import { updateUserProfile, formatPhoneNumber } from '../../repositories/AccountMgmtQueryFunctions';
import {
    Avatar,
    Typography,
    Box,
    Button,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Grid,
    Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { EditSchema, formatInputPhoneNumber } from '../../helpers/AccountHelpers';
import { createToastSuccess, createToastError } from '../../helpers/MiscFunctions';


const StyledErrorMessage = styled('div')(({ theme }) => ({
    color: theme.palette.error.main,
    fontSize: '0.75rem',
}));

const StyledTextField = styled(TextField)(({ theme, error }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: error ? theme.palette.error.main : undefined,
        },
        '&:hover fieldset': {
            borderColor: error ? theme.palette.error.main : undefined,
        },
        '&.Mui-focused fieldset': {
            borderColor: error ? theme.palette.error.main : undefined,
        },
    },
    '& .MuiInputBase-input': {
        height: '1.4375em',
    },
}));

const DynamicCheckboxLabel = ({ name, checkedLabel, uncheckedLabel }) => {
    const [field] = useField(name);
    return (
        <FormControlLabel
            control={<Checkbox {...field} checked={field.value} color="primary" />}
            label={field.value ? checkedLabel : uncheckedLabel}
        />
    );
};

export default function AccountEditProfile({ userAccount, timeZones, handleIsDirty }) {
    const theme = useTheme();
    const toast = useRef(null);
    const [isThisDirty, setIsThisDirty] = useState(false);  // this refreshes the data after a change to the profile is made

    const handleIsDirtyProfile = (isThisDirty) => {
        handleIsDirty(isThisDirty);
    };

    const PhoneNumberField = ({ field, form, ...props }) => {
        const handleChange = (e) => {
            const formatted = formatInputPhoneNumber(e.target.value);
            form.setFieldValue(field.name, formatted);
        };

        return (
            <StyledTextField
                {...field}
                {...props}
                onChange={handleChange}
                value={field.value}
            />
        );
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ paddingTop: 4 }}
        >
            <Toast ref={toast} />
            <Formik
                initialValues={{
                    FirstName: userAccount.FirstName || '',
                    LastName: userAccount.LastName || '',
                    PhoneCountryCode: userAccount.PhoneCountryCode.trim() || '',
                    PhoneNumber: formatPhoneNumber(userAccount.PhoneCountryCode.trim() ,userAccount.PhoneNumber).replace("+1 ", "") || '',
                    TimeZoneID: userAccount.TimeZoneID || '',
                    EmailOptOut: userAccount.EmailOptOut || false,
                    TextOptOut: userAccount.TextOptOut || false,
                }}
                validationSchema={EditSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const requestBody = {
                            adminID: userAccount.UserID, 
                            userID: userAccount.UserID,
                            firstName: values.FirstName,
                            lastName: values.LastName,
                            jobTitle: userAccount.JobTitle, 
                            department: userAccount.Department, 
                            phoneCountryCode: values.PhoneCountryCode,
                            phoneNumber: values.PhoneNumber.replace(/[^\d]/g, ''),
                            emailOptOut: values.EmailOptOut,
                            textOptOut: values.TextOptOut,
                            timeZoneID: values.TimeZoneID,
                            isEnabled: userAccount.IsEnabled 
                        };

                        const response = await updateUserProfile({ userID: userAccount.UserID, body: requestBody });

                        handleIsDirtyProfile(true);    // Reloads the UserAccount data to refresh the component

                        
                        let toastMessage = createToastSuccess('Profile edits have been saved.');
                        toast.current.show(toastMessage);  

                    } catch (error) {
                        handleIsDirtyProfile(false);

                        let toastMessage = createToastSuccess(`An error occurred while attempting to save profile edits.\n${error.message}`);
                        toast.current.show(toastMessage);  
                        console.error('Error updating profile:', error);
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ submitForm, resetForm, isSubmitting, touched, errors }) => (
                    <Form>                        
                        <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <Avatar sx={{ bgcolor: theme.palette.primary.main }} aria-label="edit profile">
                                            <AccountCircleIcon />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" component="div">
                                            Edit Profile
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <Field
                                        name="FirstName"
                                        as={StyledTextField}
                                        fullWidth
                                        margin="normal"
                                        label="First Name"                                        
                                        error={touched.FirstName && Boolean(errors.FirstName)}
                                    />
                                    <ErrorMessage name="FirstName" component={StyledErrorMessage} />
                                </Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <Field
                                        name="LastName"
                                        as={StyledTextField}
                                        label="Last Name"
                                        fullWidth
                                        margin="normal"
                                        error={touched.LastName && Boolean(errors.LastName)}
                                    />
                                    <ErrorMessage name="LastName" component={StyledErrorMessage} />
                                </Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <Typography variant="body1">
                                        Email: {userAccount.Email}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{ width: '100%' }}>
                                    <Grid item xs={4}>
                                        <Field
                                            name="PhoneCountryCode"
                                            as={StyledTextField}
                                            label="Phone Country Code"
                                            fullWidth
                                            margin="normal"
                                            error={touched.PhoneCountryCode && Boolean(errors.PhoneCountryCode)}
                                            InputProps={{ readOnly: true }}
                                        />
                                        <ErrorMessage name="PhoneCountryCode" component={StyledErrorMessage} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field
                                            name="PhoneNumber"
                                            component={PhoneNumberField}
                                            label="Phone Number"
                                            fullWidth
                                            margin="normal"
                                            error={touched.PhoneNumber && Boolean(errors.PhoneNumber)}
                                        />
                                        <ErrorMessage name="PhoneNumber" component={StyledErrorMessage} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <Field
                                        name="TimeZoneID"
                                        as={StyledTextField}
                                        label="Time Zone ID"
                                        fullWidth
                                        margin="normal"
                                        select
                                        error={touched.TimeZoneID && Boolean(errors.TimeZoneID)}
                                    >
                                        {timeZones.map(zone => (
                                            <MenuItem key={zone.Value} value={zone.Value}>
                                                {zone.Name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="TimeZoneID" component={StyledErrorMessage} />
                                </Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <DynamicCheckboxLabel
                                        name="EmailOptOut"
                                        checkedLabel="Receive Email Notifications"
                                        uncheckedLabel="You aren't receiving Email notifications"
                                    />
                                    <ErrorMessage name="EmailOptOut" component={StyledErrorMessage} />
                                </Grid>
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <DynamicCheckboxLabel
                                        name="TextOptOut"
                                        checkedLabel="Receive Text Notifications"
                                        uncheckedLabel="You aren't receiving Text notifications"
                                    />
                                    <ErrorMessage name="TextOptOut" component={StyledErrorMessage} />
                                </Grid>
                                <Grid item container xs={12} sx={{ width: '100%' }} justifyContent="space-evenly">
                                    <Button
                                        sx={{ margin: 1 }}
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        sx={{ margin: 1 }}
                                        variant="contained"
                                        color="secondary"
                                        disabled={isSubmitting}
                                        onClick={resetForm}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}