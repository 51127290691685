import React from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@mui/material";
import { useClasses } from '../../customHooks';
import { GetSeverityStatusColor } from '../../helpers/MiscFunctions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreMenu from '../Generic/MoreMenu';
import Typography from '@mui/material/Typography';
import CustomAlarmChip from '../Generic/CustomAlarmChip';

const styles = {
    overrides: {
        MUITableHeadRow: {
            root: {
                display: 'none'
            }
        }
    },
}

export default function AlarmDashboardTableExpandedRow(props) {

    const classes = useClasses(styles);

    const options = {
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        responsive: 'vertical',
        pagination: false,
        selectableRowsHideCheckboxes: true,
        enableNestedDataAccess: ".",

    };

    // Define columns for node-level table
    const columns = [
        {
            name: "AlarmName",
            label: "Name",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                sort: true,
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        let name1 = obj1.data.toLowerCase().split(" ").reverse().join(" ");
                        let name2 = obj2.data.toLowerCase().split(" ").reverse().join(" ");
                        if (order === 'asc') {
                            return name1 > name2 ? 1 : -1;
                        }
                        if (order === 'desc') {
                            return name1 < name2 ? 1 : -1;
                        }
                    }
                },
                customBodyRenderLite: (dataIndex) => {

                    return (
                        <span>
                            <strong>{props.data[dataIndex].AlarmName}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "AlarmType",
            label: "Type",
            options: {
                searchable: false,
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
            }

        },
        {
            name: "StatusSeverityLevel",
            label: "Severity",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: false,
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomAlarmChip
                            statusDisplayName={props.data[dataIndex].StatusDisplayName}
                            statusSeverityLevel={props.data[dataIndex].StatusSeverityLevel}
                            color={props.data[dataIndex].StatusHexColor}
                            keyName={
                                dataIndex + "-" +
                                props.data[dataIndex].NodeChannelID}

                        />
                    );
                }
            }
        },
        {
            name: "CurrentAlarmStatus",
            label: "Current Status",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: false,
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    let triggeredLevel = props.data[dataIndex].IsAlarmTriggered ? props.data[dataIndex].StatusSeverityLevel : null;

                    return (
                        triggeredLevel <= 1 ? 'Not Triggered' : 'Triggered'
                    );
                }
            }
        },
        {
            name: "ThresholdOperatorDisplayName",
            label: "Threshold",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                searchable: false,
                customBodyRenderLite: (dataIndex) => {
                    //console.log(props.data[dataIndex]);
                    return (
                        <span>
                            {props.data[dataIndex].ThresholdOperatorDisplayName + " " +
                                (props.data[dataIndex].ThresholdTriggerValue ?
                                    props.data[dataIndex].ThresholdTriggerValue.toFixed(3) : "") + " " +
                                props.data[dataIndex].ThresholdUnitTypeSymbol
                            }
                        </span>
                    )
                }
            }

        },
        
        
        {
            name: "AlarmMenu",
            options: {
                customHeadLabelRender: (_columnMeta) => {
                    return ("");
                },
                onClick: (e) => { e.stopPropagation(); e.preventDefault(); },
                customBodyRenderLite: (dataIndex) => {

                    //console.log(props.data[dataIndex]);

                    let menuItems;
                    if (props.data[dataIndex].AlarmType !== 'Vibration') {
                        menuItems =
                            [
                                {
                                    menuItemText: "View",
                                    icon: <PreviewIcon />,
                                    dataSourceID: `${props.data[dataIndex].ComputedDataSourceID}`,
                                    ID: `${props.data[dataIndex].AlarmID}`,
                                    route: `/Alarm/EventDetails/view/${props.companyID}/${props.userID}/${props.data[dataIndex].ComputedDataSourceID}/${props.data[dataIndex].AlarmID}/${false}`
                                },
                                {
                                    menuItemText: "Edit",
                                    icon: <EditIcon />,
                                    dataSourceID: `${props.data[dataIndex].ComputedDataSourceID}`,
                                    ID: `${props.data[dataIndex].AlarmID}`,
                                    route: `/Alarm/Edit/${props.companyID}/${props.userID}/${props.data[dataIndex].AlarmID}/${props.data[dataIndex].ComputedDataSourceID}/${props.viewAll}/edit`
                                },
                                {
                                    menuItemText: "Clone",
                                    icon: <ContentCopyIcon />,
                                    dataSourceID: `${props.data[dataIndex].ComputedDataSourceID}`,
                                    ID: `${props.data[dataIndex].AlarmID}`,
                                    route: `/Alarm/Clone/${props.companyID}/${props.userID}/${props.data[dataIndex].AlarmID}/${props.data[dataIndex].ComputedDataSourceID}/${props.viewAll}/clone`
                                },
                                {
                                    menuItemText: "Delete",
                                    icon: <DeleteIcon />,
                                    dataSourceID: `${props.data[dataIndex].ComputedDataSourceID}`,
                                    ID: `${props.data[dataIndex].AlarmID}`,
                                    route: ''
                                }
                            ];
                    } else {
                        menuItems =
                            [

                                {
                                    menuItemText: "SummaryButton",
                                    companyID: props.companyID,
                                    userID: props.userID,
                                    objectDefectSummary: props.data[dataIndex].ObjectDefectSummary,
                                },
                                {
                                    menuItemText: "View Asset Detail",
                                    icon: <PreviewIcon />,
                                    dataSourceID: `${props.data[dataIndex].ComputedDataSourceID}`,
                                    ID: `${props.data[dataIndex].AlarmID}`,
                                    route: `/Vibration/AssetDetail/${props.companyID}/${props.userID}/${props.vibrationAssetID}/${props.viewAll}`
                                },
                                {
                                    menuItemText: "View Alarm",
                                    icon: <PreviewIcon />,
                                    dataSourceID: `${props.data[dataIndex].ComputedDataSourceID}`,
                                    ID: `${props.data[dataIndex].AlarmID}`,
                                    route: `/Alarm/EventDetails/view/${props.companyID}/${props.userID}/${props.data[dataIndex].ComputedDataSourceID}/${props.data[dataIndex].AlarmID}/${true}`
                                },
                            ];

                    }
                    
                    let menuKey = "alarmID-" + props.data[dataIndex].AlarmID + '-' + props.data[dataIndex].ComputedDataSourceID;

                    return (
                        <MoreMenu
                            menuKey={menuKey}
                            menuTitle={"More..."}
                            buttonIcon={<MoreHorizIcon />}
                            menuItems={menuItems}
                            showToast={true}
                            nodeDisplayName={props.nodeDisplayName}
                            onDeleteClick={(alarmID, compDSID, _onToastClose) => {
                                props.onDeleteFromExpandedRow(alarmID, compDSID)
                            }}
                            onToastCloseFromMoreMenu={props.onToastCloseFromExpandedRow}
                        />
                    );
                }
            }
        },
        {
            name: "NodeID",
            options: {
                display: "excluded"
            }
        },
    ];
    //console.log(props.data);

    return (
        <React.Fragment>
            {
                <TableCell colSpan={props.colspan - 2}>
                    <MUIDataTable
                        title={""}
                        columns={columns}
                        data={props.data}
                        options={options}
                        className={classes.overrides}
                    />
                </TableCell>
            }
        </React.Fragment>
    );
}


