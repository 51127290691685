import * as Yup from "yup";

export const EditSchema = Yup.object().shape({
    FirstName: Yup.string().required('Required'),
    LastName: Yup.string().required('Required'),
    PhoneCountryCode: Yup.string().required('Required'),
    PhoneNumber: Yup.string().required('Required').max(14),
    TimeZoneID: Yup.number().required('Required'),
    EmailOptOut: Yup.boolean().required('Required'),
    TextOptOut: Yup.boolean().required('Required'),
});

export const formatInputPhoneNumber = (input) => {
    const digits = input.replace(/\D/g, '');
    const match = digits.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);
    if (match) {
        let result = `(${match[1]}`;
        if (match[2]) {
            result += `) ${match[2]}`;
        }
        if (match[3]) {
            result += `-${match[3]}`;
        }
        return result;
    }
    return input;
};

export const formatPhoneNumber = (countryCode, phoneNumber) => {
    if (!countryCode || !phoneNumber) {
        return 'Invalid phone number';
    }

    const trimmedCountryCode = countryCode.trim();

    const formatUSPhoneNumber = (number) => {
        const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return number;
    };

    if (trimmedCountryCode === '1') {
        return `+${trimmedCountryCode} ${formatUSPhoneNumber(phoneNumber)}`;
    } else {
        return `+${trimmedCountryCode} ${phoneNumber}`;
    }
};