import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { TableCell } from "@mui/material";
import { useClasses } from '../../customHooks';
import { useTheme } from "@mui/material/styles";
import { fetchDefectDetail } from '../../repositories/DefectQueryFunctions';
import AssetDetail from '../../pages/Vibration/AssetDetail'
import ForemanChatInitiatorCard from "../Vibration/Foreman/ForemanChatInitiatorCard";
import StatusMessage from '../Generic/StatusMessage';

const styles = {
    overrides: {
        MUITableHeadRow: {
            root: {
                display: 'none'
            }
        }
    },
}

export default function DefectDashboardInnerTableExpandedRow(props) {
    const classes = useClasses(styles);
    const theme = useTheme();

    const [refresh, setRefresh] = useState(0);
    const [errorState, setErrorState] = useState();
    const [defectDetail, setDefectDetail] = useState([]);
    const [completeList, setCompleteList] = useState({
        header: "",
        ingredients: []
    });
    const [burntList, setBurntList] = useState({
        header: "",
        ingredients: []
    });
    const [unknownList, setUnknownList] = useState({
        header: "",
        ingredients: []
    });

    const [isLoadingDetail, setIsLoadingDetail] = useState(true);

    const options = {
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        responsive: 'vertical',
        pagination: false,
        selectableRowsHideCheckboxes: true,
        enableNestedDataAccess: ".",
    };

    // Fetch Vibration Objects to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingDefectDetail, error: errorDefectDetail, data: dataA, refetch: refetchDetail } =
        useQuery(["detail", props.recipeStateID, { refresh }], fetchDefectDetail, {
            onSuccess: (dataA) => {
                if (JSON.stringify(dataA) !== JSON.stringify(defectDetail)) {
                    setIsLoadingDetail(true);
                    setDefectDetail(dataA);
                    
                }
            },
            onError: (errorDefectDetail) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorDefectDetail || "An unexpected error occurred.");
            }
        });

    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingDetail) {
            handleDetailChange();
        }
    }, [defectDetail]);

    // Handler for structural change to network
    const handleDetailChange = async () => {

        let newCompleteList = {
            header: props.defectName + " is indicated here by:",
            ingredients: []
        }

        let newBurntList = {
            header: props.defectName + " is usually coupled with these missing indicators:",
            ingredients: []
        }

        let newUnknownList = {
            header: "Full confidence in " + props.defectName + " is limited because we don't know whether:",
            ingredients: []
        }

        // Cycle through each DB ingredient object
        defectDetail.map((d) => {
            if (d.PercentComplete == 100) {
                newCompleteList.ingredients.push(d.IngredientDescription);
            } else if (d.PercentBurnt == 100) {
                newBurntList.ingredients.push(d.IngredientDescription);
            } else if (d.PercentUnknown > 0) {
                newUnknownList.ingredients.push(d.IngredientDescription);
            }
        })

        // Update State
        setCompleteList(newCompleteList)
        setBurntList(newBurntList)
        setUnknownList(newUnknownList)
        setIsLoadingDetail(false);
    }


    const ExplanationHTML = (htmlList) => {
        return (
            <div className="container">
                <h3 style={{ marginTop: '20px' }}><strong>{htmlList.header}</strong><br /></h3>
                {htmlList.ingredients.map((ingredient, i) => {
                    return (<h5 key={i}><strong> - </strong>{ingredient}</h5>)
                })}
            </div>
        )
    }

    return (
        <React.Fragment>
            {
                <TableCell colSpan={props.colspan}>

                    {(isLoadingDetail) && <div>Loading Detail From Database...</div>}
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around"
                    }}>
                        {errorState &&
                            <StatusMessage
                                open={errorState}
                                severity="error"
                                location="Add Asset"
                                statusCode={errorState.request.status}
                                message={errorState.message}
                                error={errorState}
                            />
                        }
                        <div>
                            {!isLoadingDetail && completeList.ingredients.length > 0 && ExplanationHTML(completeList)}
                            {!isLoadingDetail && burntList.ingredients.length > 0 && ExplanationHTML(burntList)}
                            {!isLoadingDetail && unknownList.ingredients.length > 0 && ExplanationHTML(unknownList)}
                        </div>
                        <ForemanChatInitiatorCard recipeStateID={props.recipeStateID} />
                    </div>
                    {!isLoadingDetail &&
                        <AssetDetail
                            companyID={props.companyID}
                            objectID={props.vibrationObjectID}
                            userID={props.userID}
                            hideBackToDashboard={true}
                        />
                    }
                </TableCell>
            }
        </React.Fragment>
    );
}


