import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Button,
    IconButton,
    Typography,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; 

export default function HomeDashboard() {

    const metrics = [
        { title: 'Node Activity', body: 'Detailed node activity status.' },
        { title: 'Node Status', body: 'Overall status of all nodes.' },
        { title: 'Uptime Status', body: 'Current uptime percentage.' },
    ];

    const managementMetrics = [
        { title: 'Manage Assets', body: 'Overview and control of assets.' },
        { title: 'Manage Control Gates', body: 'Manage access control gates.' },
        { title: 'Manage Alarms', body: 'Configure and monitor alarms.' },
    ];

    return (
        <Grid container spacing={3} sx={{ padding: 3 }}>
            {/* First Row */}
            {metrics.map((metric, index) => (
                <Grid item xs={12} sm={4} key={index}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant="h6" align="center">
                                    {metric.title}
                                </Typography>
                            }
                            action={
                                <Tooltip title="More Info">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {metric.body}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center' }}>
                            <Button variant="contained" color="primary">
                                Learn More
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}

            {/* Second Row */}
            {managementMetrics.map((metric, index) => (
                <Grid item xs={12} sm={4} key={index}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant="h6" align="center">
                                    {metric.title}
                                </Typography>
                            }
                            action={
                                <Tooltip title="More Info">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {metric.body}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center' }}>
                            <Button variant="contained" color="primary">
                                Learn More
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};