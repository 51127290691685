import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useClasses } from '../../customHooks';
import { Box, Button, CircularProgress, TableCell, Tooltip, Typography, TextField } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SaveIcon from '@mui/icons-material/Save';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MUIDataTable from "mui-datatables";
import ColorChangeIconButton from '../Generic/ColorChangeIconButton';
import SelectField from '../Generic/SelectField';
import {
    OnClickFunctionType
} from '../../repositories/IntegrationQueryFunctions';

const styles = {
    overrides: {
        MUITableHeadRow: {
            root: {
                display: 'none'
            }
        }
    },
}

export default function SyncAssetTableExpandedRow(props) {
    const classes = useClasses(styles);
    const theme = useTheme();

    const handleUndoButtonClick = (asset, onClickFunctionType) => {
        props.onUndoClick(asset, onClickFunctionType);
    }

    const handleTriggerTestEvent = (asset) => {
        props.handleTriggerTestEvent(asset);
    }

    const handleSelectChange = (assetID, newValue) => {
        props.onSelectionChange(assetID, newValue);
    }

    const handleSyncAsset = (asset) => {
        props.handleSyncAsset(asset);
    };

    const vendorName = props.companyInfo.CMMS.VendorName;
    const triggerTestEventTooltip = `Generate a Test Event to verify integration or to troubleshoot Event handling within ${vendorName}.`;
    const undoDoNotSyncTooltip = `Remove the Do Not Sync tag from the ${vendorName} Asset.`;
    const removeSyncTooltip = `Remove the assigned Maintenance Hub Asset.`;

    const options = {
        fixedHeader: true,
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: null,
        responsive: 'vertical',
        pagination: true,
        selectableRowsHideCheckboxes: true,
        enableNestedDataAccess: ".",
    };

    const columns = [
        {
            name: "Code",
            label: "Code",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                customBodyRenderLite: (dataIndex) => {
                    if (!props.data[dataIndex]) {
                        return null;
                    }

                    return (
                        <span>
                            {props.data[dataIndex].Code}
                        </span>
                    );
                },
                searchable: true,
                filter: false,
                sort: true
            },
        },
        {
            name: "Name",
            label: `${vendorName} Asset`,
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                customBodyRenderLite: (dataIndex) => {
                    if (!props.data[dataIndex]) {
                        return null;
                    }

                    return (
                        <span>
                            {props.data[dataIndex].Name}
                        </span>
                    );
                },
                searchable: true,
                filter: false,
                sort: true
            },
        },
        {
            name: "IsIgnored",
            label: "Do Not Sync",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                customBodyRenderLite: (dataIndex) => {
                    if (!props.data[dataIndex]) {
                        return null;
                    }

                    return (
                        <ColorChangeIconButton
                            isSelected={props.selections.find(
                                selection => selection.id === props.data[dataIndex].ID)?.action === 0}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleSelectChange(props.data[dataIndex].ID, 0);
                            }}
                            ariaLabel="Do Not Sync"
                            Icon={TaskAltIcon}
                            selectedColor={"success"}
                            defaultColor={"default"}
                            disabled={props.data[dataIndex].IsSynchronized}
                            key={`icon-button-ignore-${props.data[dataIndex].ID}`}
                        />
                    );
                },
                searchable: false,
                filter: false,
                sort: true
            },
        },
        {
            name: "CreateNew",
            label: "Create New Maintenance Hub Asset",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                customBodyRenderLite: (dataIndex) => {
                    if (!props.data[dataIndex]) {
                        return null;
                    }

                    return (
                        <ColorChangeIconButton
                            isSelected={props.selections.find(selection => selection.id === props.data[dataIndex].ID)?.action === -1}
                            onClick={() => handleSelectChange(props.data[dataIndex].ID, -1)}
                            ariaLabel="createNew"
                            Icon={TaskAltIcon}
                            selectedColor={"success"}
                            defaultColor={"default"}
                            disabled={props.data[dataIndex].IsSynchronized}
                            key={`icon-button-createnew-${props.data[dataIndex].ID}`}
                        />
                    );
                },
                searchable: true,
                filter: false,
                sort: true
            },
        },
        {
            name: "VibrationAsset",
            label: "Maintenance Hub Asset",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (<Typography variant="h8"><strong>{columnMeta.label}</strong></Typography>);
                },
                customBodyRenderLite: (dataIndex) => {
                    if (!props.data[dataIndex]) {
                        return null;
                    }

                    const asset = props.data[dataIndex];
                    const selectionObject = props.selections.find(selection => selection.id === asset.ID);
                    const selectedValue = selectionObject ? selectionObject.action : null;

                    if (asset.IsSynchronized) {
                        const matchingOption = props.selectOptions.find(option => option.ID === asset.VibrationObjectID);

                        return (
                            <div>
                                {asset.IsIgnored ? "Do Not Sync" :
                                    (matchingOption ? matchingOption.DisplayName : asset.VibrationObjectName || asset.Name)}
                            </div>
                        );
                    } else {
                        const options = props.selectOptions.filter(obj => {
                            const isSelectedElsewhere = props.selections.some(selection =>
                                selection.action === obj.ID && selection.id !== asset.ID);
                            const isUsedInData = props.data.some(dataItem =>
                                dataItem.VibrationObjectID === obj.ID && dataItem.ID !== asset.ID);
                            return (!isSelectedElsewhere || obj.ID === selectedValue) && !isUsedInData;
                        }).map(obj => ({ label: `${obj.DisplayName} (${obj.ID})`, value: obj.ID }));

                        return (
                            <>
                                {props.selections.find(selection => selection.id === props.data[dataIndex].ID)?.action !== -1 && (
                                    <SelectField
                                        name={`vibrationObjectID-${asset.ID}-${dataIndex}`}
                                        options={options}
                                        selectedValue={selectedValue}
                                        onChange={(newValue) => handleSelectChange(asset.ID, newValue ? newValue.value : null)}
                                        width="100%"
                                        enabled="true"
                                        key={`vibrationObjectID-${asset.ID}-${dataIndex}`}
                                    />
                                )}
                                {props.selections.find(
                                    selection => selection.id === props.data[dataIndex].ID)?.action === -1 && (
                                    <TextField
                                        name={`vibrationObjectName-${asset.ID}-${dataIndex}`}
                                        width="100%"
                                        style={{ width: '100%' }}
                                        enabled="true"
                                        key={`vibrationObjectName-${asset.ID}-${dataIndex}`}
                                        defaultValue={asset.Name}
                                        onBlur={(element) => props.toggleAssetNameOverride(asset.ID, element.target.value)}
                                    />
                                )}
                            </>
                        );
                    }
                },
                searchable: true,
                filter: false,
                sort: true
            },
        },
        {
            name: "TriggerEvent",
            label: " ",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    if (!props.data[dataIndex]) {
                        return null;
                    }

                    const asset = props.data[dataIndex];

                    const canSaveRow = () => {
                        const selection = props.selections.find(selection => selection.id === props.data[dataIndex].ID)?.action;
                        return typeof selection === 'undefined';
                    };

                    if (asset.IsSynchronized && !asset.IsIgnored) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={removeSyncTooltip} style={{ marginRight: '10px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ position: 'relative' }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleUndoButtonClick({
                                                    ...asset, ...{
                                                        VibrationObjectID: null,
                                                        IsIgnored: true,
                                                        IsSynchronized: false,
                                                        VendorAssetID: 0,
                                                    }
                                                }, OnClickFunctionType.Unsync)}>   
                                                <UndoIcon />
                                                Undo
                                            </Button>
                                            {props.assetLoadingStates[asset.ID] === true && (
                                                <CircularProgress
                                                    size={25}
                                                    color="success"
                                                    sx={{                                                        
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Tooltip>
                                <Tooltip title={triggerTestEventTooltip}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ position: 'relative' }}>
                                            <Button
                                                style={{ minWidth: '200px' }}
                                                variant="contained"
                                                startIcon={<NotificationsActiveIcon />}
                                                onClick={() => handleTriggerTestEvent(asset)}>
                                                Trigger Test Event
                                            </Button>
                                            {props.assetLoadingStates[asset.ID] === 'test' && (
                                                <CircularProgress
                                                    size={25}
                                                    sx={{
                                                        color: "success",
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Tooltip>
                            </div>
                        );
                    } else if (asset.IsSynchronized && asset.IsIgnored) {
                        return (
                            <Tooltip title={undoDoNotSyncTooltip}>                           
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                            color="secondary"
                                        onClick={() => handleUndoButtonClick({
                                            ...asset, ...{
                                                VibrationObjectID: null,
                                                IsIgnored: false,
                                                IsSynchronized: false,
                                                VendorAssetID: 0,
                                            }
                                        }, OnClickFunctionType.UndoDoNotSync)}> 
                                        <UndoIcon />
                                        Undo
                                    </Button>
                                    {props.assetLoadingStates[asset.ID] === true && (
                                        <CircularProgress
                                            size={25}
                                            sx={{
                                                color: "success",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                        )}
                                </Box>
                            </Box>
                            </Tooltip >
                        );
                    } else {
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        disabled={canSaveRow()}
                                        onClick={() => {
                                            const selection = props.selections.find(
                                                selection => selection.id === props.data[dataIndex].ID)?.action;

                                            if (selection === -1) {
                                                asset.VibrationObjectID = null;
                                            } else if (selection === 0) {
                                                asset.VibrationObjectID = selection;
                                            } else if (selection >= 1) {
                                                asset.VibrationObjectID = selection;
                                            } else if (typeof selection === 'undefined') {
                                                return;
                                            }

                                            if (props.rowNameOverrides[asset.ID]) {
                                                asset.VibrationObjectName = props.rowNameOverrides[asset.ID];
                                            }

                                            handleSyncAsset(asset);
                                        }}>
                                        Apply
                                    </Button>
                                    {props.assetLoadingStates[asset.ID] === true && (
                                        <CircularProgress
                                            size={25}
                                            sx={{
                                                color: "success",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        );
                    }
                },
                searchable: false,
                filter: false,
                sort: false
            },
        },
    ];

    return (
        <TableCell colSpan={2}>
            <MUIDataTable
                title={""}
                columns={columns}
                data={props.data}
                options={options}
                className={classes.overrides}
            />
        </TableCell>
    );
}
