import React  from 'react';
import { Paper, Modal, Button, Typography, Grid, Box, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SubmitModal from '../Modals/SubmitModal'

export default function AssignNewCompanyModal(props) {
    const formStyle = {
        my: '3%'
    }
    
    return (
        <Box>
            <SubmitModal
                title="Assign to New Company"
                open={props.assignModalOpen}
                handleSave={props.handleAssignment}
                handleClose={props.handleAssignModalClose}
            >
                <Typography>{`Move ${props.assignObject?.DisplayName} to a different company. Items moved will no longer be visible within this company.`}</Typography>
                <FormControl
                    sx={formStyle}
                    fullWidth
                >
                    <InputLabel id="company-select">Select company</InputLabel>
                    <Select
                        labelId="company-select"
                        label="Select company"
                        value={props.selectedCompany}
                        onChange={props.handleSelectedCompanyChange}

                    >
                        {props.companyList.map((company, index) => {
                            return (<MenuItem key={index} value={company.CompanyID}>{company.CompanyName}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </SubmitModal>
        </Box>

    )
}