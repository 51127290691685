import NotificationsIcon from '@mui/icons-material/Notifications';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box, Button, Grid, Paper, Stack, Step,
    StepConnector,
    StepLabel, Stepper, Typography
} from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { Field, Form, Formik } from 'formik';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useQuery } from "react-query";
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyInfoContext } from '../../App';
import AlarmStep1Content from '../../components/Alarm/AlarmStep1Content';
import AlarmStep1ContentEdit from '../../components/Alarm/AlarmStep1ContentEdit';
import AlarmStep2Content from '../../components/Alarm/AlarmStep2Content';
import AlarmStep3Content from '../../components/Alarm/AlarmStep3Content';
import CustomHeader from '../../components/Generic/CustomHeader';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import StatusMessage from "../../components/Generic/StatusMessage";
import { useClasses } from '../../customHooks';
import { editPostObject } from '../../helpers/AlarmHelpers';
import { createToastError, createToastSuccess, KeysToLowerCase } from "../../helpers/MiscFunctions";
import { alarmEditFormModel } from '../../models/Alarm/alarmFormModel';
import { alarmInitialValues } from '../../models/Alarm/alarmInitialValues';
import { alarmAddValidationSchema } from '../../models/Alarm/alarmValidationSchema';
import { fetchAlarm, fetchCompanyEnums, postAlarm, updateAlarm } from '../../repositories/AlarmQueryFunctions';
import { fetchCompanyInfo } from '../../repositories/CompanyQueryFunctions';
import theme from "../../theme";

const steps = [
    {
        name: 'step0',
        label: 'Step 1: Edit Alarm Name and Targets',
        description1: `` ,  
        description2: ``,
        validationSchema: alarmAddValidationSchema[0]
    },
    {
        name: 'step1',
        label: 'Step 2: Edit Alarm Rules',
        description1: '',
        description2: ``,
        validationSchema: alarmAddValidationSchema[1]
    },
    {
        name: 'step2',
        label: 'Step 3: Review Changes and Save',
        description1: ``,
        description2: ``,
        validationSchema: alarmAddValidationSchema[2]
    },
];

const styles = {
    stepper: {
        position: 'relative',
        width: '50%',
        margin: 'auto',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    paper: {
        elevation: 6,
        position: 'relative',
        width: '70%',
        margin: 'auto',
    }
}
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(275deg, rgba(33,150,243,1) 35%, rgba(208,222,224,1) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(275deg, rgba(76,175,80,1) 35%, rgba(208,224,211,1) 100%))',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(rgba(33,150,243,1) 35%, rgba(33,150,243,1) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient(rgba(76,175,80,1) 35%, rgba(76,175,80,1) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <NotificationsIcon />,
        2: <SettingsIcon />,
        3: <SaveIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function AlarmStepperEdit() {
    // Consume parameters from URL access
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
    const navigate = useNavigate();
    const { companyID, userID, viewAll, alarmID, crudType, cdsid } = useParams();

    const toast = useRef(null);

    const classes = useClasses(styles);

    //enumerations
    const [activeStep, setActiveStep] = useState(0);
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);
    const [alarmStatusValues, setAlarmStatusValues] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationPeriods, setNotificationPeriods] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [dataTypes, setDataTypes] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [comparisonOperatorTypes, setComparisonOperatorTypes] = useState([]);
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);

    const [alarmDetails, setAlarmDetails] = useState({});
    const [alarmThresholds, setAlarmThresholds] = useState([]);
    const [alarmRecipients, setAlarmRecipients] = useState([]);
    const [alarmTargets, setAlarmTargets] = useState([]);
    const [alarmTargetStates, setAlarmTargetStates] = useState({});
    const [alarmInfoNodes, setAlarmInfoNodes] = useState([]);
    const [isLoadedDetails, setIsLoadedDetails] = useState(false);
    const [alarmData, setAlarmData] = useState({});
    const [myInitialValues, setMyInitialValues] = useState(alarmInitialValues);
    const [finishing, setFinishing] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [dataTypeTargets, setDataTypeTargets] = useState([]);
    const [recipientMapping, setRecipientMapping] = useState([]);

    const [assignedNodeList, setAssignedNodeList] = useState([]);
    const [thisTarget, setThisTarget] = useState("");
    const [errorState, setErrorState] = useState();

    const isLastStep = activeStep === steps.length - 1;

    const { isLoading, error, data } =
        useQuery(["companyInfo", companyID], fetchCompanyInfo, {
            onSuccess: (data) => {
                setCompanyInfo(data);
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(error || "An unexpected error occurred.");
            }
        });
    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } =
        useQuery(["enums", companyID, viewAll], fetchCompanyEnums, {
            onSuccess: (dataE) => {
                setAlarmStatusInfo(dataE.CompanyStdAlarmStatuses);

                setAlarmStatusValues(dataE.CompanyStdAlarmStatuses.map((item) => ({
                    "value": item.SystemStatusID,
                    "label": item.StatusDisplayName
                })));

                setNotifications(dataE.CompanyNotifications.map((item) => ({
                    "value": item.NotificationID,
                    "label": item.NotificationDisplayName
                })));

                setNotificationPeriods(dataE.NotificationPeriods.map((item) => ({
                    "value": item.PeriodID,
                    "label": item.PeriodDisplayName
                })));

                setDataTypes(dataE.DataTypes.map((item) => ({
                    "value": item.DataTypeID,
                    "label": item.DisplayName
                })));

                setUnitTypes(dataE.UnitTypes.map((item) => ({
                    "value": item.UnitTypeID,
                    "label": item.DisplayName,
                    "dataTypeID": item.DataTypeID
                })));

                setComparisonOperatorTypes(dataE.ThresholdComparisonOperatorTypes.map((item) => ({
                    "value": item.ThresholdComparisonOperatorTypeID,
                    "label": item.DisplayName,
                    "displaySymbol": item.DisplaySymbol
                })));

                const transformedData = dataE.CompanyRecipients.map((item) => ({
                    "value": item.RecipientID,
                    "label": item.UserName,
                    "isGroup": item.IsGroup,
                    "group": item.IsGroup ? "Recipient Group" : "Recipient",
                }));

                const groupedData = transformedData.reduce((acc, item) => {
                    if (!acc[item.group]) {
                        acc[item.group] = [];
                    }
                    acc[item.group].push(item);
                    return acc;
                }, {});

                const menuItems = Object.entries(groupedData).map(([label, options]) => ({
                    label,
                    options,
                }));


                // Create a mapping from RecipientID to UserName
                let recipMapping = transformedData.reduce((acc, item) => {
                    acc[item.value] = item.label;
                    return acc;
                }, {});

                //console.log(recipMapping);
                setRecipientMapping(recipMapping);

                setRecipients(menuItems);
                setIsLoadedEnums(true);
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

    const { isLoading: isLoadingAlarm, error: alarmError, data: dataA } =
        useQuery(["alarm", alarmID, viewAll], fetchAlarm, 
            {
                onSuccess: (dataA) => {
                    ReactDOM.unstable_batchedUpdates(() => {
                        setAlarmDetails(dataA.Value.AlarmDetails);
                        setAlarmThresholds(dataA.Value.AlarmThresholds[0]);
                        
                        setAlarmInfoNodes(dataA.Value.AlarmInfoNodes);

                        let tempTargets = KeysToLowerCase(dataA.Value.AlarmTargets);
                        let tempStates = KeysToLowerCase(dataA.Value.AlarmTargetStates);  
                       
                        const transformedData = dataA.Value.AlarmRecipients.map((item) => ({
                            "value": item.RecipientID,
                            "label": recipientMapping[item.RecipientID] || null,
                            "isGroup": item.IsGroup,
                            "group": item.IsGroup ? "Recipient Group" : "Recipient",
                        }));

                        setAlarmRecipients(transformedData);

                        setAlarmTargets(tempTargets);
                        setAlarmTargetStates(tempStates);
                       
                        setAlarmData(dataA.Value);
                    });

                    setIsLoadedDetails(true);

                },
                onError: (alarmError) => {
                    // Handle the error here. For example, you can log the error or set an error state.
    
                    // Optionally, you can set an error state to display an error message to the user.
                    setErrorState(alarmError || "An unexpected error occurred.");
                }
            });
   
    useEffect(() => { 
        if (isLoadedDetails && isLoadedEnums) {

            let dataTypeName = dataTypes.find(x => x.value === alarmThresholds.DataTypeID).label;

            let assignedAlarmTarget = alarmInfoNodes
                .filter(x => x.IsAssigned === true)
                .map(x => x.ComputedDataSourceID);           

            let assignedList = alarmInfoNodes
                .filter(x => x.IsAssigned === true)
                .map(x => {
                    return {
                        NodeDisplayName: x.NodeDisplayName,
                        NodeChannelDisplayName: x.NodeChannelDisplayName,
                        ComputedDataSourceID: x.ComputedDataSourceID
                    };
                });

            let checkedTarget = assignedList
                .find(x => x.ComputedDataSourceID === assignedAlarmTarget);

            if (checkedTarget) {
                checkedTarget = {
                    ParentKey: checkedTarget.ParentKey,
                    ChildKey: checkedTarget.ChildKey,
                    GrandchildKey: checkedTarget.GrandchildKey
                }
            }


            setAssignedNodeList(assignedList);

            const initialValues = {
                "alarmName": alarmDetails.DisplayName,
                "alarmSummary": alarmDetails.Summary,
                "thresholdDataTypeID": alarmThresholds.DataTypeID,
                "thresholdDataTypeName": dataTypeName,
                "assignedComputedDataSourceIDs": cdsid ? [cdsid] : [], 
                "isCheckedTargets":  checkedTarget,
                "thresholdComparisonID": alarmThresholds.ThresholdComparisonOperatorTypeID,
                "thresholdTriggerValue": alarmThresholds.ThresholdValue,
                "thresholdUnitTypeID": alarmThresholds.UnitTypeID,
                "alarmSeverityID": alarmDetails.SystemStatusID,
                "sendNotification": !alarmDetails.NotificationID ? false : true,
                "notificationID": alarmDetails.NotificationID,
                "notificationRecipients": alarmRecipients ? alarmRecipients : [],
                "alarmID": alarmDetails.AlarmID
            };
            let foundItem = assignedList.find(item => String(item.ComputedDataSourceID).trim() === String(cdsid).trim());
            
            if (foundItem) {
                setThisTarget(foundItem);
            } else {
                setThisTarget(null);
            }
            // Set Formik initial values
            setMyInitialValues(initialValues);
        }
    }, [alarmData, isLoadedDetails]);
  
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function createHandleSubmit(formik) {
        return async function handleSubmit() {
            const isLastStep = activeStep === steps.length - 1;
            const currentStepFields = alarmAddValidationSchema.reduce((acc, schema) => {
                const fields = Object.keys(schema.describe().fields);
                return acc.concat(fields);
            }, []);

            const errors = await formik.validateForm();

            // Check if the only error is {isCheckedTargets: 'Required'}
            const isErrorOtherThanCheckedTargets = Object.entries(errors).some(([key, value]) => {
                return key !== 'isCheckedTargets' || value !== 'Required';
            });

            // Updated condition for stepErrors
            const stepErrors = currentStepFields.some(field => errors[field]) && isErrorOtherThanCheckedTargets;

            //console.log(errors);

            if (!stepErrors) {
                if (!isLastStep) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    // Final step submission is handled by handleFinalSubmit, nothing needs to be done here
                }
            } else {
                // Set touched state for current step fields
                currentStepFields.forEach((field) => {
                    formik.setFieldTouched(field, true);
                });
            }
        };
    }

    async function handleFinalSubmit(formik) {

        const postAlarmObj = editPostObject(formik.values, companyID, userID);

        let result; 

        if (crudType === 'edit') {
            result = await updateAlarm(postAlarmObj);
        } else {
            result = await postAlarm(postAlarmObj);
        }

        let toastMessage;

        const newAlarmID = result.data;
        const httpStatusCode = result.status;

        if (httpStatusCode === 200 && newAlarmID > 0) {
            toastMessage = createToastSuccess('Alarm has been saved.');

            // Navigate to the new URL with updated alarmID after showing the toast message.
            setTimeout(() => {
                navigate(`/Alarm/AlarmDashboard/${companyID}/${userID}/${viewAll}`);
            }, 7300);

        } else {
            toastMessage = createToastError('Alarm could not be saved. Please try again.');

            setTimeout(() => {
                navigate(`/Alarm/AlarmDashboard/${companyID}/${userID}/${viewAll}`);
            }, 7300);
        }

        setTimeout(() => {
            setFinishing(false);
            toast.current.show(toastMessage);
        }, 3000);
    }

    const handleDataTypeTargets = (dataTypeTargets) => {
        let tempTargets = KeysToLowerCase(dataTypeTargets);
        setDataTypeTargets(tempTargets);
    }

    const alarmSeverityLevel = (level) => {
        return alarmStatusInfo.find(item => item.SystemStatusID === level);
    }

    const handleBackButtonClick = () => {
        navigate(`/Alarm/AlarmDashboard/${companyID}/${userID}/${viewAll}`);
    }

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    };

    return (
        <Box sx={pageStyle}>
            <Toast ref={toast} />
            <Grid container item spacing={3} justifyContent="space-between">
                <Grid item xs={10}>
                    <CustomHeader headerText={crudType === "edit" ? "Edit Alarm " : "Clone Alarm "} />
                </Grid>
                {/* Back Button Grid Item */}
                <Grid item xs={2}>
                    <Button fullWidth onClick={handleBackButtonClick} variant="contained">Back To Dashboard</Button>
                </Grid>

            </Grid>
            <Paper style={styles.paper} elevation={6}>
                {(isLoadingEnums || !isLoadedEnums || !isLoadedDetails || isLoadingAlarm )
                    && <StatusBackdrop open={(isLoadingEnums || !isLoadedEnums || !isLoadedDetails || isLoadingAlarm)} />}
                {finishing && <StatusBackdrop open={finishing} />}
                {errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location="Edit Alarm"
                        statusCode={errorState.request.status}
                        message={errorState.message}
                        error={errorState}
                    />
                }                     
                <div style={{ height: '10px' }} /> {/* Add space above Stepper */}
                <Stepper alternativeLabel activeStep={activeStep} style={styles.stepper} connector={<ColorlibConnector />}>
                    {steps.map(({ label }) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Formik
                    initialValues={myInitialValues}
                    validationSchema={steps[activeStep].validationSchema}
                    onSubmit={handleFinalSubmit}
                    enableReinitialize={true}
                >
                    {(formik) => (
                        <Form id={alarmEditFormModel.formID}>
                            <div>
                                <div>
                                    <Typography variant="h5" align="center">{steps[activeStep].label}</Typography>
                                    <Typography variant="body1" align="center">
                                        {steps[activeStep].description1}
                                    </Typography>
                                    <Typography variant="body1" align="center" style={{ marginTop: '0.5em', marginBottom: '2em' }}>
                                        {steps[activeStep].description2}
                                    </Typography>
                                    {activeStep === 0 && crudType === 'edit' ? (
                                        <Field name="isCheckedTargets">
                                            {({ field }) => (
                                                <AlarmStep1ContentEdit                                                    
                                                    dataTypes={dataTypes}
                                                    unitTypes={unitTypes}
                                                    formik={formik}
                                                    companyID={companyID}
                                                    alarmTargetsList={alarmInfoNodes}
                                                    onDataTypeTargetsChange={handleDataTypeTargets}
                                                    assignedNodeList={assignedNodeList}
                                                    cdsid={cdsid}
                                                    thisTarget={thisTarget}
                                                />

                                            )}
                                        </Field>
                                    ) : null}
                                    {activeStep === 0 && crudType === 'clone' ? (
                                        <Field name="isCheckedTargets">
                                            {({ field }) => (
                                                <AlarmStep1Content
                                                    dataTypes={dataTypes}
                                                    unitTypes={unitTypes}
                                                    formik={formik}
                                                    companyID={companyID}
                                                    alarmTargetsList={alarmInfoNodes}
                                                    onDataTypeTargetsChange={handleDataTypeTargets}
                                                    assignedNodeList={assignedNodeList}
                                                    cdsid={cdsid}
                                                    thisTarget={thisTarget}
                                                />

                                            )}
                                        </Field>
                                    ) : null}
                                    {activeStep === 1 ? (
                                        <AlarmStep2Content
                                            formik={formik}
                                            unitTypes={unitTypes}
                                            notificationPeriods={notificationPeriods}
                                            notifications={notifications}
                                            recipients={recipients}
                                            comparisonOperatorTypes={comparisonOperatorTypes}
                                            alarmStatusInfo={alarmStatusValues}                                            
                                        />
                                    ) : null}
                                    {activeStep === 2 ? (                                        
                                        <AlarmStep3Content
                                            formik={formik}
                                            alarmStatusInfo={alarmSeverityLevel}
                                            alarmStatusValues={alarmStatusValues}
                                            unitTypes={unitTypes}
                                            notificationPeriods={notificationPeriods}
                                            notifications={notifications}
                                            comparisonOperatorTypes={comparisonOperatorTypes}
                                            dataTypeTargets={dataTypeTargets}
                                        />                                           
                                    ) : null}
                                    <Stack
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="baseline"
                                        spacing={4}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={styles.button}
                                            onClick={formik.resetForm} // Call the handleReset function on the onClick event
                                            disabled={disableButton}
                                        >
                                            Reset
                                        </Button>
                                        {activeStep !== 0 && (
                                            <Button
                                                style={styles.button}
                                                disabled={disableButton}
                                                onClick={handleBack}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={disableButton}
                                            onClick={(e) => {
                                                if (isLastStep) {
                                                    setFinishing(true);
                                                    setDisableButton(true);
                                                    handleFinalSubmit(formik);
                                                } else {
                                                    createHandleSubmit(formik)();
                                                }
                                            }}
                                        >
                                            {isLastStep ? "Finish" : "Next"}
                                        </Button>                                                                                
                                    </Stack>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div style={{ height: '10px' }} /> {/* Add space below Stepper */}
            </Paper>
        </Box>
    );

}



//const FormObserver = () => {
//    const { values, touched, errors } = useFormikContext();

//    //useEffect(() => {
//    //    console.log("FormObserver::errors", JSON.stringify(errors));
//    //}, [errors]);

//    //useEffect(() => {
//    //    console.log("FormObserver::touched", JSON.stringify(touched));
//    //}, [touched]);

//    useEffect(() => {
//        //console.log("FormObserver::values", JSON.stringify(values));
//    }, [values]);

//    //useEffect(() => {

//    //}, []);

//    return null;
//};