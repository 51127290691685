import React from 'react';
import { Typography, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const USER_TYPES = {
    STANDARD_USER: 0,
    ADVANCED_USER: 1,
    DASHBOARD_ADMIN: 2,
    COMPANY_ADMIN: 3,
}
const userTypeStr = ["Standard User", "Advanced User", "Dashboard Admin", "Company Admin"];
export default function ManageUsers(props) {
    return (
        <TableContainer sx={{ mx: "auto", width: "50%" }} component={Paper}>
            <Typography variant="h4" align="center">{props.users.CompanyName}</Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell><b>User Email</b></TableCell>
                        <TableCell align="left"><b>User Type</b></TableCell>
                        <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.users.Users.map((user) => (
                            <TableRow
                                key={user.DisplayName}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {user.DisplayName}
                                </TableCell>
                                <TableCell align="left">{userTypeStr[user.Type]}</TableCell>
                                <TableCell align="right">
                                    {(user.Type != USER_TYPES.COMPANY_ADMIN && props.userInfo && user.ID != props.userInfo.ID &&
                                        (props.userInfo.Type == USER_TYPES.DASHBOARD_ADMIN || props.userInfo.Type == USER_TYPES.COMPANY_ADMIN)) &&
                                        <Button variant="outlined" color="error" onClick={() => props.handleRemoveClick(user.ID)}>Remove</Button>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
        </TableContainer>
    )
}