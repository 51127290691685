import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlarmIcon from "@mui/icons-material/Alarm";
import ApiIcon from "@mui/icons-material/Api";
import AssignmentIcon from '@mui/icons-material/Assignment';
import BarChartIcon from "@mui/icons-material/BarChart";
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmailIcon from '@mui/icons-material/Email';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from "@mui/icons-material/Home";
import LockIcon from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MemoryIcon from '@mui/icons-material/Memory';
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import WifiIcon from '@mui/icons-material/Wifi';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/Auth/AuthContext";
import GSIcon from '../../components/images/GraceSense_FC_glow.png';
import { SessionContext } from '../../SessionContext';


const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

export default function ResponsiveNavbar() {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [mobileSubMenuOpen, setMobileSubMenuOpen] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const isMobile = useMediaQuery("(max-width:600px)");
    const { sessionData } = useContext(SessionContext);
    const { currentUser, logout, isAuthenticated } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());

    const menuItems = isLoggedIn ? [
        {
            text: "Home", icon: <HomeIcon />,
            subMenu: [
                {
                    text: "Hardware Dashboard", icon: <MemoryIcon />,
                    onClick: () => navigate('/')
                },
                {
                    text: "Vibration Analysis", icon: <ShowChartIcon />,
                    onClick: () => navigate(`/Vibration/AssetDashboard/${currentUser.currentCompanyID}/${currentUser.userID}/${currentUser.viewAll}`)
                },
                {
                    text: "Defect Dashboard (BETA)", icon: <BuildIcon />,
                    onClick: () => navigate(`/Defect/DefectDashboard/${currentUser.userID}/${currentUser.currentCompanyID}`)
                },
                {
                    text: "Alarm Dashboard  (BETA)", icon: <DashboardIcon />,
                    onClick: () => navigate(`/Alarm/AlarmDashboard/${currentUser.currentCompanyID}/${currentUser.userID}/${currentUser.viewAll}`)
                },                
            ],
        },
        {
            text: "Reports", icon: <BarChartIcon />,
            subMenu: [
                {
                    text: "Time-History Data", icon: <TimelineIcon />,
                    onClick: () => navigate(`/Reports/time-history-data`)
                },
                {
                    text: "Battery Report", icon: <BatteryFullIcon />,
                    onClick: () => navigate(`/Reports/battery`)
                },
                {
                    text: "Connectivity Report", icon: <WifiIcon />,
                    onClick: () => navigate(`/Reports/connectivity`)
                },
                {
                    text: "Error Report", icon: <ErrorOutlineIcon />,
                    onClick: () => navigate(`/Reports/error`)
                },
                {
                    text: "Company Report", icon: <BusinessIcon />,
                    onClick: () => navigate(`/Reports/company`)
                },
            ],
        },
        {
            text: "Alarms", icon: <AlarmIcon />,
            subMenu: [
                {
                    text: "Assign Alarms", icon: <AssignmentIcon />,
                    onClick: () => navigate(`/Alarm/assignment`)
                },
                {
                    text: "Alarm Templates", icon: <AlarmIcon />,
                    onClick: () => navigate(`/Alarm/templates`)
                },
                {
                    text: "Notification Templates", icon: <EmailIcon />,
                    onClick: () => navigate(`/Alarms/notifications`)
                },
                {
                    text: "Recipient Groups", icon: <GroupsIcon />,
                    onClick: () => navigate(`/Alarms/recipient-groups`)
                },
            ],
        },
        {
            text: "Integrations", icon: <ApiIcon />,
            subMenu: [
                // TODO: generate Integration Menu Items via Company
                { text: "Connect to *Integration", icon: <SettingsInputAntennaIcon /> },
                {
                    text: "Connect to Maintenance Hub API", icon: <CloudDownloadIcon />,
                    onClick: () => navigate(`/PublicAPI/${currentUser.currentCompanyID}/${currentUser.userID}`)
                },
            ],
        },
        {
            text: "Settings",
            icon: <SettingsIcon />,
            subMenu: [
                {
                    text: "Node Settings", icon: <SettingsInputComponentIcon />,
                    onClick: () => navigate(`/Settings/nodes`)
                },
                {
                    text: "Control Gate Settings", icon: <LockIcon />,
                    onClick: () => navigate(`/Settings/control-gates`)
                },
                {
                    text: "Firmware Settings", icon: <CloudDownloadIcon />,
                    onClick: () => navigate(`/Settings/firmware`)
                },
            ],
        },
    ] : [
        { text: "Register", icon: <PersonAddIcon />, onClick: () => { navigate('/register'); } },
        { text: "Forgot My Password", icon: <HelpOutlineIcon />, onClick: () => { navigate('/forgot-my-password'); } },
        { text: "Login", icon: <LoginIcon />, onClick: () => { navigate('/login'); } },
    ];

    const farRightMenuItems = [
        { text: "", icon: <NotificationsIcon /> },
        {
            text: "", icon: <HelpIcon />,
            subMenu: [
                { text: "Contact Us", icon: <MailOutlineIcon />, onClick: () => { window.open('https://share.hsforms.com/1zfrZ_GTiT5izSsVqloqEoga8r5', '_blank'); } },
            ],
        },
        {
            text: sessionData.firstName,
            icon: <AccountCircleIcon />,
            subMenu: [
                {
                    text: "Manage Profile", icon: <AccountCircleIcon />,
                    onClick: () => navigate(`/Settings/profile`)
                 },
                {
                    text: `Manage ${sessionData?.cc?.CompanyName}`, icon: <BusinessIcon />,
                    onClick: () => navigate(`/Settings/company`)
                 },
                {
                    text: "Set Unit Types", icon: <SettingsIcon />,
                    onClick: () => navigate(`/Settings/unit-types`)
                },
                { text: "Log Out", icon: <LogoutIcon />, onClick: () => { logout(); window.location.href = '/'; } },
            ],
        },
    ];

    const handleMenuClick = (event, index, item) => {
        if (item.subMenu) {
            setOpenSubMenu(openSubMenu === index ? null : index);
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
            setOpenSubMenu(null);
            if (item.onClick) {
                item.onClick();
            }
        }
    };

    const handleMenuClose = () => {
        setOpenSubMenu(null);
        setAnchorEl(null);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerOpen(open);
    };

    const toggleMobileSubMenu = (index) => {
        setMobileSubMenuOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle the specific submenu's open state
        }));
    };

    const renderMenuItems = (items, originalIndex) => {
        return items.map((item, index) => (
            <Box
                key={`${item.text}-${(index + originalIndex)}`}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    gap: 1,
                }}
                onClick={(e) => handleMenuClick(e, (index + originalIndex), item)}
            >
                <IconButton color="inherit">{item.icon}</IconButton>
                <Typography variant="body1">{item.text}</Typography>

                {item.subMenu && openSubMenu === (index + originalIndex) && (
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(openSubMenu === (index + originalIndex))}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        {item.subMenu.map((subItem, subIndex) => (
                            <MenuItem key={`${subItem.text}-${(index + originalIndex) + subIndex}`} onClick={() => { handleMenuClose(); if (subItem.onClick) subItem.onClick(); }}>
                                <ListItemIcon>{subItem.icon}</ListItemIcon>
                                <ListItemText primary={subItem.text} />
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </Box>
        ))
    };

    const renderMobileMenuItems = (items, originalIndex) => {
        return items.map((item, index) => (
            <React.Fragment key={`${item.text}-${index + originalIndex}`}>
                <ListItem
                    button
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent unintended drawer closure
                        if (item.subMenu) {
                            toggleMobileSubMenu(index + originalIndex);
                        } else {
                            if (item.onClick) {
                                item.onClick();
                            }
                            setDrawerOpen(false); // Close drawer if no submenu
                        }
                    }}
                >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                </ListItem>
                {item.subMenu && mobileSubMenuOpen[index + originalIndex] && (
                    <List component="div" disablePadding sx={{ pl: 4 }}>
                        {item.subMenu.map((subItem, subIndex) => (
                            <ListItem
                                button
                                key={`${subItem.text}-${index + originalIndex}-${subIndex}`}
                                onClick={() => {
                                    if (subItem.onClick) {
                                        subItem.onClick();
                                    }
                                    setDrawerOpen(false); // Close drawer after submenu action
                                }}
                            >
                                <ListItemIcon>{subItem.icon}</ListItemIcon>
                                <ListItemText primary={subItem.text} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </React.Fragment>
        ));
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <AppBar position="sticky">
                <Toolbar sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 30,
                                mr: 0.5,
                            }}
                            alt="GraceSense Icon."
                            src={GSIcon}
                            to={"/"}
                        />
                    </Typography>

                    {isMobile ? (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={toggleDrawer(true)}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <>
                            {/* Centered Menu Items */}
                            <Box sx={{ display: "flex", gap: 2, flexGrow: 1, justifyContent: "center" }}>
                                {renderMenuItems(menuItems, 0)}
                            </Box>
                        </>
                    )}

                    {/* My Account on the Far Right */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        {isLoggedIn && (<Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ "aria-label": "search" }}
                            />
                        </Search>)}
                        {isLoggedIn && renderMenuItems(farRightMenuItems, menuItems.length)}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Mobile Drawer */}
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{
                        //width: 250,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        {renderMobileMenuItems(menuItems, 0)}
                    </List>
                    <List>
                        {isLoggedIn && renderMobileMenuItems(farRightMenuItems, menuItems.length)}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}
