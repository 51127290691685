import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const NotificationPanel = ({ notifications }) => {
    const initialNotificationId = notifications && notifications.length > 0 ? notifications[0].NotificationID : null;
    const [selectedNotification, setSelectedNotification] = useState(initialNotificationId);

    const successfulNotifications = notifications.filter(notification => notification.WasSendSuccessful);

    // Styles for the scrollable container
    const scrollableContainerStyles = {
        maxHeight: '40vh',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
            width: '10px',
        },
        '::-webkit-scrollbar-track': {
            background: 'rgba(0, 0, 0, 0.05)',
        },
        '::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '20px',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.15)',
            },
        },
        scrollbarColor: 'rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05)',
        scrollbarWidth: 'thin',
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Box sx={{ ...scrollableContainerStyles, borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>                    
                    <List component="nav">
                        <Typography>Notifications Sent</Typography>
                        {successfulNotifications.map((notification, _index) => (
                            <ListItem
                                key={notification.AlarmActivityID + (notification.WasEmailSent ? "1" : "0")}
                                button
                                onClick={() => setSelectedNotification(notification)}
                            >
                                <ListItemIcon>
                                    {notification.WasEmailSent && <EmailIcon />}
                                    {notification.WasSMSSent && <SmsIcon />}
                                </ListItemIcon>
                                <ListItemText primary={notification.NotificationDisplayName} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box sx={scrollableContainerStyles}>
                    <List>
                        <Typography>Notification Recipients</Typography>
                        {selectedNotification &&
                            [...new Set(
                                notifications
                                    .filter(notification => notification.NotificationID === selectedNotification.NotificationID)
                                    .map(notification => notification.UserFullName)
                            )]
                                .map((userFullName, index) => (
                                    <ListItem key={`recipient-${index}`}>
                                        <ListItemText primary={userFullName} />
                                    </ListItem>
                                ))
                        }
                    </List>
                </Box>
            </Grid>
        </Grid>
    );
};
export default  NotificationPanel;