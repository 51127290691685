import React, { useState } from 'react';
import { Opacity } from '../../helpers/MiscFunctions';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import { useTheme } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';


export default function BatteryStatus(props) {
    const theme = useTheme();

    let opacity = (props.isConnected ? 1 : 0.6);

    const [toolTipText] = useState(props.value.toFixed(3) + " " + props.unitType);

    if (props.value >= 5) {
        return (
            <Tooltip title={toolTipText}>
                <BatteryFullIcon fontSize="large" sx={{ color: Opacity(theme.palette.success.main, opacity) }} />
            </Tooltip>
        );
    } else if (props.value >= 4.375 && props.value < 5) {
        return (
            <Tooltip title={toolTipText}>
                <BatteryFullIcon fontSize="large" sx={{ color: Opacity(theme.palette.success.main, opacity) }} />
            </Tooltip>
        );
    } else if (props.value >= 3.75 && props.value < 4.375) {
        return (
            <Tooltip title={toolTipText}>
                <Battery6BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.success.main, opacity) }} />
            </Tooltip>
        );
    } else if (props.value >= 3.5 && props.value < 3.75) {     
        return (
            <Tooltip title={toolTipText}>
                <Battery5BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.success.main, opacity) }} />
            </Tooltip>
        );
    } else if (props.value >= 3.425 && props.value < 3.5) {
        return (
            <Tooltip title={toolTipText}>
                <Battery3BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.warning.main, opacity) }} />
            </Tooltip >
        );
    } else if (props.value >= 3.35 && props.value < 3.425) {
        return (
            <Tooltip title={toolTipText}>
                <Battery2BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.warning.main, opacity) }} />
            </Tooltip >
        );
    } else if (props.value >= 0 && props.value < 3.35) {
        return (
            <Tooltip title={toolTipText}>
                <Battery1BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.error.main, opacity) }} />
            </Tooltip >
        );
    } else {
        return (
            <Tooltip title={toolTipText}>
                <Battery0BarIcon fontSize="large" sx={{ color: Opacity(theme.palette.grey.main, opacity) }} />
            </Tooltip >
        );
    }
}