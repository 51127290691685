
export const createHierarchy = (items, newSelections, notSyncedFolderName, rootFolderName) => {
    let hierarchy = {};
    let ignoredItems = items.filter(i => i.IsIgnored === true);
    let allOthers = items.filter(i => i.IsIgnored === false);

    allOthers.forEach(item => {
        const pathParts = (item.HierarchyPath || '').split('/');
        pathParts.pop();
        const parentPath = pathParts.slice(1).join(' > ');

        if (!hierarchy[parentPath]) {
            hierarchy[parentPath] = [];
        }

        if (item.VibrationObjectID != null) {
            newSelections.push({ id: item.ID, action: item.VibrationObjectID });
        }

        if (item.IsIgnored) {
            newSelections.push({ id: item.ID, action: 0 });
        }

        hierarchy[parentPath].push(item);
    });

    ignoredItems.forEach(item => {
        const pathParts = (item.HierarchyPath || '').split('/');
        pathParts.pop();
        const parentPath = notSyncedFolderName;

        if (!hierarchy[parentPath]) {
            hierarchy[parentPath] = [];
        }

        if (item.VibrationObjectID != null) {
            newSelections.push({ id: item.ID, action: item.VibrationObjectID });
        }

        if (item.IsIgnored) {
            newSelections.push({ id: item.ID, action: 0 });
        }

        hierarchy[parentPath].push(item);
    });

    const sortedHierarchy = Object.entries(hierarchy).map(([parentPath, children]) => ({
        ParentPath: parentPath || rootFolderName,
        VendorParentPath: parentPath,
        Children: children,
        ChildrenDescriptions: children.map(child => `${child.Name} (${child.Code})`).join(', ')
    }));

    sortedHierarchy.sort((a, b) => {
        if (a.ParentPath === rootFolderName) return -1;
        if (b.ParentPath === rootFolderName) return 1;
        if (a.ParentPath === notSyncedFolderName) return -1;
        if (b.ParentPath === notSyncedFolderName) return 1;
        return a.ParentPath.localeCompare(b.ParentPath);
    });

    return sortedHierarchy;
}

export const updateAsset = (prevData) => {
    const newData = [...prevData];

    // Ensure the item is not already in the original parent path
    const originalParentPath = (updatedAsset.HierarchyPath || '').split('/').slice(1, -1).join(' > ');
    const parentFolder = newData.find(folder => folder.ParentPath === originalParentPath);

    if (parentFolder) {
        const existingChildIndex = parentFolder.Children.findIndex(child => child.ID === updatedAsset.ID);
        if (existingChildIndex === -1) {
            // Add the item to its parent path if it doesn't already exist
            parentFolder.Children.push(updatedAsset);
        } else {
            // Update the existing item
            parentFolder.Children[existingChildIndex] = updatedAsset;
        }
    } else {
        // If the parent path doesn't exist, create it and add the item
        newData.push({
            ParentPath: originalParentPath,
            Children: [updatedAsset]
        });
    }

    // Remove the item from the "Not Synced" folder
    const notSyncedFolderIndex = newData.findIndex(folder => folder.ParentPath === notSyncedFolderName);
    if (notSyncedFolderIndex !== -1) {
        const itemIndexInNotSynced = newData[notSyncedFolderIndex].Children.findIndex(child => child.ID === asset.ID);
        if (itemIndexInNotSynced !== -1) {
            newData[notSyncedFolderIndex].Children.splice(itemIndexInNotSynced, 1);

            // If "Not Synced" folder is empty, remove the folder
            if (newData[notSyncedFolderIndex].Children.length === 0) {
                newData.splice(notSyncedFolderIndex, 1);
            }
        }
    }
    return newData;
}