import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Divider } from '@mui/material';
import theme from "../../theme";

export default function DefectPeriodicTableHeader({ elementName }) {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            position: 'relative',
            height: '50px',
        }}>
            {/* Left Vertical Bar */}
            <Box sx={{
                width: '4px',
                height: '100%',
                backgroundColor: theme.palette.grey.main,
                position: 'absolute',
                left: '5%',
                top: 5
            }} />

            {/* Right Vertical Bar */}
            <Box sx={{
                width: '4px',
                height: '100%',
                backgroundColor: theme.palette.grey.main,
                position: 'absolute',
                right: '5%',
                top: 5
            }} />

            {/* Top Horizontal Bar */}
            <Divider sx={{
                position: 'absolute',
                top: 5,
                left: '5%',
                right: '5%',
                backgroundColor: theme.palette.grey.main,
                opacity: 1,
                height: '4px',
            }} />

            {/* Text on top of the horizontal bar */}
            <Typography sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                padding: '0 8px',
                fontWeight: 'bold',
            }}>
                {elementName}
            </Typography>
        </Box>
    )
}