import { VBTxConfigurationFormModel } from './VBTxConfigurationFormModel';

const {
    formField: {
        assetName,
        machineType,
        driveType,
        p1AssignedNode,
        p2AssignedNode,
        p3AssignedNode,
        p4AssignedNode,
        gearboxLocation,
        bladeCount,
        inputToothCount,
        outputToothCount,
        shaftSpeed,
        shaftSpeedHiBound,
        shaftSpeedLoBound,
        vibrationSamplingPeriod,
        temperatureSamplingPeriod,
        tempEnabled,
        burstEnabled,
        triggerSource,
        accelerationThreshold,
        velocityThreshold,
        minTriggers,
        maxTriggers,
        defectLevel,
        maximizeNodes,
        sameGateway,
        parentGateway,
        warningRecipientIDs,
        dangerRecipientIDs
    }
} = VBTxConfigurationFormModel;

export const VBTxConfigurationInitialValues = {
    [assetName.name]: '',
    [machineType.name]: null,
    [driveType.name]: 2,
    [p1AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 2,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "parentGateway": 0
    },
    [p2AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 2,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],
        "parentGateway": 0
    },
    [p3AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 2,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],
        "parentGateway": 0
    },
    [p4AssignedNode.name]: {
        "nodeID": 0,
        "axisAlignment": 0,
        "shaftSpeedSource": 2,
        "xEnabled": true,
        "yEnabled": true,
        "zEnabled": true,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs": [],
        "dangerRecipientIDs": [],
        "parentGateway": 0
    },
    [gearboxLocation.name]: 0,
    [bladeCount.name]: 0,
    [inputToothCount.name]: 0,
    [outputToothCount.name]: 0,
    [shaftSpeed.name]: 1800,
    [shaftSpeedLoBound.name]: 1680,
    [shaftSpeedHiBound.name]: 3720,
    [vibrationSamplingPeriod.name]: 3600,
    [temperatureSamplingPeriod.name]: 3600,
    [tempEnabled.name]: false,
    [burstEnabled.name]: false,
    [triggerSource.name]: 1,
    [accelerationThreshold.name]: '',
    [velocityThreshold.name]: '',
    [minTriggers.name]: '',
    [maxTriggers.name]: '',
    [defectLevel.name]: 1,
    [maximizeNodes.name]: false,
    [sameGateway.name]: true,
    [parentGateway.name]: 0,
    [warningRecipientIDs.name]: [],
    [dangerRecipientIDs.name]: [],
};