import React, { useRef, useState, useContext } from 'react';
import { Box, Grid, Typography, IconButton, Button } from "@mui/material"
import {
    fetchControlGateTree, removeParent, setNewParent, downloadL5X, downloadAOP, downloadMemoryMap,
    fetchDocumentList, downloadDocument, fetchDashboardList
} from '../../repositories/ControlQueryFunctions'
import { Toast } from 'primereact/toast';
import StatusMessage from "../../components/Generic/StatusMessage";
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import { useQuery, useMutation } from "react-query";
import FolderCard from "../../components/Generic/FolderCard";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ControlGateMenu from '../../components/Control/ControlGateMenu';
import AssignModal from '../../components/Control/AssignModal';
import DocumentModal from '../../components/Control/DocumentModal'
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { createToastSuccess, createToastError } from '../../helpers/MiscFunctions';
import { SessionContext } from '../../SessionContext';

// Page Styling
const pageStyle = {
    margin: "2%"
}

export default function ControlGateConfiguration() {

    const { sessionData } = useContext(SessionContext);
    
    const toast = useRef(null);

    //Members used to control the states of components
    const [errorState, setErrorState] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedControlGate, setSelectedControlGate] = useState();
    const [selectedRowId, setSelectedRowId] = useState(null);

    //Data pulled from the API
    const [dashboardID, setDashboardID] = useState();
    const [cgRootNode, setCGRootNode] = useState();
    const [dashRootNode, setDashRootNode] = useState();
    const [dashNodes, setDashNodes] = useState([]);
    const [cgNodes, setCGNodes] = useState([]);
    const [docList, setDocList] = useState([]);

    //Members used to determine if API requests are loading
    const [isLoadedCGTree, setIsLoadedCGTree] = useState(false);
    const [isLoadedDashboardTree, setIsLoadedDashboardTree] = useState(false);
    const [isLoadedDocumentList, setIsLoadedDocumentList] = useState(false);
    const [documentDownloading, setDocumentDownloading] = useState(false);

    //Controls whether certain modals or menus are open
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [documentModalOpen, setDocumentModalOpen] = useState(false);
    const controlGateMenuOpen = Boolean(anchorEl);

    /*
        Gets dashboards and trees using API calls
    */
    const { isLoading: dashListLoading } =
        useQuery(["dashlist", sessionData.currentCompanyID], fetchDashboardList, {
            onSuccess: (dataT) => {
                setDashboardID(dataT[0].Item1);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            }
        });

    const { isLoading: cgTreeLoading} =
        useQuery({
            queryKey: ["cgtree", dashboardID], queryFn: fetchControlGateTree,
            onSuccess: (dataC) => {
                console.log(dataC);
                setCGRootNode(dataC.Root);
                setCGNodes(dataC.AssignedNodes);
                setDashRootNode(dataC.Dashboard.root);
                setDashNodes(dataC.Dashboard.nodes);
                setIsLoadedDashboardTree(true);
                setIsLoadedCGTree(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            },
            enabled: !!dashboardID
        });

    const { isLoading: documentListLoading } =
        useQuery(["doclist"], fetchDocumentList, {
            onSuccess: (dataO) => {
                setDocList(dataO);
                setIsLoadedDocumentList(true);
            },
            onError: (dashboardsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(dashboardsError || "An unexpected error occurred.");
            }
        });

    
    /*
        Performs post requests to assign/unassign nodes and download documents
    */
    const doRemoveParent =
        useMutation((childObjectID) => removeParent(childObjectID, sessionData.currentCompanyID), {
            onSuccess: (_result) => {
                let toastMessage = createToastSuccess('Node unassigned');
                toast.current.show(toastMessage);
                setTimeout(() => {
                    window.location.reload();
                }, 3000); // Match the life of the toast 
            },
            onError: (_removeError) => {
                let toastMessage = createToastError('An error occurred while attempting to unassign node');
                toast.current.show(toastMessage);
            }
        });

    const doSetNewParent =
        useMutation(({ childObjectID, parentObjectID }) => setNewParent(childObjectID, parentObjectID, sessionData.currentCompanyID), {
            onSuccess: (_result) => {
                setAssignModalOpen(false);
                setSelectedRowId(null);
                let toastMessage = createToastSuccess('Node assigned');
                toast.current.show(toastMessage);
                setTimeout(() => {
                    window.location.reload();
                }, 3000); // Match the life of the toast 
            },
            onError: (_removeError) => {
                let toastMessage = createToastError('An error occurred while attempting to assign node');
                toast.current.show(toastMessage);
            }
        });

    const doDownloadL5X =
        useMutation(({ dashboardObjectID, displayName }) => downloadL5X(dashboardObjectID, displayName, sessionData.currentCompanyID), {
            onSuccess: (_result) => {
                let toastMessage = createToastSuccess('File created');
                toast.current.show(toastMessage);
            },
            onError: (_removeError) => {
                let toastMessage = createToastError('An error occurred while attempting to create file');
                toast.current.show(toastMessage);
            }
        });

    const doDownloadAOP =
        useMutation(({ dashboardObjectID, displayName }) => downloadAOP(dashboardObjectID, displayName, sessionData.currentCompanyID), {
            onSuccess: (_result) => {
                let toastMessage = createToastSuccess('File created');
                toast.current.show(toastMessage);
            },
            onError: (_removeError) => {
                let toastMessage = createToastError('An error occurred while attempting to create file');
                toast.current.show(toastMessage);
            }
        });

    const doDownloadMemoryMap =
        useMutation(({ dashboardObjectID, displayName }) => downloadMemoryMap(dashboardObjectID, displayName, sessionData.currentCompanyID), {
            onSuccess: (_result) => {
                let toastMessage = createToastSuccess('File created');
                toast.current.show(toastMessage);
            },
            onError: (_removeError) => {
                let toastMessage = createToastError('An error occurred while attempting to create file');
                toast.current.show(toastMessage);
            }
        });

    const doDownloadDocument =
        useMutation((fileName) => downloadDocument(fileName), {
            onSuccess: (_result) => {
                setDocumentModalOpen(false);
                setDocumentDownloading(false);
                let toastMessage = createToastSuccess('File downloaded');
                toast.current.show(toastMessage);
            },
            onError: (_removeError) => {
                let toastMessage = createToastError('An error occurred while attempting to downloaded file');
                toast.current.show(toastMessage);
            }
        });

    /*
        Handles the opening and closing of menus and modals
    */
    const handleControlGateMenuClose = () => {
        setSelectedRowId(null);
        setAnchorEl(null);
    }

    const handleControlGateMenuClick = (event, id) => {
        setSelectedRowId(id);
        setAnchorEl(event.currentTarget);
    }

    const handleAssignModalOpen = (id) => {
        setSelectedRowId(id);
        setAssignModalOpen(true);
    }

    const handleAssignModalClose = () => {
        setAssignModalOpen(false);
        setSelectedRowId(null);
    }

    const handleSelectedControlGateChange = (event) => {
        setSelectedControlGate(event.target.value);
    }

    const handleDocumentModalOpen = () => {
        setDocumentModalOpen(true);
    }

    const handleDocumentModalClose = () => {
        setDocumentModalOpen(false);
    }

    /*
        Handle downloading of documents
    */
    const handleDownloadL5X = (dashboardObjectID) => {
        let displayName = cgNodes.find((node) => node.ObjectID == dashboardObjectID).DisplayName
        if ((typeof dashboardObjectID) === "string") {
            dashboardObjectID = Number(dashboardObjectID.split("_")[1])
        }
        doDownloadL5X.mutateAsync({ dashboardObjectID: dashboardObjectID, displayName: displayName });
    }

    const handleDownloadAOP = (dashboardObjectID) => {
        let displayName = cgNodes.find((node) => node.ObjectID == dashboardObjectID).DisplayName
        if ((typeof dashboardObjectID) === "string") {
            dashboardObjectID = Number(dashboardObjectID.split("_")[1])
        }
        doDownloadAOP.mutateAsync({ dashboardObjectID: dashboardObjectID, displayName: displayName });
    }

    const handleDownloadMemoryMap = (dashboardObjectID) => {
        let displayName = cgNodes.find((node) => node.ObjectID == dashboardObjectID).DisplayName
        if ((typeof dashboardObjectID) === "string") {
            dashboardObjectID = Number(dashboardObjectID.split("_")[1])
        }
        doDownloadMemoryMap.mutateAsync({ dashboardObjectID: dashboardObjectID, displayName: displayName });
    }

    const handleDocumentDownload = (fileName) => {
        setDocumentDownloading(true);
        setDocumentModalOpen(false);
        doDownloadDocument.mutateAsync(fileName);
    }

    /*
        Handles assigning and unassigning of nodes
    */
    const handleUnassignClick = (id) => {
        if ((typeof id) === "string") {
            id = Number(id.split("_")[1])
        }
        doRemoveParent.mutateAsync(id);
    }

    const handleAssignClick = (childID, parentID) => {
        if ((typeof childID) === "string") {
            childID = Number(childID.split("_")[1])
        }
        if ((typeof parentID) === "string") {
            parentID = Number(parentID.split("_")[1])
        }
        doSetNewParent.mutateAsync({ childObjectID: childID, parentObjectID: parentID });
    }


    /*
        Renders the buttons on the right side of the rows for both tables
    */
    const renderChildAction = (node) => {
        return (<Button
            style={{ float: "right" }}
            onClick={() => handleUnassignClick(node.ObjectID)}
            endIcon={<ClearIcon />}
        >
            Unassign
        </Button>)
    }

    const renderAssignedParentAction = (node) => {
        return (
            <Box>
                <IconButton onClick={(event) => handleControlGateMenuClick(event, node.ObjectID)}>
                    <MoreHorizIcon />
                </IconButton>
                {selectedRowId === node.ObjectID && (<ControlGateMenu
                    id={node.ObjectID}
                    objectID={node.ObjectID}
                    controlGateMenuOpen={controlGateMenuOpen}
                    handleControlGateMenuClose={handleControlGateMenuClose}
                    anchorEl={anchorEl}
                    handleDownloadL5X={handleDownloadL5X}
                    handleDownloadAOP={handleDownloadAOP}
                    handleDownloadMemoryMap={handleDownloadMemoryMap}
                />)}
            </Box>)
    }

    const renderUnassignedParentAction = (node) => {
        return (
            <Box>
                <Button
                    onClick={() => handleAssignModalOpen(node.ObjectID)}
                    endIcon={<AddIcon />}
                >
                    Assign
                </Button>
                {selectedRowId === node.ObjectID && (<AssignModal
                    id={node.ObjectID}
                    displayName={node.DisplayName}
                    controlgates={cgNodes}
                    selectedControlGate={selectedControlGate}
                    handleSelectedControlGateChange={handleSelectedControlGateChange}
                    assignModalOpen={assignModalOpen}
                    handleAssignModalClose={handleAssignModalClose}
                    handleAssignClick={handleAssignClick}
                />)}
            </Box>
            )
    }
    return (
        <Box sx={pageStyle}>
            {(cgTreeLoading || documentListLoading || dashListLoading || documentDownloading) &&
                <StatusBackdrop open={cgTreeLoading || documentListLoading || dashListLoading || documentDownloading} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="warning"
                    location="Company"
                    statusCode={errorState?.request?.status}
                    message={errorState?.message}
                    error={errorState}
                />
            }
            <Toast ref={toast} />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">ControlGate Configuration</Typography>
                </Grid>
                {isLoadedCGTree && <Grid container item xs={12}>
                    <Grid item xs={10}>
                        <Typography variant="h4" align="left">Assigned Nodes</Typography>
                    </Grid>
                    <br /><br />
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={handleDocumentModalOpen}>Documents</Button>
                        {isLoadedDocumentList && (< DocumentModal
                            docList={docList}
                            documentModalOpen={documentModalOpen}
                            handleDocumentDownload={handleDocumentDownload}
                            handleDocumentModalClose={handleDocumentModalClose}
                        />)}
                    </Grid>

                    <Grid item xs={12}>
                        <FolderCard
                            node={cgRootNode}
                            list={cgNodes}
                            renderChildAction={renderChildAction}
                            showChildren={true}
                            renderParentAction={renderAssignedParentAction}
                            showFilter={false}
                        />
                    </Grid>
                </Grid>}
                {isLoadedDashboardTree && <Grid item xs={12}>
                    <br /><br />
                    <Typography variant="h4" align="left">Unassigned Nodes</Typography>
                    <br />
                    <FolderCard
                        node={dashRootNode}
                        list={dashNodes}
                        renderChildAction={renderChildAction}
                        showChildren={false}
                        renderParentAction={renderUnassignedParentAction}
                        showFilter={true}
                    />
                </Grid>}
            </Grid>
        </Box>
    )
}