import React from 'react'
import { Tooltip, Typography } from '@mui/material';
export default function ColoredNormalText({ color, children, tooltip }) {
    return (
        <Tooltip title={tooltip || children} placement={"top"} arrow>
            <Typography
                component="div"
                style={{ color: color }}
                sx={{ mb: 2 }}
            >
                {children}
            </Typography>
        </Tooltip>
    );
};