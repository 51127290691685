import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Modal } from "@mui/material";
import { styled } from '@mui/material/styles';
import AssetImage from './AssetImage';
import AssetNodeSelect from './AssetNodeSelect';
import AssetNameFormItem from './AssetNameFormItem';
import AssetRpmFormItem from './AssetRpmFormItem';
import AssetDropdownFormItem from './AssetDropdownFormItem';
import { VibrationAnalysisType } from "./VibrationAnalysisType";
import VibrationBandConfigure from './VibrationBandConfigure';
import AssetAlignmentModalContent from './AssetAlignmentModalContent';
import AssetAlarmingModalContent from './AssetAlarmingModalContent';
import { Item } from "../../components/Items/BoxItem";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function AssetForm(props) {

    const [alarmModalOpen, setAlarmModalOpen] = useState(false);
    const handleAlarmModalOpen = () => setAlarmModalOpen(true);
    const handleAlarmModalClose = () => setAlarmModalOpen(false);
    const [alignModalOpen, setAlignModalOpen] = useState(false);
    const handleAlignModalOpen = () => setAlignModalOpen(true);
    const handleAlignModalClose = () => setAlignModalOpen(false);

    const handleModalOpen = (e, posIdx) => {
        switch (e.target.outerText) {
            case "Alignment":
                handleAlignModalOpen()
                props.handleModalOpen(posIdx)
                break;

            case "Alarming":
                handleAlarmModalOpen()
                props.handleModalOpen(posIdx)
                break;
        }
    }

    const closeModals = () => {
        handleAlignModalClose();
        handleAlarmModalClose();
    }

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }

    // Determine whether GMF is allowed
    const getGearBladeItem = () => {
        switch (props.analysisType) {
            case VibrationAnalysisType.AdvancedVBT:
            case VibrationAnalysisType.IntermediateVBT:
                return (
                    <Item>
                        <TextField
                            fullWidth
                            inputProps={{ type: 'number', step: 1, min: 0 }}
                            onChange={props.handleBladeCountChange}
                            value={props.bladeCount}
                            label="Gear/Blade Count"
                        />
                    </Item>
                    );
                break;
        }
    }

    // Determine whether Sampling Period is adjustable
    const isSamplingPeriodDisabled = () => {
        switch (props.analysisType) {
            case VibrationAnalysisType.BasicVBTTemp:
            case VibrationAnalysisType.BasicVBTTempVFD:
                return true;
                break;

            default:
                return false;
                break;
        }
    }

    return (
        <Box sx={pageStyle}>
            <Grid container spacing={1} columns={6}>
                <Grid container item spacing={3}>
                    {/* Asset Name Grid Item */}
                    <Grid item xs={4}>
                        <Item><AssetNameFormItem value={props.assetName} onChange={props.handleNameChange} /></Item>
                    </Grid>
                    {/* Save Button Grid Item */}
                    <Grid item xs={1}>
                        <Item><Button fullWidth onClick={props.handleButton1Click} variant="contained">{props.button1Name}</Button></Item>
                    </Grid>
                    {/* Cancel Button Grid Item */}
                    <Grid item xs={1}>
                        <Item><Button fullWidth onClick={props.handleButton2Click} variant="contained">{props.button2Name}</Button></Item>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} columns={6}>
                <Grid container item spacing={3}>

                    {/* Machine Type Grid Item */}
                    <Grid item xs={3}>
                        <Item><AssetDropdownFormItem
                            id="machine-type"
                            value={props.machineType}
                            label="Machine Type"
                            onChange={props.handleMachineTypeChange}
                            selectItems={props.enums.MachineTypes}
                        /></Item>
                    </Grid>

                    {/* Analysis Type Grid Item */}
                    <Grid item xs={2}>
                        <Item><AssetDropdownFormItem
                            id="analysis-type"
                            value={props.analysisType}
                            label="Analysis Type"
                            onChange={props.handleAnalysisTypeChange}
                            selectItems={props.enums.AnalysisTypes}
                        /></Item>
                    </Grid>

                    {/* Band Details Button Grid Item */}
                    <Grid item xs={1}>
                        <Item><VibrationBandConfigure
                            analysisType={props.analysisType}
                            enableFlags={props.enableFlags}
                            enableChange={props.analysisType == VibrationAnalysisType.AdvancedVBT ? true : false}
                        /></Item>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} columns={6}>
                <Grid container item spacing={3}>

                    {/* RPM Settings Grid Item */}
                    <AssetRpmFormItem
                        handleRpmChange={props.handleRpmChange}
                        handleHighRpmChange={props.handleHighRpmChange}
                        handleLowRpmChange={props.handleLowRpmChange}
                        rpmType={props.rpmType}
                        rpmTypes={props.enums.DriveTypes}
                        rpm={props.rpm}
                        lowRpm={props.lowRpm}
                        highRpm={props.highRpm}
                        handleRpmTypeChange={props.handleRpmTypeChange}
                        analysisType={props.analysisType}
                        primaryRPMErrorText={props.primaryRPMErrorText}
                        lowRPMErrorText={props.lowRPMErrorText}
                        highRPMErrorText={props.highRPMErrorText}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} columns={6}>
                <Grid container item spacing={3}>

                    {/* Analysis Type Grid Item */}
                    <Grid item xs={2}>
                        <Item><AssetDropdownFormItem
                            id="sampling-period"
                            value={props.vibrationSamplingPeriod}
                            label="Time Between Analyses"
                            onChange={props.handleSamplingPeriodChange}
                            selectItems={props.enums.SamplingPeriods}
                            disabled={isSamplingPeriodDisabled()}
                        /></Item>
                    </Grid>

                    {/* Acceleration Range Grid Item */}
                    <Grid item xs={2}>
                        <Item><AssetDropdownFormItem
                            id="accel-range"
                            value={props.accelRange}
                            label="Acceleration Range"
                            onChange={props.handleAccelRangeChange}
                            selectItems={props.enums.AccelRanges}
                        /></Item>
                    </Grid>

                    {/* Gear / Blade Pass Grid Item */}
                    <Grid item xs={2}>
                        { getGearBladeItem() }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} columns={6}>
                <Grid container item spacing={3}>
                    <Grid item xs={3} />

                    {/* Asset Image Grid Item */}
                    <Grid item xs={3}>
                        <Item><AssetImage machineType={props.machineType} /></Item>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} columns={6} style={{ height: '450px' }}>

                {/* Asset Image Grid Item */}
                <AssetNodeSelect
                    items={props.availableNodes}
                    nodes={props.nodes}
                    unassignedNodes={props.unassignedNodes}
                    p1Nodes={props.p1Nodes}
                    p2Nodes={props.p2Nodes}
                    p3Nodes={props.p3Nodes}
                    p4Nodes={props.p4Nodes}

                    handleUnassignedNodesChange={props.handleUnassignedNodesChange}
                    handleP1NodesChange={props.handleP1NodesChange}
                    handleP2NodesChange={props.handleP2NodesChange}
                    handleP3NodesChange={props.handleP3NodesChange}
                    handleP4NodesChange={props.handleP4NodesChange}

                    handleModalOpen={handleModalOpen}
                />
            </Grid>

            {/* Alarming Modal */}
            <Modal
                open={alarmModalOpen}
                onClose={handleAlarmModalClose}
                aria-labelledby="alarm-modal-title"
                aria-describedby="alarm-modal-description"
            >
                <Box sx={modalStyle}>
                    <AssetAlarmingModalContent
                        enums={props.enums}
                        users={props.users}
                        userGroups={props.userGroups}
                        positionIdx={props.activePosIdx}
                        dangerThreshold={props.dangerThresholds[props.activePosIdx]}
                        dangerRecipientIDs={props.dangerRecipientIDs[props.activePosIdx]}
                        warningThreshold={props.warningThresholds[props.activePosIdx]}
                        warningRecipientIDs={props.warningRecipientIDs[props.activePosIdx]}
                        handleAlarmingChange={props.handleAlarmingChange}
                        closeModals={closeModals}
                    />
                </Box>
            </Modal>

            {/* Alignment Modal */}
            <Modal
                open={alignModalOpen}
                onClose={handleAlignModalClose}
                aria-labelledby="align-modal-title"
                aria-describedby="align-modal-description"
            >
                <Box sx={modalStyle}>
                    <AssetAlignmentModalContent
                        enums={props.enums}
                        positionIdx={props.activePosIdx}
                        onChange={props.handleOrientationChange}
                        closeModals={closeModals}
                        value={props.orientationIDs[props.activePosIdx]}
                    />
                </Box>
            </Modal>
        </Box>
    )
}