import {
    fetchStdAlarmDetailsForView, fetchStdAlarmDetailsFromURL, fetchVIBAlarmDetailsForView, fetchVIBAlarmDetailsFromURL
} from '../repositories/AlarmQueryFunctions';
import { CalculateTimeDifference, FormatDate } from './MiscFunctions';

export const mapAlarmData = (alarmData, newAlarmCurrentData) => {
    const alarm = {
        NodeChannelID: alarmData.NodeChannelID,
        ComputedDataSourceID: alarmData.ComputedDataSourceID,
        IsConnected: alarmData.IsNodeConnected,
        AlarmID: alarmData.AlarmID,
        AlarmName: alarmData.AlarmName,
        ThresholdTriggerValue: alarmData.ThresholdTriggerValue,
        ThresholdOperatorDisplayName: alarmData.ThresholdOperatorDisplayName,
        ThresholdOperatorDisplaySymbol: alarmData.ThresholdOperatorDisplaySymbol,
        ThresholdUnitTypeQuantityType: alarmData.ThresholdUnitTypeQuantityType,
        ThresholdUnitTypeDisplayName: alarmData.ThresholdUnitTypeDisplayName,
        ThresholdUnitTypeSymbol: alarmData.ThresholdUnitTypeSymbol,
        SystemStatusID: alarmData.SystemStatusID,
        StatusSeverityLevel: alarmData.StatusSeverityLevel,
        StatusDisplayName: alarmData.StatusDisplayName,
        StatusHexColor: alarmData.StatusHexColor,
        AlarmType: alarmData.AlarmType,
        DefaultSensorQuantityType: alarmData.DefaultSensorQuantityType,
        DefaultSensorUnitTypeSymbol: alarmData.DefaultSensorUnitTypeSymbol,
        ComputedValue: alarmData.ComputedValue,
        ComputedOnUTC: alarmData.ComputedOnUTC,
        ComputedOnTimeZone: alarmData.ComputedOnTimeZone,
        DefaultSensorUnitTypeConvertedValue: alarmData.DefaultSensorUnitTypeConvertedValue,
        IsAlarmTriggered: alarmData.IsAlarmTriggered,
        AlarmDataType: alarmData.AlarmDataType,
        SecondsSinceCheckIn: alarmData.ComputedOnTimeZone ? CalculateTimeDifference(alarmData.ComputedOnTimeZone) : null,
        ObjectDefectSummary: alarmData.ObjectDefectSummary,
    }
    newAlarmCurrentData.push(alarm);
}

export const mapNodeChannel = (nodeChannel, newDashboard) => {
    const obj = {
        CompanyName: nodeChannel.CompanyName,
        NodeID: nodeChannel.NodeID,
        NodeLocation: nodeChannel.NodeLocation,
        NodeDisplayName: nodeChannel.NodeDisplayName,
        NodeChannelDisplayName: nodeChannel.NodeChannelDisplayName,
        ProductionReadableSN: nodeChannel.ProductionReadableSN,
        IsConnected: nodeChannel.IsNodeConnected,
        NodeChannelID: nodeChannel.NodeChannelID,
        ComputedDataSourceID: nodeChannel.ComputedDataSourceID,
        ComputedValue: nodeChannel.ComputedValue,
        ComputedOnUTC: FormatDate(nodeChannel.ComputedOnUTC),
        ComputedOnTimeZone: FormatDate(nodeChannel.ComputedOnTimeZone),
        SensorUnitTypeSymbol: nodeChannel.SensorUnitTypeSymbol,
        SensorUnitTypeQuantityType: nodeChannel.SensorUnitTypeQuantityType,
        ConfigUnitTypeID: nodeChannel.ConfigUnitTypeID,
        ConfigQuantityType: nodeChannel.ConfigQuantityType,
        ConfigSymbol: nodeChannel.ConfigSymbol,
        ConvertedComputedValue: nodeChannel.ConvertedComputedValue,
        ChannelSeverityLevel: null,
        AlarmNames: "",
        AlarmTypes: [],
        SensorDataType: nodeChannel.SensorDataType,
        SecondsSinceCheckIn: nodeChannel.ComputedOnTimeZone ?
            CalculateTimeDifference(nodeChannel.ComputedOnTimeZone) : null,
        VibrationAssetID: nodeChannel.VibrationAssetID,
        CheckInTimeZone: FormatDate(nodeChannel.CheckInTimeZone)


    }
    newDashboard.push(obj);
}

export const filterChannels = (channels, newAlarmCurrentData, alarmStatusInfo) => {
    if (channels && channels.length > 0) {
        for (let x = 0; x < channels.length; x++) {
            let channelAlarms = newAlarmCurrentData.filter(c => c.ComputedDataSourceID === channels[x].ComputedDataSourceID);
            if (channelAlarms && channelAlarms.length > 0) {

                let triggered = channelAlarms.filter(ca => ca.IsAlarmTriggered === true);

                if ((triggered && triggered.length > 0) && triggered.ComputedValue !== null) {
                    channels[x].ChannelSeverityLevel = Math.max.apply(Math, triggered.map((t) => { return t.StatusSeverityLevel }));
                } else if (triggered.ComputedValue !== null) {
                    channels[x].ChannelSeverityLevel = alarmStatusInfo.find(i => i.StatusDisplayName === "Normal").Severity;
                } else {
                    channels[x].ChannelSeverityLevel = alarmStatusInfo.find(i => i.StatusDisplayName === "Off").Severity;
                }

                for (let n = 0; n < channelAlarms.length; n++) {
                    channels[x].AlarmNames += channelAlarms[n].AlarmName + ", ";
                    channels[x].AlarmTypes.push(channelAlarms[n].AlarmType);
                }
            }

        }
    }
}

export const getFetchDetails = (params) => {
    // Convert string vib to boolean
    let isVib = params.vib.toLowerCase() === 'true';

    if (params.urlFragment && !isVib) {
        return { fetchFunction: fetchStdAlarmDetailsFromURL, queryKey: ["alarmEventDetails", params.urlFragment] };
    } else if (params.urlFragment && isVib) {
        return { fetchFunction: fetchVIBAlarmDetailsFromURL, queryKey: ["alarmEventDetails", params.urlFragment] };
    } else if (params.cdsid && params.alarmID && !isVib) {
        return { fetchFunction: fetchStdAlarmDetailsForView, queryKey: ["alarmEventDetails", params.cdsid, params.alarmID, params.companyID, params.userID] };
    } else if (params.cdsid && params.alarmID && isVib) {
        return { fetchFunction: fetchVIBAlarmDetailsForView, queryKey: ["alarmEventDetails", params.cdsid, params.alarmID, params.companyID, params.userID] };
    } else {
        // If none of the expected parameters are present, throw an error
        // This will be handled in the component itself
        throw new Error("Required parameters are missing.");
    }
};

export const createPostObject = (formikValues, companyID, userID) => {
    let myValues = formikValues;

    const alarmCreate = {
        CompanyID: Number(companyID),
        UserID: Number(userID),
        DisplayName: myValues.alarmName,
        SystemStatusID: Number(myValues.alarmSeverityID),
        Summary: myValues.alarmSummary,
        SendNotification: myValues.sendNotification,
        NotificationID: Number(myValues.notificationID),
        AlarmRecipientList: myValues.notificationRecipients.map(recipient => ({
            RecipientID: Number(recipient.value),
            AlarmID: null,
            IsGroup: recipient.isGroup,
        })),
        ComputedDataSourceIDList: myValues.assignedComputedDataSourceIDs,
        AlarmThresholdList: [{
            AlarmThresholdID: null,
            AlarmID: null,
            DataTypeID: Number(myValues.thresholdDataTypeID),
            ThresholdComparisonOperatorTypeID: Number(myValues.thresholdComparisonID),
            ThresholdLogicOperatorTypeID: 2,    //For now this is hardcoded as we haven't developed for multiple thresholds
            ThresholdValue: Number(myValues.thresholdTriggerValue),
            CoupledAlarmThresholdID: null,
            UnitTypeID: Number(myValues.thresholdUnitTypeID),
        }],
    };

    return alarmCreate;
}

export const editPostObject = (formikValues, companyID, userID) => {
    let myValues = formikValues;

    const alarmCreate = {
        AlarmID: myValues.alarmID,
        CompanyID: Number(companyID),
        UserID: Number(userID),
        DisplayName: myValues.alarmName,
        SystemStatusID: Number(myValues.alarmSeverityID),
        Summary: myValues.alarmSummary,
        SendNotification: myValues.sendNotification,
        NotificationID: Number(myValues.notificationID),
        AlarmRecipientList: myValues.notificationRecipients.map(recipient => ({
            RecipientID: Number(recipient.value),
            AlarmID: null,
            IsGroup: recipient.isGroup,
        })),
        ComputedDataSourceIDList: myValues.assignedComputedDataSourceIDs,
        AlarmThresholdList: [{
            AlarmThresholdID: null,
            AlarmID: null,
            DataTypeID: Number(myValues.thresholdDataTypeID),
            ThresholdComparisonOperatorTypeID: Number(myValues.thresholdComparisonID),
            ThresholdLogicOperatorTypeID: 2,    //For now this is hardcoded as we haven't developed for multiple thresholds
            ThresholdValue: Number(myValues.thresholdTriggerValue),
            CoupledAlarmThresholdID: null,
            UnitTypeID: Number(myValues.thresholdUnitTypeID),
        }],
    };

    return alarmCreate;
}