import React from 'react';
import { FormControl, RadioGroup, FormLabel, Radio, FormControlLabel } from '@mui/material';
import SubmitModal from '../Modals/SubmitModal';



export default function AssignModal(props) {
    //Shows a modal with a list of ControlGates the node can be assigned to
    return (
        <SubmitModal
            key={props.id}
            title="Assign Parent"
            open={props.assignModalOpen}
            handleClose={props.handleAssignModalClose}
            handleSave={() => { return (props.handleAssignClick(props.id, props.selectedControlGate))}}
        >
            <FormControl>
                    <FormLabel>{`Select a parent for ${props.displayName}:`}</FormLabel>
                    <RadioGroup
                        value={props.selectedControlGate}
                        onChange={props.handleSelectedControlGateChange}
                    >
                        {props.controlgates.map((cg) => {
                            return (
                                <FormControlLabel key={cg.ObjectID} value={cg.ObjectID} control={<Radio />} label={cg.DisplayName} />
                            )
                        }) }
                    </RadioGroup>
            </FormControl>
            <br />
        </SubmitModal>
    )
}