import React, { useState, useContext, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Toast } from 'primereact/toast';
import { SessionContext } from '../../SessionContext';
import {
    fetchUserAccountMgmt,
    acceptEULA,
    respondToInvitation,
    fetchTimeZones,
    setDefaultCompany
} from '../../repositories/AccountMgmtQueryFunctions';
import { fetchCompanyInfo } from '../../repositories/CompanyQueryFunctions';
import StatusBackdrop from '../../components/Generic/StatusBackdrop';
import StatusMessage from '../../components/Generic/StatusMessage';
import CustomHeader from '../../components/Generic/CustomHeader';
import AccountMgmtHome from '../../components/Account/AccountMgmtHome';
//import AccountChangePassword from './AccountChangePassword';
import AccountCompanyInvites from '../../components/Account/AccountCompanyInvites';
import AccountEULAMgmt from '../../components/Account/AccountEULAMgmt';
import AccountEditProfile from '../../components/Account/AccountEditProfile';
import { styled } from '@mui/system';
import {
    Tabs,
    Tab,
    Box,
} from '@mui/material';
import { createToastSuccess, createToastError } from '../../helpers/MiscFunctions';

// Using styled utility
const StyledTab = styled(Tab)`
  font-size: 18px;
  font-weight: bold;
`;

// Parent component for the Account Management components
export default function AccountManagement() { 
    const { sessionData, updateSessionData } = useContext(SessionContext);
    const toast = useRef(null);

    const [errorState, setErrorState] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [userAccount, setUserAccount] = useState({});
    const [latestEulaVersion, setLatestEulaVersion] = useState({});
    const [userEULAAcceptance, setUserEULAAcceptance] = useState([]);
    const [userInvites, setUserInvites] = useState([]);
    const [userCompanies, setUserCompanies] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [isDirty, setIsDirty] = useState(true);
    const [userID] = useState(sessionData.userID);

    const handleTabChange = (event, newValue) => {  
        if (typeof newValue === 'number') {
            setCurrentTab(newValue);
        } else if (event !== null) {
            setCurrentTab(event);
        }
    };

    const handleSetAsDefault = (companyID) => {
        updateSessionData({ defaultCompanyID: companyID });
        doSetAsDefault.mutateAsync({ userID, companyID });
    };

    const doSetAsDefault =
        useMutation(({ userID, companyID }) => setDefaultCompany({ userID, companyID }), {
            onSuccess: (_result) => {
                let toastMessage = createToastSuccess(`Success! ${sessionData.currentCompanyName} has been set as your default company.`);
                toast.current.show(toastMessage);
                setIsDirty(true);
            },
            onError: (failError) => {
                let toastMessage = createToastError(`An error occurred while attempting to set ${sessionData.currentCompanyName} as your default company.\n${failError.message}`);
                setIsDirty(false);
                toast.current.show(toastMessage);
            }
        });

    const handleSwitchToCompanyID = (companyID) => {
        updateSessionData({ currentCompanyID: companyID });    };

    const { isLoading } =
        useQuery(["companyInfo", sessionData.currentCompanyID], fetchCompanyInfo, {
            onSuccess: (data) => {
                updateSessionData({ currentCompanyName: data.CompanyName });
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(error || "An unexpected error occurred.");
            },
            enabled: sessionData.currentCompanyID > 0 && !sessionData.currentCompanyName
        });

    const { isLoadingDefault } =
        useQuery(["companyInfo2", sessionData.defaultCompanyID], fetchCompanyInfo, {
            onSuccess: (data) => {
                updateSessionData({ defaultCompanyName: data.CompanyName });
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(error || "An unexpected error occurred.");
            },
            enabled: !sessionData.defaultCompanyName
        });

    const { isLoading: isLoadingTZ, error: errorTZ, data: dataB } =
        useQuery(["timezones"], fetchTimeZones, {
            onSuccess: (dataB) => {
                setTimeZones(dataB);
            },
            onError: (errorTZ) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorTZ || "An unexpected error occurred.");
            }
        });

    const { isLoading: isLoadingAcct, error: errorAcct, data: dataA } =
        useQuery(["userAccountMgmt", sessionData.userID], fetchUserAccountMgmt, {
            onSuccess: (dataA) => {

                setUserAccount(dataA);
                setLatestEulaVersion(dataA.LatestEulaVersion);
                setUserEULAAcceptance(dataA.UserEulaAcceptance);
                setUserInvites(dataA.UserInvitations);
                setUserCompanies(dataA.UserCompanies);
                setIsDirty(false);
            },
            onError: (errorAcct) => {
                // Handle the error here. For example, you can log the error or set an error state.
                //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorAcct || "An unexpected error occurred.");
            }, 
            enabled: isDirty
        });

    const handleIsDirty = (isThisDirty) => {
        setIsDirty(isThisDirty);
    }

    const handleAcceptEULA = (userID, versionID) => {
        doAcceptEULA.mutateAsync({ userID, versionID });
    };

    const doAcceptEULA =
        useMutation(({ userID, versionID }) => acceptEULA({ userID, versionID }), {
            onSuccess: (_result) => {
                let toastMessage = createToastSuccess('End-User License Agreement (EULA) has been accepted.');
                toast.current.show(toastMessage);  
                setIsDirty(true);
            },
            onError: (failError) => {
                let toastMessage = createToastError(`An error occurred while attempting to accept End-User License Agreement (EULA).\n${failError.message}`);
                toast.current.show(toastMessage);
            }
        });

    const handleInvitationResponse = (userID, companyID, accept) => {
        doInviteResponse.mutateAsync({ userID, companyID, accept });
    };

    const doInviteResponse =
        useMutation(({ userID, companyID, accept }) => respondToInvitation({ userID, companyID, accept }), {
            onSuccess: (_result) => {
                let toastMessage = createToastError('Invitation Accepted.');
                toast.current.show(toastMessage);
            },
            onError: (failError) => {
                let toastMessage = createToastError(`An error occurred while attempting to accept invitation.\n${failError.message}`);
                toast.current.show(toastMessage);
            }
        });
    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }


    return (
        <Box sx={pageStyle}>
            <Toast ref={toast} />
            <CustomHeader headerText={"Account Management"} />
            {(isLoading || isLoadingAcct || isLoadingDefault)
                && <StatusBackdrop open={(isLoading || isLoadingAcct || isLoadingDefault)} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Account Management"
                    statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }            
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                centered
                aria-label="Account Management"
            >
                <StyledTab label="Account Home" />
                <StyledTab label="EULA Management" />
                {/*<StyledTab label="Change Password" />*/}
                <StyledTab label="Company Invitations" />
                <StyledTab label="Edit Profile" />
            </Tabs>
            {currentTab === 0 &&
                <AccountMgmtHome
                    userAccount={userAccount}
                    currentCompany={sessionData.companyID}
                    userCompanies={userCompanies}
                    handleTabChange={handleTabChange}
                    handleSetAsDefault={handleSetAsDefault}
                    handleSwitchToCompanyID={handleSwitchToCompanyID}
                />
            }
            {currentTab === 1 &&
                <AccountEULAMgmt
                    userAccount={userAccount}        
                    latestEulaVersion={latestEulaVersion}
                    userEULAAcceptance={userEULAAcceptance}
                    handleAcceptEULAMgmt={handleAcceptEULA}
                />
            }
            {/*{currentTab === 2 &&*/}
            {/*    <AccountChangePassword*/}
            {/*        userID={userID}*/}
            {/*    />*/}
            {/*}*/}
            {currentTab === 2 &&
                <AccountCompanyInvites
                    userInvites={userInvites}
                    handleInvitationResponse={handleInvitationResponse}                    
                />
            }
            {currentTab === 3 &&
                <AccountEditProfile
                    userAccount={userAccount}
                    timeZones={timeZones}
                    handleIsDirty={handleIsDirty}
                />
            }
        </Box>
    );
}