import { CalculateTimeDifference, GetSeverityStatusColorVIB} from "./MiscFunctions"

export const mapVibrationObject = (v, newDashboardData, enums, vendorIcon, vibrationObjectNodes) => {
    //Creating dashboard data object per asset
    const obj = {
        AccelerationID: v.AccelerationID,
        AnalysisTypeID: v.AnalysisTypeID,
        DisplayName: v.DisplayName,
        DriveType: enums.driveType[v.DriveTypeID],
        EnabledVibrationChannels: v.EnabledVibrationChannels,
        FixedRPM: v.FixedRPM,
        GearMeshValue: v.GearMeshValue,
        HighRPM: v.HighRPM,
        ID: v.ID,
        IndividualNodeAlarms: v.IndividualNodeAlarms,
        IsConnected: v.IsConnected,
        LowRPM: v.LowRPM,
        MachineType: enums.machineType[v.MachineTypeID],
        SamplingPeriod: v.SamplingPeriod,
        Nodes: [],
        NodeNames: v.DisplayName + " ",
        AssetStatus: {},
        VendorName: v.VendorName,
        VendorIcon: vendorIcon,
        ObjectDefectSummary: v.ObjectDefectSummary
    };

    // Cycle through each DB node object
    vibrationObjectNodes.filter(n => n.ObjectID === v.ID && n.NodeID != undefined).map((n) => {
        const node = {
            NodeID: n.NodeID,
            DisplayName: n.NodeDisplayName,
            OrientationID: n.OrientationID,
            PositionType: enums.positionType.find(p => p.ID === n.PositionID),
            IsConnected: n.IsConnected,
            CheckinInfo: {},
            SignalInfo: {},
            BatteryInfo: {},
            StatusInfo: {}
        };
        obj.Nodes.push(node);

        // Node names used for search function in datatable
        obj.NodeNames += (n.NodeDisplayName ? n.NodeDisplayName + " " : " ");
    });
    newDashboardData.push(obj);
    return obj;
}

export const mapDashboardData = (o, nodeDiagnosticStatus, nodeCheckInStatus, nodeMaxAlarmValues, nodeAmplitudeData, enums) => {
    let diagnostics = nodeDiagnosticStatus.filter(s => s.ObjectID === o.ID);
    o.Diagnostics = [];
    if (diagnostics && diagnostics.length > 0) {
        o.Diagnostics = o.Diagnostics.concat(diagnostics);
    }

    let checkin = nodeCheckInStatus.filter(s => s.ObjectID === o.ID);

    o.CheckinInfo = checkin;
    //console.log("checkin: " + JSON.stringify(checkin));

    let maxAlarm = nodeMaxAlarmValues.filter(a => a.ObjectID === o.ID);
    o.MaxAlarm = maxAlarm;
    o.AssetStatus = parseAssetStatus(maxAlarm, checkin, enums);

    // Cycle through each object node
    o.Nodes.map((n) => {
        n.SignalInfo = parseNodeSignal(o.ID, n.NodeID, nodeDiagnosticStatus);
        n.BatteryInfo = parseNodeBattery(o.ID, n.NodeID, nodeDiagnosticStatus);
        n.CheckinInfo = parseNodeCheckin(o.ID, n.NodeID, nodeCheckInStatus);
        n.StatusInfo = parseNodeStatus(o.ID, n.NodeID, n.CheckinInfo, nodeMaxAlarmValues, enums);
        n.AmplitudeData = nodeAmplitudeData ? parseNodeVibrationData(n.NodeID, n.OrientationID, nodeAmplitudeData, enums) : null;
        n.TemperatureInfo = parseNodeTemperature(n.NodeID, nodeDiagnosticStatus);
    });
}

// Pull wireless signal diagnostic from  incoming DB information
const parseNodeSignal = (objectID, nodeID, nodeDiagnosticStatus) => {
    const info = nodeDiagnosticStatus.find(
        d => d.ObjectID === objectID
            && d.NodeID === nodeID
            && d.DataTypeDisplayName.includes('Signal Strength')
    );

    if (info && Object.keys(info).length > 0) {
        return info;
    } else {
        return {};
    }
}


// Pull battery diagnostic from incoming DB information
const parseNodeBattery = (objectID, nodeID, nodeDiagnosticStatus) => {
    const info = nodeDiagnosticStatus.find(
        d => d.ObjectID === objectID
            && d.NodeID === nodeID
            && d.NodeChannelDisplayName === 'Battery Voltage'
    );

    if (info && Object.keys(info).length > 0) {
        return info;
    } else {
        return {};
    }

}

const parseAssetStatus = (maxAlarm, checkin, enums) => {
    const maxAlarmValues = maxAlarm.map((alarm) => alarm.MaxAlarmValue);

    const nonNullValues = maxAlarmValues.filter((value) => value !== null);

    const maxAlarmValue = nonNullValues.length > 0 ? Math.max(...nonNullValues) : null;

    const secondsSinceCheckIns = checkin.map((checkIn) => CalculateTimeDifference(checkIn.CheckInTimeZone));
    const nonsecNullValues = secondsSinceCheckIns.filter((value) => value !== null);
    const minSecondsSinceCheckIn = nonsecNullValues.length > 0 ? Math.min(...nonsecNullValues) : null;

    return GetSeverityStatusColorVIB(
        maxAlarmValue,
        enums.alarmStatusInfo,
        minSecondsSinceCheckIn
    );
}

// Pull node status from incoming DB information
const parseNodeStatus = (objectID, nodeID, checkinInfo, nodeMaxAlarmValues, enums) => {
    const nodeStatus = nodeMaxAlarmValues.find(
        d => d.ObjectID === objectID
            && d.NodeID === nodeID
    );
    let status = nodeStatus ? nodeStatus.MaxAlarmValue : null;

    return GetSeverityStatusColorVIB(
        status,
        enums.alarmStatusInfo,
        checkinInfo ? checkinInfo.SecondsSinceCheckIn : null
    );
}


// Pull node checkin info from incoming DB information
const parseNodeCheckin = (objectID, nodeID, nodeCheckInStatus) => {
    let checkin = nodeCheckInStatus.find(
        d => d.ObjectID === objectID
            && d.NodeID === nodeID
    );

    //console.log("CHECKIN 1: " + JSON.stringify(checkin));
    if (checkin && Object.keys(checkin).length > 0) {
        const seconds = checkin.CheckInTimeZone ?
            CalculateTimeDifference(checkin.CheckInTimeZone) : null;
        //console.log(JSON.stringify(seconds));
        checkin.SecondsSinceCheckIn = seconds;

        //console.log("CHECKIN 2: " + JSON.stringify(checkin));

        return checkin;
    } else {
        return {};
    }
}

const parseNodeVibrationData = (nodeID, orientationID, nodeAmplitudeData, enums) => {
    let nodeData = [];
    if (nodeAmplitudeData && nodeAmplitudeData.length > 0) {
        nodeAmplitudeData.map((d) => {
            if (d.NodeID == nodeID) {
                const nodeDataPoint = {
                    NodeID: d.NodeID,
                    PositionID: d.PositionID,
                    OrientationID: orientationID,
                    AxisDisplayName: parseAxisDisplayName(enums, orientationID, d.AxisID),
                    AxisID: d.AxisID,
                    BandID: d.BandID,
                    BandDisplayName: enums.bands.find(b => b.ID === d.BandID).DisplayName,
                    IsEnabled: d.IsEnabled,
                    AlarmTypeID: d.AlarmTypeID,
                    ComputedValue: d.ComputedValue,
                    ComputedOn: d.ComputedOn,
                    CheckedInOn: d.CheckedInOn,
                    WarningValue: d.WarningValue,
                    DangerValue: d.DangerValue,
                    SecondsSinceCheckIn: d.CheckInTimeZone ?
                        CalculateTimeDifference(d.CheckInTimeZone) : null,
                    UnitTypeSymbol: d.UnitTypeSymbol,
                    WarningThresholdValue: d.WarningThresholdValue,
                    DangerThresholdValue: d.DangerThresholdValue,
                    DashboardDataID: d.DashboardDataID
                };

                nodeData.push(nodeDataPoint);
            }
        })
    }
    return nodeData;
}

const parseAxisDisplayName = (enums, orientationID, axisID) => {
    const sensorAxis = enums.axisOrientations.filter(x => x.OrientationID === orientationID && x.ID === axisID);
    let vibrationAxis = {};

    if (sensorAxis && sensorAxis.length > 0) {
        vibrationAxis = enums.axisOrientations.find(x => x.OrientationID === orientationID && x.ID === sensorAxis.OrientationAxisID);
    }

    if ((sensorAxis && sensorAxis.length > 0) && (vibrationAxis && vibrationAxis.length > 0)) {
        return vibrationAxis.DisplayName + "(" + sensorAxis.DisplayName + ")";
    } else {
        return "";
    }
}

const parseNodeTemperature = (nodeID, nodeDiagnosticStatus) => {
    const info = nodeDiagnosticStatus.find(
        d => d.NodeID === nodeID
            && d.NodeChannelDisplayName === 'Surface Temperature'
    );

    if (info && Object.keys(info).length > 0) {
        return info;
    } else {
        return {};
    }
}