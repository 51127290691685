import NotificationsIcon from '@mui/icons-material/Notifications';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box, Button, Grid, Paper, Stack,


    Step,
    StepLabel, Stepper,




    Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyInfoContext } from '../../App';
import AlarmStep1Content from '../../components/Alarm/AlarmStep1Content';
import AlarmStep2Content from '../../components/Alarm/AlarmStep2Content';
import AlarmStep3Content from '../../components/Alarm/AlarmStep3Content';
import CustomHeader from '../../components/Generic/CustomHeader';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import StatusMessage from "../../components/Generic/StatusMessage";
import { ColorlibConnector, ColorlibStepIconRoot, styles } from '../../components/Generic/StepperComponents';
import { createPostObject } from '../../helpers/AlarmHelpers';
import { createToastError, createToastSuccess } from '../../helpers/MiscFunctions';
import { alarmFormModel } from '../../models/Alarm/alarmFormModel';
import { alarmInitialValues } from '../../models/Alarm/alarmInitialValues';
import { alarmAddValidationSchema } from '../../models/Alarm/alarmValidationSchema';
import { fetchCompanyEnums, postAlarm } from '../../repositories/AlarmQueryFunctions';
import { fetchCompanyInfo } from '../../repositories/CompanyQueryFunctions';

const steps = [
    {
        name: 'step0',
        label: 'Step 1: Alarm Name and Targets',
        description1: `Give the alarm a name and description here.`,
        description2: `Select the alarm threshold data type and the targets to apply the alarm to.`,
        validationSchema: alarmAddValidationSchema[0]
    },
    {
        name: 'step1',
        label: 'Step 2: Add Alarm Rules',
        description1: 'Create rules for the alarm and select the notification methods.',
        description2: ``,
        validationSchema: alarmAddValidationSchema[1]
    },
    {
        name: 'step2',
        label: 'Step 3: Review and Save',
        description1: ``,
        description2: ``,
        validationSchema: alarmAddValidationSchema[2]
    },
];

const { formId } = alarmFormModel;

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <NotificationsIcon />,
        2: <SettingsIcon />,
        3: <SaveIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function AlarmStepperAddNew() {
    // Consume parameters from URL access
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
    const navigate = useNavigate();
    const { companyID, userID, viewAll } = useParams();

    const toast = useRef(null);

    const [activeStep, setActiveStep] = useState(0);
    const [alarmStatusInfo, setAlarmStatusInfo] = useState([]);
    const [alarmStatusValues, setAlarmStatusValues] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationPeriods, setNotificationPeriods] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [dataTypes, setDataTypes] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [comparisonOperatorTypes, setComparisonOperatorTypes] = useState([]);
    const [dataTypeTargets, setDataTypeTargets] = useState([]);
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [finishing, setFinishing] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [errorState, setErrorState] = useState();

    const isLastStep = activeStep === steps.length - 1;

    const { isLoading, error, data } =
        useQuery(["companyInfo", companyID], fetchCompanyInfo, {
            onSuccess: (data) => {
                setCompanyInfo(data);
            },
            onError: (error) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(error || "An unexpected error occurred.");
            }
        });
    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE } =
        useQuery(["enums", companyID, viewAll], fetchCompanyEnums, {
            onSuccess: (dataE) => {
                setAlarmStatusInfo(dataE.CompanyStdAlarmStatuses);

                setAlarmStatusValues(dataE.CompanyStdAlarmStatuses.map((item) => ({
                    "value": item.SystemStatusID,
                    "label": item.StatusDisplayName
                })));

                setNotifications(dataE.CompanyNotifications.map((item) => ({
                    "value": item.NotificationID,
                    "label": item.NotificationDisplayName
                })));

                setNotificationPeriods(dataE.NotificationPeriods.map((item) => ({
                    "value": item.PeriodID,
                    "label": item.PeriodDisplayName
                })));

                setDataTypes(dataE.DataTypes.map((item) => ({
                    "value": item.DataTypeID,
                    "label": item.DisplayName
                })));

                setUnitTypes(dataE.UnitTypes.map((item) => ({
                    "value": item.UnitTypeID,
                    "label": item.DisplayName,
                    "dataTypeID": item.DataTypeID
                })));

                setComparisonOperatorTypes(dataE.ThresholdComparisonOperatorTypes.map((item) => ({
                    "value": item.ThresholdComparisonOperatorTypeID,
                    "label": item.DisplayName,
                    "displaySymbol": item.DisplaySymbol
                })));

                const transformedData = dataE.CompanyRecipients.map((item) => ({
                    "value": item.RecipientID,
                    "label": item.UserName,
                    "isGroup": item.IsGroup,
                    "group": item.IsGroup ? "Recipient Group" : "Recipient",
                }));

                const groupedData = transformedData.reduce((acc, item) => {
                    if (!acc[item.group]) {
                        acc[item.group] = [];
                    }
                    acc[item.group].push(item);
                    return acc;
                }, {});

                const menuItems = Object.entries(groupedData).map(([label, options]) => ({
                    label,
                    options,
                }));

                setRecipients(menuItems);
                setIsLoadedEnums(true);
            },
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function createHandleSubmit(formik) {
        return async function handleSubmit() {
            const isLastStep = activeStep === steps.length - 1;

            const currentStepFields = alarmAddValidationSchema.reduce((acc, schema) => {
                const fields = Object.keys(schema.describe().fields);
                return acc.concat(fields);
            }, []);

            const errors = await formik.validateForm();
            const stepErrors = currentStepFields.some((field) => errors[field]);

            if (!stepErrors) {
                if (!isLastStep) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    // Final step submission is handled by handleFinalSubmit, nothing needs to be done here
                }
            } else {
                // Set touched state for current step fields
                currentStepFields.forEach((field) => {
                    formik.setFieldTouched(field, true);
                });
            }
        };
    }

    async function waitForConfirmation(event, formik) {
        return confirmDialog({
            trigger: event.currentTarget,
            message: 'Are you sure you want to submit?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { handleFinalSubmit(formik) },
            reject: () => { setFinishing(false); setDisableButton(false); }
        });
    };

    const mutation = useMutation(postAlarmObj => postAlarm(postAlarmObj));

    async function handleFinalSubmit(formik) {

        const postAlarmObj = createPostObject(formik.values, companyID, userID);

        //console.log(JSON.stringify(postAlarmObj));

        mutation.mutate(postAlarmObj, {
            onSuccess: (result) => {
                //console.log(JSON.stringify(result));
                // Handle success
                const toastMessage = result.status === 200 && result.data > 0
                    ? createToastSuccess('Alarm has been saved.')
                    : createToastError('Alarm could not be saved. Please try again.');
                setTimeout(() => {
                    setFinishing(false);
                    toast.current.show(toastMessage);
                }, 3000);
            },
            onError: (error) => {
                // Handle error
                const toastMessage = { /* error message object */ };
                setTimeout(() => {
                    setFinishing(false);
                    setDisableButton(false);
                    toast.current.show(toastMessage);
                }, 3000);
            }
        });
    }

    const handleDataTypeTargets = (dataTypeTargets) => {
        setDataTypeTargets(dataTypeTargets);
    }

    const alarmSeverityLevel = (level) => {
        return alarmStatusInfo.find(item => item.SystemStatusID === level);
    }

    const handleSelectedOptionsChange = (options) => {
        setSelectedRecipents(options);
    };

    const handleBackButtonClick = () => {
        navigate(`/Alarm/AlarmDashboard/${companyID}/${userID}/${viewAll}`);
    }

    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    };

    return (
        <Box sx={pageStyle}>
            <Grid container spacing={1} columns={12}>
                <Grid container item spacing={3} justifyContent="space-between">
                    <Grid item xs={10}>
                        <CustomHeader headerText={"Add New Alarm "} />
                    </Grid>
                    {/* Back Button Grid Item */}
                    <Grid item xs={2}>
                        <Button fullWidth onClick={handleBackButtonClick} variant="contained">Back To Dashboard</Button>
                    </Grid>

                </Grid>
            </Grid>
            <Paper style={styles.paper} elevation={6}>
                {(isLoadingEnums || !isLoadedEnums)
                    && <StatusBackdrop open={(isLoadingEnums || !isLoadedEnums)} />}
                {finishing && <StatusBackdrop open={finishing} />}
                {errorState &&
                    <StatusMessage
                        open={errorState}
                        severity="error"
                        location="Add New Alarm"
                        statusCode={errorState.request.status}
                        message={errorState.message}
                        error={errorState}
                    />
                }
                <Toast ref={toast} />
                <div style={{ height: '10px' }} /> {/* Add space above Stepper */}
                <Stepper alternativeLabel activeStep={activeStep} style={styles.stepper} connector={<ColorlibConnector />}>
                    {steps.map(({ label }) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Formik
                    initialValues={alarmInitialValues}
                    validationSchema={steps[activeStep].validationSchema}
                    onSubmit={handleFinalSubmit}
                >
                    {(formik) => (
                        <Form id={formId}>
                            <div>
                                <div>
                                    <Typography variant="h5" align="center">{steps[activeStep].label}</Typography>
                                    <Typography variant="body1" align="center">
                                        {steps[activeStep].description1}
                                    </Typography>
                                    <Typography variant="body1" align="center" style={{ marginTop: '0.5em', marginBottom: '2em' }}>
                                        {steps[activeStep].description2}
                                    </Typography>
                                    {activeStep === 0 ? (
                                        <Field name="isCheckedTargets">
                                            {({ field }) => (
                                                <AlarmStep1Content
                                                    dataTypes={dataTypes}
                                                    unitTypes={unitTypes}
                                                    formik={formik} // Pass the formik object instead of formikProps
                                                    companyID={companyID}
                                                    alarmTargetsList={null}
                                                    onDataTypeTargetsChange={handleDataTypeTargets}
                                                />

                                            )}
                                        </Field>
                                    ) : null}
                                    {activeStep === 1 ? (
                                        <AlarmStep2Content
                                            formik={formik}
                                            unitTypes={unitTypes}
                                            notificationPeriods={notificationPeriods}
                                            notifications={notifications}
                                            recipients={recipients}
                                            comparisonOperatorTypes={comparisonOperatorTypes}
                                            alarmStatusInfo={alarmStatusValues}
                                        />
                                    ) : null}
                                    {activeStep === 2 ? (
                                        <AlarmStep3Content
                                            formik={formik}
                                            alarmStatusInfo={alarmSeverityLevel}
                                            alarmStatusValues={alarmStatusValues}
                                            unitTypes={unitTypes}
                                            notificationPeriods={notificationPeriods}
                                            notifications={notifications}
                                            comparisonOperatorTypes={comparisonOperatorTypes}
                                            dataTypeTargets={dataTypeTargets}
                                        />
                                    ) : null}
                                    <Stack
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="baseline"
                                        spacing={4}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={styles.button}
                                            onClick={() => {
                                                // Call the handleReset function on the onClick event
                                                formik.resetForm();
                                                setActiveStep(0);
                                            }}
                                            disabled={disableButton}
                                        >
                                            Reset
                                        </Button>
                                        {activeStep !== 0 && (
                                            <Button
                                                style={styles.button}
                                                disabled={disableButton}
                                                onClick={handleBack}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={disableButton}
                                            onClick={(e) => {
                                                if (isLastStep) {
                                                    setFinishing(true);
                                                    setDisableButton(true);
                                                    waitForConfirmation(e, formik);
                                                } else {
                                                    createHandleSubmit(formik)();
                                                }
                                            }}
                                        >
                                            {isLastStep ? "Finish" : "Next"}
                                        </Button>

                                        {finishing && (
                                            <StatusBackdrop open={finishing} />
                                        )}
                                        <ConfirmDialog
                                            header="Confirmation"
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div style={{ height: '10px' }} /> {/* Add space below Stepper */}
            </Paper>
        </Box>
    );

}



//const FormObserver = () => {
//    const { values, touched, errors } = useFormikContext();

//    //useEffect(() => {
//    //    console.log("FormObserver::errors", JSON.stringify(errors));
//    //}, [errors]);

//    //useEffect(() => {
//    //    console.log("FormObserver::touched", JSON.stringify(touched));
//    //}, [touched]);

//    useEffect(() => {
//        //console.log("FormObserver::values", JSON.stringify(values));
//    }, [values]);

//    //useEffect(() => {

//    //}, []);

//    return null;
//};