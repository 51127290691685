import { Box, Button, Grid, Typography, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyInfoContext } from '../../App';
import CustomHeader from '../../components/Generic/CustomHeader';
import StatusBackdrop from "../../components/Generic/StatusBackdrop";
import StatusMessage from "../../components/Generic/StatusMessage";
import EmptyAsset from '../../components/images/EmptyAsset.png';
import VibrationAddAssetHelp from '../../components/images/VibrationAddAssetHelp.png';
import CentrifugalPump from '../../components/images/Centrifugal Pump.jpg';
import MoreInfo from '../../components/Modals/MoreInfo';
import AssetDashboardTableExpandable from "../../components/Vibration/AssetDashboardTableExpandable";
import { mapDashboardData, mapVibrationObject } from '../../helpers/VibrationHelpers';
import { fetchCompanyInfo } from '../../repositories/CompanyQueryFunctions';
import { fetchImage } from '../../repositories/ImageQueryFunctions';
import {
    fetchVibrationDashboard, fetchVibrationEnums
} from '../../repositories/VibrationQueryFunctions';



export default function AssetDashboard() {
    const navigate = useNavigate();
    // Consume parameters from URL access
    const { companyID, userID, viewAll } = useParams();
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);

    // State to handle recurring DB interactions
    const initalState = 0;
    const [refresh, setRefresh] = useState(initalState);
    const [vibrationObjects, setVibrationObjects] = useState([]);
    const [vibrationObjectNodes, setVibrationObjectNodes] = useState([]);
    const [nodeDiagnosticStatus, setNodeDiagnosticStatus] = useState([]);
    const [nodeCheckInStatus, setNodeCheckInStatus] = useState([]);
    const [nodeMaxAlarmValues, setNodeMaxAlarmValues] = useState([]);

    // State to handle relevant enumerations
    const [enums, setEnums] = useState();

    // State to control rendered dashboard objects
    const [isLoadedEnums, setIsLoadedEnums] = useState(false);
    const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [dashboardData, setDashboardData] = useState([]);
    const [dashboardRowsExpanded, setDashboardRowsExpanded] = useState([]);
    const [dashboardRowsPerPage, setDashboardRowsPerPage] = useState(10);
    const [dashboardPage, setDashboardPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [errorState, setErrorState] = useState();
    const [imagesLoading, setImagesLoading] = useState(true);
    const [vendorIcon, setVendorIcon] = useState(null);
    const [failedToLoad, setFailedToLoad] = useState({});

    // Force react components to refresh every 10 seconds (will run on refresh variable change)
    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(refresh + 1);
        }, 10000);
        return () => clearInterval(interval)
    }, [refresh]);

    const { isLoading, error, data } =
        useQuery(["companyInfo", companyID], fetchCompanyInfo, {
        onSuccess: (data) => {
            setCompanyInfo(data);
            },
        onError: (error) => {
            // Handle the error here. For example, you can log the error or set an error state.
            //console.log("An error occurred while fetching company info:", JSON.stringify(enumsError));
            // Optionally, you can set an error state to display an error message to the user.
            setErrorState(error || "An unexpected error occurred.");
        }
    });

    const handleImageError = (vendorName) => {
        setFailedToLoad({ ...failedToLoad, [vendorName]: true });
    };

    const fetchVendorImage = async (ImageFileName) => {
        if (!ImageFileName) {
            setImagesLoading(false);
            return;
        }

        try {
            const image = await fetchImage(ImageFileName, process.env.REACT_APP_IMAGE_CONTAINER_NOTIFICATION);
            setVendorIcon(image);
        } catch (error) {
            console.error('Error fetching image:', error);
            // Handle error appropriately
        } finally {
            setImagesLoading(false);
        }
    };
    useEffect(() => {
        if (imagesLoading) {
            fetchVendorImage('fiix_logo_color_center.png');
        }
    }, []);

    // Fetch Enumerations from DB
    const { isLoading: isLoadingEnums, error: enumsError, data: dataE, refetch: refetchEnums } =
        useQuery(["enums"], fetchVibrationEnums, {
            onSuccess: (dataE) => {
                setEnums({
                    driveType: dataE.DriveTypes,
                    machineType: dataE.MachineTypes,
                    alarmStatusInfo: dataE.AlarmStatusInfo,
                    positionType: dataE.PositionTypes
                })
                setIsLoadedEnums(true)
            },        
            onError: (enumsError) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(enumsError || "An unexpected error occurred.");
            }
        });


    // Fetch Vibration Objects to populate dashboard from DB (only react on DB changes)
    const { isLoading: isLoadingVibrationObjects, error: errorVibrationObjects, data: dataA, refetch: refetchDashboard } =
        useQuery(["dashboard", companyID, userID, { refresh }], fetchVibrationDashboard, {
            onSuccess: (dataA) => {
                //console.log("Fresh Dashboard Information Retreived...");

                // Handle structural changes
                if (JSON.stringify(dataA.VibrationObjects) !== JSON.stringify(vibrationObjects)) {
                    setIsLoadingDashboard(true);
                    setVibrationObjects(dataA.VibrationObjects);
                }
                if (JSON.stringify(dataA.VibrationObjectNodes) !== JSON.stringify(vibrationObjectNodes)) {
                    setIsLoadingDashboard(true);
                    setVibrationObjectNodes(dataA.VibrationObjectNodes);
                }

                // Handle data changes
                let newNodeCheckInStatus = nodeCheckInStatus.map(obj => {
                    let newObj = { ...obj };
                    delete newObj.SecondsSinceCheckIn
                    return newObj;
                })

                if (JSON.stringify(dataA.NodeDiagnosticStatuses) !== JSON.stringify(nodeDiagnosticStatus)) {
                    setIsLoadingData(true);
                    setNodeDiagnosticStatus(dataA.NodeDiagnosticStatuses);
                }
                if (JSON.stringify(dataA.NodeCheckInStatuses) !== JSON.stringify(newNodeCheckInStatus)) {
                    setIsLoadingData(true);
                    setNodeCheckInStatus(dataA.NodeCheckInStatuses);
                }
                if (JSON.stringify(dataA.NodeMaxAlarmValues) !== JSON.stringify(nodeMaxAlarmValues)) {
                    setIsLoadingData(true);
                    setNodeMaxAlarmValues(dataA.NodeMaxAlarmValues);
                }
            },
            onError: (errorVibrationObjects) => {
                // Handle the error here. For example, you can log the error or set an error state.

                // Optionally, you can set an error state to display an error message to the user.
                setErrorState(errorVibrationObjects || "An unexpected error occurred.");
            }
        });


    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingDashboard && isLoadedEnums && !imagesLoading) {
            handleDashboardStructureChange();
        }
    }, [vibrationObjects, vibrationObjectNodes, enums, imagesLoading]);


    // Update Dashboard Structure when objects change
    useEffect(() => {
        if (isLoadingData && !isLoadingDashboard && isLoadedEnums && !imagesLoading) {
            handleDashboardDataChange();
        }
    }, [dashboardData, isLoadingData, isLoadedEnums, imagesLoading]);


    // Handler for structural change to network
    const handleDashboardStructureChange = async () => {

        // Cycle through each DB vibration object

        let newDashboardData = [];
        vibrationObjects.map((v) => mapVibrationObject(v, newDashboardData, enums, vendorIcon, vibrationObjectNodes));

        // Update State
        setDashboardData(newDashboardData);
        setIsLoadingDashboard(false);
        setIsLoadingData(true);

    }


    // Handler for newly received data
    const handleDashboardDataChange = async () => {

        // Cycle through each asset object and look for new data
        dashboardData.map((o) => mapDashboardData(o, nodeDiagnosticStatus, nodeCheckInStatus, nodeMaxAlarmValues, null, enums));

        // Update state
        if (dashboardData.length > 0) {
            setDashboardData(dashboardData);
        }
        setIsLoadingData(false);
    }


    // Handle an Add Asset Button Click
    const handleAddButtonClick = () => {
        navigate(`/Vibration/AddAsset/${companyID}/${userID}/${viewAll}`);
    }

    const handleRowsPerPageChange = (rowsPerPage) => {
        setDashboardRowsPerPage(rowsPerPage)
    }
    // Handle a change in expanded rows
    const handleDashboardRowChange = (expandedRows) => {
        setDashboardRowsExpanded(expandedRows)
    }

    const handlePageChange = (currentPage) => {
        setDashboardPage(currentPage)
    }

    // Handle a change in search text
    const handleSearchTextChange = (searchText) => {
        if (!searchText) {
            setSearchText("");
        } else {
            setSearchText(searchText);
        }
    }

    // Handle MoreMenu open/close
    const handleMoreMenuChange = (expandedMenu) => {
        setMoreMenuExpanded(expandedMenu)
    }


    const pageStyle = {
        margin: "2%",
        flexGrow: 1
    }

    const helpText = [
        {
            title: "Create an asset by clicking on \"Add Asset\" in the top right corner of the page.",
            text: <Stack alignItems="center" sx={{ mb: '25px' }}>
                <Button variant="contained" sx={{ width: '150px' }}>Add Asset</Button>
                <img
                    src={CentrifugalPump}
                    alt={'Centrifugal Pump'}
                    style={{
                        height: '200px',
                        maxWidth: '90%'
                    }}
                />
                <Typography align="center">An asset is a Motor and the driven machine. An example of this would be a pump assembly. The Combination of the motor driving the pump, and then the pump itself. These combined together to make an "asset". </Typography>
                </Stack>
        },
        {
            title: "What is an Asset?",
            text: <Typography align="center">An asset is a Motor and the driven machine. An example of this would be a pump assembly. The Combination of the motor driving the pump, and then the pump itself. These combined together to make an "asset". </Typography>
        },
        {
            title: "Why Create an Asset?",
            text: <Typography align="center" sx={{ mb: '25px' }}>Creating an asset allows for more accurate Defect Classification by utilizing the different available positions of an asset. This allows for Asset-Level alarming by providing a wholistic view of the asset's health.</Typography>
        },
        {
            title: "",
            text: <img
                src={VibrationAddAssetHelp}
                alt={'Vibration Asset Dashboard'}
                style={{
                    width: '100%'
                }}
            />
        },
        {
            title: "Asset Dashboard",
            text: <Stack>
                <Typography align="center">The <strong>Asset Dashboard</strong> shows a snapshot of the overall health of all created assets.</Typography>
                <Typography align="center">The colors coordinate with the good (green), warning (yellow), and danger (red) levels for the status, connectivity, and battery life.</Typography>
            </Stack>
        },
        {
            title: "Individual Asset Status",
            text: <Stack>
                <Typography align="center">For each individual asset, the status, connectivity, and battery life will be shown.</Typography>
                <Typography align="center">To graphically view the asset's vibration data, click "<strong>View</strong>" in the <strong>View Asset</strong> column.</Typography>
                <Typography align="center">If an asset has an active alarm, click "<strong>View Identified Defects</strong>" to view probable vibration faults.</Typography>
            </Stack>
        },
        {
            title: "What if I need to make changes in the future?",
            text: <Typography align="center" sx={{ mb: '25px' }}>Click the three dots on the right side to view options for that asset. Click edit to make changes to the asset. Click clone to duplicate the asset for quick creation for similar assets.</Typography>
        },
    ]

    return (
        <Box sx={pageStyle}>
            <CustomHeader headerText={"Asset Dashboard"}/>
            {(isLoadingEnums || isLoadingVibrationObjects || isLoadingDashboard)
                && <StatusBackdrop open={(isLoadingEnums || isLoadingVibrationObjects || isLoadingDashboard) && refresh === 0} />}
            {errorState &&
                <StatusMessage
                    open={errorState}
                    severity="error"
                    location="Asset Dashboard"
                    //statusCode={errorState.request.status}
                    message={errorState.message}
                    error={errorState}
                />
            }
            {/*<GearBoxModal />*/}
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                <Grid container spacing={1} columns={6}>
                    <Grid container item spacing={3}>
                        <Grid container item xs={12} align="right" justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <Button onClick={handleAddButtonClick} variant="contained">Add Asset</Button>
                            </Grid>
                            <Grid item>
                            <MoreInfo
                                title="What is Included in the Vibration Analysis Page?"
                                imgWidth="35%"
                                helpText={helpText}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {(((!dashboardData) || (dashboardData.length === 0)) && (!isLoadingDashboard) && (!isLoadingData)) &&
                    <Grid container spacing={2} align="center" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <img src={EmptyAsset} />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {((dashboardData) && (dashboardData.length > 0) && (!isLoadingDashboard) && (!isLoadingData)) && (!imagesLoading) &&
                <Grid container spacing={2} align="center" justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <AssetDashboardTableExpandable
                            title={''}
                            data={dashboardData}
                            alarmStatusInfo={enums.alarmStatusInfo}
                            companyID={companyID}
                            userID={userID}
                            onRowExpansionChange={handleDashboardRowChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            onChangeCurrentPage={handlePageChange}
                            dashboardRowsExpanded={dashboardRowsExpanded}
                            dashboardRowsPerPage={dashboardRowsPerPage}
                            onChangeSearchText={handleSearchTextChange}
                            searchText={searchText}
                            dashboardPage={dashboardPage}
                            viewAll={viewAll}
                            searchAlwaysOpen={true}
                        />
                    </Grid>
                    {/*<h1 id="hidden-basic" sx={{ display: { lg: 'none' } }} >{refresh} </h1>*/}
                </Grid>
            }
        </Box>

    );
}

