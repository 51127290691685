import React, { useState } from 'react';
import { Paper, Modal, Button, Typography, Grid, TableContainer, Table, TableBody, Box, TextField, Divider, IconButton } from '@mui/material';
import FolderRow from './FolderRow';
import ClearIcon from '@mui/icons-material/Clear';
import ViewModal from '../Modals/ViewModal';
import SubmitModal from '../Modals/SubmitModal';

export default function FolderModal(props) {
    const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const handleNewFolderButtonClick = () => {
        setCreateFolderModalOpen(true);
    }

    const handleCreateFolderModalClose = () => {
        setCreateFolderModalOpen(false);
    }

    const handleNewFolderNameChange = (event) => {
        setNewFolderName(event.target.value);
    }

    const handleCreateFolderClick = () => {
        setCreateFolderModalOpen(false);
        props.handleCreateFolderClick(newFolderName);
    }

    return (
        <Box>
            <ViewModal
                title="Manage Folders"
                open={props.folderModalOpen}
                handleClose={props.handleFolderModalClose}
            >
                <Button variant="contained" style={{ float: "right" }} onClick={handleNewFolderButtonClick}>
                    Create Folder
                </Button>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {props.folders?.map((folder) => {
                                return (
                                    folder.ParentObjectID != -1 && <FolderRow
                                        key={folder.ObjectID}
                                        folder={folder}
                                        handleFolderNameChange={props.handleFolderNameChange}
                                        handleFolderNameSave={props.handleFolderNameSave}
                                        handleDeleteFolderClick={props.handleDeleteFolderClick}
                                        viewAll={props.viewAll}
                                        handleAssignModalOpen={props.handleAssignModalOpen}
                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ViewModal>
            <SubmitModal
                title="Create New Folder"
                open={createFolderModalOpen}
                handleClose={handleCreateFolderModalClose}
                handleSave={handleCreateFolderClick}
                saveLabel="Create Folder"
            >
                <TextField
                    sx={{ width: 500}}
                    label={"New folder name"}
                    variant="outlined"
                    value={newFolderName}
                    onChange={handleNewFolderNameChange}
                />
                <br />
            </SubmitModal>
        </Box>
        
    )
}