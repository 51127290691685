import apiClient from "../http-common";
import { fetchReques, Opacity } from "../helpers/MiscFunctions";

export const fetchNotificationTemplates = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;
    const maxRetries = 1; // Define the maximum number of retries
    let attempt = 0;

    if (!companyID) {
        throw new Error("The 'companyID' parameter is required.");
    }

    while (attempt < maxRetries) {
        try {
            const response = await apiClient.get(`/AlarmNotificationTemplate/${companyID}`);
            // If the response is successful, return the data
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                // After max retries, re-throw the error to be handled by the caller
                throw new Error(`Failed to fetch notification templates after ${maxRetries} attempts: ${error.message}`);
            }
            // Optionally, you can add a delay before retrying
            await new Promise(resolve => setTimeout(resolve, 1000 * attempt)); // Exponential back-off
        }
    }
};

export const postNotificationTemplate = async (data) => {
    try {
        const response = await apiClient.post(`/AlarmNotificationTemplate`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error posting notification template:", error);
        throw new Error("Error posting notification template:");
    }
};

export const updateNotificationTemplate = async (data) => {
    try {
        const response = await apiClient.patch(`/AlarmNotificationTemplate`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error updating notification template:", error);
        throw new Error("Error updating notification template:");
    }
};

export const deleteNotificationTemplate = async (notificationID) => {
    try {
        const response = await apiClient.delete(`/AlarmNotificationTemplate/${notificationID}`);
        return response;
    } catch (error) {
        // Log the error or handle it as needed
        console.error("Error deleting notification template:", error);
        throw new Error("Error deleting notification template:");
    }
};