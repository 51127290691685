import React, { useState } from 'react';
import FrequencyDefectChart from './FrequencyDefectChart';
import { Grid, Typography, Paper, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import FrequencyChart from '../images/FrequencyChart.png'
import MoreInfo from '../Modals/MoreInfo';

const helpText = [
    {
        title: "What do the Points Mean?",
        text: <Typography align="center">The points on the chart represent the amplitude of a frequency band and the shaft speed
            multiple at which maximum amplitude is occurring. For most defects, knowing the frequency of the alarm levels is most
            important in terms of its relationship to the turning speed of the shaft. A highlighted point (as shown above) displays
            the frequency of interest for this defect.</Typography>
    },
    {
        title: "Why is this Important?",
        text: <Typography align="center">Defects present themselves at specific frequencies based on the turn speed of the motor.
            Determining the relationship between the amplitude peaks and the turning speed of the motor allow us to isolate defects.</Typography>
    },
    {
        title: "What are the Different Frequency Relationships?",
        text: <Stack>
            <Typography align="center"><strong>Sub-Synchrounous:</strong>  Below Turning Speed of the Shaft. </Typography>
            <Typography align="center"><strong>Synchrounous:</strong> At Turning Speed of the Shaft or Exact Integer Multiple. </Typography>
            <Typography align="center"><strong>Non-Harmonic:</strong> Non-Integer Multiple of Shaft Turning Speed. </Typography>
        </Stack>
    },
]

export default function FrequencyDefect({ ingredient, allPositionSeriesData }) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    let chartMax = 0;
    allPositionSeriesData.forEach((position) => {
        position.seriesData.forEach((series) => {
            if (series.y > chartMax) {
                chartMax = series.y;
            }
        })
    });
    chartMax += 0.045

    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{mb: 1}}
            >
                <Grid item xs={9}>
                    <Typography align="right" variant="h5" style={styles.description}>
                        {ingredient.IngredientDescription}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MoreInfo
                        title="How do I Interpret Frequency Charts?"
                        image={FrequencyChart}
                        imgWidth="35%"
                        helpText={helpText}
                    />
                </Grid>
                
            </Grid>
            

            {/* Stack of charts rendered below the description */}
            {allPositionSeriesData.length > 0 ?
                (<Grid
                    container
                    direction="row"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    style={styles.grid}
                >
                    {allPositionSeriesData
                        .filter(positionData => positionData.seriesData && positionData.seriesData.length > 0)
                        .map((positionData, index) => (
                            <Grid item xs={6} key={index}>
                                <Item
                                    square={false}
                                >
                                    <Typography>{positionData.position}</Typography>
                                    <FrequencyDefectChart
                                        ingredient={ingredient}
                                        positionData={positionData}
                                        max={chartMax}
                                    />
                                </Item>
                            </Grid>

                    ))}
                </Grid>) : (<Typography sx={{ my: "10%" }}>No data present for this ingredient.</Typography>)}
            <Typography sx={{mt: '1%'}} align="right">Note: A single frequency of maximum amplitude per band is returned.
                A curve is drawn to make interpretation easier.</Typography>
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        paddingLeft: '10%'
    },
    grid: {
        justifyContent: 'center',  // Center the charts in the container
        alignItems: 'flex-start',  // Align the charts at the start
    },
};