import { fetchRequest, RequestType } from "../helpers/MiscFunctions";

export const OnClickFunctionType = Object.freeze({
    Unsync: "Unsync",
    UndoDoNotSync: "UndoDoNotSync",
    Disconnect: "Disconnect",
    SyncMHAsset: "SyncMHAsset"
});

//FETCH
export const fetchIntegrationObjectList = async ({ queryKey }) => {
    const [_key, currentPage, tableRowsPerPage, vendorID, companyID] = queryKey;

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Asset/search?VendorID=${vendorID}&CompanyID=${companyID}&PageNumber=${currentPage+1}&PageSize=1000`, 
        `Failed to fetch Integration Object List: 
            companyID: ${companyID} 
            vendorID: ${vendorID}`);
};

export const fetchAvailableVibObjects = async ({ queryKey }) => {
    const [_key, companyID] = queryKey;

    return fetchRequest(`/Vibration/minis?companyID=${companyID}`, 
        `Failed to fetch available vibration Object List: 
            companyID: ${companyID}`
    );
}

//POST
export const postVendor = async ({ queryKey }) => {
    const [_key, body] = queryKey;

    let requestBody = {
        CompanyID: body.CompanyID,
        ApplicationKey: body.ApplicationKey,
        AccessKey: body.AccessKey,
        SecretKey: body.SecretKey,
        Subdomain: body.Subdomain,
        VendorID: body.VendorID,
        CreatedBy: body.UserID,
        IsCurrent: true,
        StartDate: new Date().toISOString(),
    };
    // Perform the post request with the body parameters
    return fetchRequest(`/Vendor/${body.VendorID}/credentials`,
        `Failed to add Vendor Credentials for vendor: ${body.VendorName}`, requestBody);

};

export const postSynchronizeAssetList = async (payload) => {
    //console.log("Final payload:",JSON.stringify(payload));
    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Asset/synchronize`, `Failed to post synchronize Asset List: 
            companyID: ${payload.companyID} 
            vendorID: ${payload.vendorID} 
            userID: ${payload.userID}`, payload);
};


export const fetchVendorList = async ({ queryKey }) => {
    const [_key, IntegrationTypeId] = queryKey;

    if (!IntegrationTypeId) {
        throw new Error("The 'IntegrationTypeId' parameter is required.");
    }

    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Vendor/search?IntegrationTypeId=${IntegrationTypeId}`, "Failed to retrieve Vendor List");
};

export const postAssetEvent = async (payload) => {

    let requestBody = {
        UserID: payload.userID,
        Alarms: payload.assets.map(ev => {
            return {
                VendorID: ev.VendorID,
                CompanyID: ev.CompanyID,
                VendorAssetID: ev.VendorAssetID,
                CreatedDate: ev.CreatedDate,
                Description: ev.Description
            };
        })
    };

    return fetchRequest(`/Asset/raise-alarms`,
        `Failed to post asset event List: 
            assetID: ${payload.assetHierarchyPath}`, requestBody
    );
}

export const postDisconnectIntegration = async (payload) => {
    // Use the fetchRequest helper function to execute the request and handle potential errors
    return fetchRequest(`/Asset/disconnect`, `Failed to disconnect integration for: 
            companyID: ${payload.companyID} 
            vendorID: ${payload.vendorID}`, payload, RequestType.POST);
};