import React from 'react';
import { Button, Box } from '@mui/material';
import ViewModal from '../Modals/ViewModal';


export default function DocumentModal(props) {
    //Shows a modal for downloading documents from Azure blob storage
    return (
        <ViewModal
            title={<b>Documents</b>}
            open={props.documentModalOpen}
            handleClose={props.handleDocumentModalClose}
        >
            {props.docList.map((doc, index) => {
                    return (
                        <Box sx={{ mb: "5%" }} key={index}>
                            <Button sx={{ margin: 'auto' }} fullWidth variant="contained" onClick={() => props.handleDocumentDownload(doc)}>{`Download ${doc}`}</Button>
                        </Box>
                    )
                }) }
        </ViewModal>
    )
}