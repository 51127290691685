import React from 'react';
import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PositionAngle from './PositionAngle';
import BigAssetImage from './BigAssetImage';
import MoreInfo from '../Modals/MoreInfo';
import PhaseChart from '../images/PhaseChart.png'
import PhaseAngle from '../images/PhaseAngle.png'

const helpText = [
    {
        title: "What is Phase Analysis?",
        text: <Typography align="center">Phase Analysis is used to determine how different positions of the asset move in relation to other positions.
            This requires synchronous vibration sampling across all devices on an asset and comparing all vibration signatures (represented as sine waves) to determine
            the <strong>Phase Angle</strong> between each set of signals. A familiar relationship can be drawn from electrical systems. In a 3 Phase (3Ø) Electrical System,
            120° between phases is expected and anything outside of that would be cause for concern.</Typography>
    },
    {
        title: "What do the Phase Symbols Mean?",
        text: <Stack>
            <img
                src={PhaseAngle}
                alt={'Phase Angle'}
                style={{
                    width: '10%',
                    margin: 'auto',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            />
            <Typography align="center" sx={{ mb: 1 }}>A single Phase Angle by itself is random and will change between vibration samples. What matters is how
                different angles relate to each other when an alarm occurs. </Typography>
            <Typography align="center"><strong>There are Two Main Phase Relationships we look at:</strong></Typography>
            <Typography align="center"><strong>Same Position:</strong> In a healthy system, we expect ~90° Between Horizontal and Vertical
                Vibration Signatures at the same node/bearing</Typography>
            <Typography align="center"><strong>Different Position, Same Axis:</strong> In a healthy system, we expect In Phase (~0°) relationships
                between different positions with the same axis </Typography>
        </Stack>
    },
    {
        title: "Why Perform Phase Analysis?",
        text: <Typography align="center">If a vibration alarm occurs, <strong>Phase Relationships</strong> can help us identify which type of defect is
            causing a fault.</Typography>
    },
]
export default function PhaseDefect({ingredient, phaseAngleData })
{

    let p1 = phaseAngleData.find(item => item.positionID == 1);
    let p2 = phaseAngleData.find(item => item.positionID == 2);
    let p3 = phaseAngleData.find(item => item.positionID == 3);
    let p4 = phaseAngleData.find(item => item.positionID == 4);
    let positions = [p1, p2, p3, p4];

    const renderPositionGraph = (position) => {
        return (
            <Grid item xs={4} key={position.positionID}>
                <Paper>
                    <Typography>{position.position}</Typography>
                    <PositionAngle
                        ingredient={ingredient}
                        phaseAngles={position.phaseAngles}
                    />
                </Paper>
            </Grid>
        );
    }

    const renderEmptyPosition = (position) => {
        return (
            <Grid item xs={2} key={position.positionID}>
                <Paper sx={{ height: '100%', padding: 2 }}>
                    <Grid container direction="row" sx={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Typography>{position?.position}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Extraneous for this defect.</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mb: 1 }}
            >
                <Grid item xs={9}>
                    <Typography align="right" variant="h5" style={styles.description}>
                        {ingredient.IngredientDescription}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MoreInfo
                        title=""
                        image={PhaseChart}
                        imgWidth={"45%"}
                        helpText={helpText}
                    />
                </Grid>

            </Grid>
            {/* Stack of charts rendered below the description */}
            {phaseAngleData.length > 0 ? (< Grid
                container
                direction="row"
                alignItems="stretch"
                justifyContent="center"
                spacing={2}
            >
                {positions.map((pos) => {
                    return pos.phaseAngles.find(angle => angle.isHighlighted) !== undefined ? (renderPositionGraph(pos)) : (renderEmptyPosition(pos))
                })}
                <Grid item xs={12}>
                    <br />
                    <BigAssetImage
                        style={styles.asset}
                    />

                </Grid>
            </Grid>) : (<Typography sx={{ my: "10%" }}>No data present for this ingredient.</Typography>)}
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        marginBottom: '16px', // Space between the description and the charts
        paddingRight: '10%'
    },
    asset: {
        width: '85%',
        maxHeight: 350,
        margin: 'auto',
        marginTop: '1%'
    }
};
