import { FormControlLabel, FormGroup, Grid, Paper, Switch } from '@mui/material';
import React from 'react';
import { VBTxConfigurationFormModel } from '../../../models/Vibration/VBTxConfigurationFormModel';
import FormikSelectField from '../../Formik/FormikSelectField';
import FormikSwitch from '../../Formik/FormikSwitch';
import NumericInputField from '../../Formik/NumericInputField';
import AssetImage from "../AssetImage";
import AlarmRecipientsSelect from './AlarmRecipientsSelect';
import FormikNodeAssignment from './FormikNodeAssignment';

const {
    enums: {
        minTriggerOptions,
        maxTriggerOptions
    }
} = VBTxConfigurationFormModel;

export default function VBTxConfigurationStep2({ formik, unassignedNodes, enums, users, userGroups, samplingPeriods }) {

    const handleSeperateSamplingChange = (event) => {
        if (!event.target.checked) {
            formik?.setFieldValue('temperatureSamplingPeriod', formik?.values.vibrationSamplingPeriod);
        } else {
            formik?.setFieldValue('vibrationSamplingPeriod', 3600);
        }
        formik?.setFieldValue('tempEnabled', event.target.checked);
    }
    const handleVibrationSamplingChange = (_) => {
        formik?.handleBlur({
            target: {
                name: 'vibrationSamplingPeriod',
            },
        });
        if (!formik?.values.tempEnabled) {
            formik?.setFieldValue('temperatureSamplingPeriod', formik?.values.vibrationSamplingPeriod);
        }
    }

    // Handle changes in alarming
    const handleAlarmingChange = (event, changeType) => {
        switch (changeType) {
            case "WarningThresholdRecipients":
                formik?.setFieldValue('warningRecipientIDs', event.target.value);
                break;
            case "DangerThresholdRecipients":
                formik?.setFieldValue('dangerRecipientIDs', event.target.value);
                break;
        }
    }

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'triggerSource'}
                        label={'Trigger Source'}
                        data={enums.triggerSources.filter(ts => ts.value == 1 || ts.value == 3)}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["triggerSource"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>

                {(formik?.values.triggerSource != 3) && (<Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={formik?.values.tempEnabled} onChange={handleSeperateSamplingChange} />}
                            label="Sample vibration and temperature separately"
                        />
                    </FormGroup>
                </Grid>)}
                
                {(formik?.values.triggerSource != 3) && (<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'vibrationSamplingPeriod'}
                        label={'Vibration Sampling Period'}
                        data={samplingPeriods}
                        onBlur={handleVibrationSamplingChange}
                        errors={formik?.errors["vibrationSamplingPeriod"]}
                        width={'100%'}
                        enabled={!formik?.values.tempEnabled}
                    />
                </Grid>)}
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'temperatureSamplingPeriod'}
                        label={'Temperature Sampling Period'}
                        data={samplingPeriods}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["temperatureSamplingPeriod"]}
                        width={'100%'}
                        enabled={formik?.values.tempEnabled || formik?.values.triggerSource == 3}
                    />
                </Grid>
                
                {(formik?.values.triggerSource == 3) && (
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <NumericInputField
                            name={'accelerationThreshold'}
                            label={'Acceleration Threshold (mg)'}
                            placeholder={'Acceleration Threshold (mg)'}
                            form={formik}
                            step={1}
                            onBlur={formik?.onBlur}
                            errors={formik?.errors["threshold"]}
                            width={'100%'}
                            digits={0}
                            min={100}
                            max={7500}
                            preventempty="false"
                        />
                    </Grid>)}
                {(formik?.values.triggerSource == 4) && (
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <NumericInputField
                            name={'velocityThreshold'}
                            label={'Velocity Threshold (ips)'}
                            placeholder={'Velocity Threshold (ips)'}
                            form={formik}
                            step={1}
                            onBlur={formik?.onBlur}
                            errors={formik?.errors["threshold"]}
                            width={'100%'}
                            digits={0}
                            min={10}
                            max={65000}
                            preventempty="false"
                        />
                    </Grid>)}
                {(formik?.values.triggerSource != 1) && (
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <FormikSelectField
                            name={'minTriggers'}
                            label={'Minimum Time Between Triggers'}
                            data={minTriggerOptions}
                            onBlur={formik?.onBlur}
                            errors={formik?.errors["maxTriggers"]}
                            width={'100%'}
                            enabled={'true'}
                        />
                    </Grid>)}
                {(formik?.values.triggerSource != 1) && (
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <FormikSelectField
                            name={'maxTriggers'}
                            label={'Maximum Time Between Triggers'}
                            data={maxTriggerOptions}
                            onBlur={formik?.onBlur}
                            errors={formik?.errors["maxTriggers"]}
                            width={'100%'}
                            enabled={'true'}
                        />
                    </Grid>)}
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <AssetImage machineType={formik?.values.machineType} />
                </Grid>
                < Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={formik}
                        name={"p1AssignedNode"}
                        label={"P1 Assigned Node"}
                        unassignedNodes={unassignedNodes}
                        enums={enums}
                        users={users}
                        userGroups={userGroups}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={formik}
                        name={"p2AssignedNode"}
                        label={"P2 Assigned Node"}
                        unassignedNodes={unassignedNodes}
                        enums={enums}
                        users={users}
                        userGroups={userGroups}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={formik}
                        name={"p3AssignedNode"}
                        label={"P3 Assigned Node"}
                        unassignedNodes={unassignedNodes}
                        enums={enums}
                        users={users}
                        userGroups={userGroups}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikNodeAssignment
                        formik={formik}
                        name={"p4AssignedNode"}
                        label={"P4 Assigned Node"}
                        unassignedNodes={unassignedNodes}
                        enums={enums}
                        users={users}
                        userGroups={userGroups}
                    />
                </Grid>
                {formik?.values.sameGateway && < Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'parentGateway'}
                        label={'Parent Gateway'}
                        data={enums.parentGateways}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["parentGateway"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'sameGateway'}
                        label={'All Sensors to Same Gateway'}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["sameGateway"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'burstEnabled'}
                        label={'Burst Mode Enabled'}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["burstEnabled"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                {/*formik?.values.defectLevel == 2 && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'maximizeNodes'}
                        label={'Maximize Nodes per ControlGate'}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["maximizeNodes"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>*/}
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <AlarmRecipientsSelect
                        enums={enums}
                        users={users}
                        userGroups={userGroups}
                        onChange={handleAlarmingChange}
                        thresholdID="DangerThreshold"
                        recipientIDs={formik?.values['dangerRecipientIDs']}
                        key={"danger"}
                        name="Danger Alarm Recipients"
                        color='#a30f0f'
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <AlarmRecipientsSelect
                        enums={enums}
                        users={users}
                        userGroups={userGroups}
                        onChange={handleAlarmingChange}
                        thresholdID="WarningThreshold"
                        recipientIDs={formik?.values['warningRecipientIDs']}
                        key={"warning"}
                        name="Warning Alarm Recipients"
                        color='#f2cb2e'
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}